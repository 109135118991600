import { ChangeDetectionStrategy, Component, Host, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { expandableArrow } from 'src/app/core/constants/animation.constants';
import { BreakpointService } from '../../services/breakpoint.service';
import { ExpandableWrapperComponent } from '../expandable/afc-expandable-wrapper/afc-expandable-wrapper.component';

export enum AnimationPosition {
  right = 0,
  bottom = 90,
  left = 180,
  top = 270
}

export enum AnimationSpeed {
  normal = 300,
  fast = 200
}

@Component({
  selector: 'afc-expandable-arrow',
  templateUrl: './expandable-arrow.component.html',
  styleUrls: ['./expandable-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandableArrow]
})
export class ExpandableArrowComponent implements OnInit, OnDestroy {
  @Input() startPosition: 'right' | 'bottom' | 'left' | 'top' = 'right';
  @Input() endPosition: 'right' | 'bottom' | 'left' | 'top' = 'bottom';
  @Input() set animationSpeed(animationSpeed: 'normal' | 'fast') {
    this._animationSpeed = AnimationSpeed[animationSpeed || 'normal'];
  }
  @Input() isExpanded: boolean;
  @Input() size: string = '24px';
  @Input() sizeMobile: string = '24px';
  @Input() arrowColor: string;
  @Input() isClockwise = true;
  @Input() triggerArrowClick = false;

  private subs = new Subscription();
  private _animationSpeed: AnimationSpeed;
  public arrowPath = '../../../../assets/icons/nextArrow.svg';

  public get getNumberSpeed() {
    return this._animationSpeed;
  }

  public collapsedRotation = AnimationPosition['right'];
  public expandedRotation = AnimationPosition['bottom'];

  constructor(
    @Host() @Optional() public wrapper: ExpandableWrapperComponent,
    private breakpointService: BreakpointService
  ) {}

  ngOnInit(): void {
    this.collapsedRotation = AnimationPosition[this.startPosition];
    this.expandedRotation = AnimationPosition[this.endPosition];
    this.subs.add(
      this.breakpointService.isMobile$.subscribe((matches) => {
        if (matches && this.sizeMobile) {
          this.size = this.sizeMobile;
        }
      })
    );
  }

  triggerClick(e: any) {
    e && e.preventDefault();
    if (this.triggerArrowClick) {
      this.wrapper.toggleIsExpanded();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
