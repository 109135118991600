import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'afc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() variant: 'large' | 'normal' | 'small' = 'normal';

  constructor() {}

  ngOnInit(): void {}
}
