import { Component } from '@angular/core';

@Component({
  selector: 'afc-update-failed',
  templateUrl: './update-failed.component.html',
  styleUrls: ['./update-failed.component.scss']
})
export class UpdateFailedComponent {

}
