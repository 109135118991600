import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY_LINE } from '../../core/constants/base.constants';
/**
 * Formats numbers or strings to AFC design and returns a string (eg. 1 000 000.00)
 */

@Pipe({ name: 'afcNumber' })
export class NumberPipe implements PipeTransform {
  transform(
    value: number | string | undefined | null,
    minimumFractionDigits: number = 2,
    maximumFractionDigits?: number,
    stripDecimal: boolean = true,
    locale: string = 'en-US'
  ): string {
    if (value == 0) {
      return minimumFractionDigits ? `0.${'0'.repeat(minimumFractionDigits)}` : '0';
    }

    if (!value || isNaN(+value)) {
      return EMPTY_LINE;
    }

    let valueToTransform = value;
    if (typeof value === 'string') {
      valueToTransform = valueToTransform.toString().replace(/[, ]/g, '');
    }

    let parsedNumber = +valueToTransform;
    if (!stripDecimal) {
      const decimalPart = parsedNumber.toString().split('.')[1];
      maximumFractionDigits = decimalPart ? decimalPart.length : 0;

      if (maximumFractionDigits === 0) {
        minimumFractionDigits = 0;
      }
  
      if (maximumFractionDigits === 1) {
        maximumFractionDigits = 2;
      }
    }

    const formattedValue = Intl.NumberFormat(locale, { minimumFractionDigits, maximumFractionDigits }).format(
      parsedNumber
    );
    return formattedValue.replace(/,/g, ' ');
  }
}
