import { Component, Input } from '@angular/core';

@Component({
  selector: 'afc-loading-ellipsis',
  templateUrl: './loading-ellipsis.component.html',
  styleUrls: ['./loading-ellipsis.component.scss']
})
export class LoadingEllipsisComponent {
  @Input() size: number = 56;
  @Input() spacing?: number;
  @Input() color: string = 'black';

  ngOnInit() {
    if (this.spacing === undefined) {
      this.spacing = this.size / 3;
    }
  }

  getDotStyle() {
    return {
      width: `${this.size}px`,
      backgroundColor: this.color
    }
  }
}
