<div class="skeleton-container">
    <ng-container [ngSwitch]="variant">
        <!-- Profile Section -->
        <ng-container *ngSwitchCase="state.Profile" [ngTemplateOutlet]="profile"></ng-container>
        <!-- Statements & Documents Section -->
        <ng-container *ngSwitchCase="state.Statements" [ngTemplateOutlet]="statements"></ng-container>
        <!-- Internal Funds Section -->
        <ng-container *ngSwitchCase="state.InternalFunds" [ngTemplateOutlet]="internalFunds"></ng-container>
        <!-- External Funds Section -->
        <ng-container *ngSwitchCase="state.ExternalFunds" [ngTemplateOutlet]="externalFunds"></ng-container>
        <!-- Claims Section -->
        <ng-container *ngSwitchCase="state.Claims" [ngTemplateOutlet]="claims"></ng-container>
        <!-- FAQ Section -->
        <ng-container *ngSwitchCase="state.FAQ" [ngTemplateOutlet]="faq"></ng-container>
        <!-- Useful Documents Section -->
        <ng-container *ngSwitchCase="state.UsefulDocuments" [ngTemplateOutlet]="usefulDocuments"></ng-container>
      </ng-container>
</div>

<ng-template #profile>
    <div class="profile-container">
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>

<ng-template #statements>
    <div class="statements-container">
        <div></div>
        <div></div>
        <afc-loading-ellipsis [size]="16" [spacing]="4"></afc-loading-ellipsis>
    </div>
</ng-template>

<ng-template #internalFunds>
    <div class="internal-funds-container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>

<ng-template #externalFunds>
    <div class="external-funds-container">
        <div></div>
        <div></div>
    </div>
</ng-template>

<ng-template #claims>
    <div class="claims-container">
        <div></div>
    </div>
</ng-template>

<ng-template #faq>
    <div class="faq-container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>

<ng-template #usefulDocuments>
    <div class="useful-documents-container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>
