import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, switchMap } from 'rxjs';
import appsetting from '../../../assets/appsetting.json';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((token) => {
        const updatedRequest = request.clone({
          headers: request.headers
            .set('Authorization', `Bearer ${token}`)
            .set('apikey', request.headers.has('apikey') ? request.headers.get('apikey')! : appsetting.apikey)
        });
        return next.handle(updatedRequest);
      })
    );
  }
}
