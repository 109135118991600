<table id="tbl_default" class="table" [ngClass]="[variant]" [class.mobile-sticky-last-column]="mobileStickyLastColumn">
  <thead id="thd_default" class="table-head" [ngClass]="[variant]">
    <th id="thi_default" *ngFor="let header of tableHeadersPresentation">
      {{ header }}
    </th>
  </thead>
  <tbody id="tbd_default" class="table-body">
    <ng-content></ng-content>
  </tbody>
</table>
