import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingPageRoutingModule } from './loading-page-routing.module';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    LoadingPageComponent
  ],
  imports: [
    CommonModule,
    LoadingPageRoutingModule,
    SharedModule
  ],
  exports: [
    LoadingPageComponent
  ]
})
export class LoadingPageModule { }
