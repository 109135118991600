import { ChangeDetectorRef, Component, Input, forwardRef, Injector, Output, EventEmitter } from '@angular/core';
import { FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { toggleButtonAnimation, toggleBackgroundAnimation } from 'src/app/core/constants/animation.constants';
import { EmitValue } from 'src/app/core/constants/base.constants';

const NEUTRAL_BUTTON_COLOR = '#F3F3F3';

@Component({
  selector: 'afc-toggle',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  animations: [toggleButtonAnimation, toggleBackgroundAnimation],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleButtonComponent),
      multi: true
    }
  ]
})
export class ToggleButtonComponent {
  @Input() emitValue: EmitValue;
  @Input() label: string;
  @Input() verbose: boolean = true;
  @Input() disabled = true;
  @Input() onStateBackground: string = 'var(--primary-connected-orange-color)';
  @Input() offStateBackground: string;
  @Input() onStateText: string;
  @Input() offStateText: string;
  @Input() background: string;
  @Input() position: 'on' | 'off' = 'off';
  @Input() variant: 'ball' | 'full' = 'ball';
  @Input() hasError?: boolean;
  @Output() clickBtn = new EventEmitter<string | null>();

  public togglePosition() {
    if (this.position === 'on') this.position = 'off';
    else this.position = 'on';

    this.propagateChange(this.position === 'on');
  }

  private propagateChange: Function = (_: string) => {};
  private propagateTouch: Function = (_: string) => {};

  public writeValue(toggledOn: boolean): void {
    this.position = toggledOn ? 'on' : 'off'
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public onBlur(): void {
    this.propagateTouch();
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  public getButtonStyles(): Object {
    if (this.variant === 'full')
      return {
        background: this.background || NEUTRAL_BUTTON_COLOR,
        width: '152px',
        height: '32px',
        borderRadius: '16px'
      };
    else
      return {
        background: this.position === 'on' ? this.onStateBackground : this.offStateBackground
      };
  }

  public getToggleBallStyles(): Object | void {
    if (this.variant === 'full')
      return {
        background: '#15808D',
        width: '76px',
        height: '32px',
        borderRadius: '16px',
        margin: 0
      };
  }

  public getOnStateTextStyles(): Object | void {
    if (this.variant === 'full')
      return {
        zIndex: '2',
        fontSize: '14px',
        marginLeft: '19px',
        color: this.position === 'on' ? '#707070' : '#fff'
      };
  }

  public getOffStateTextStyles(): Object | void {
    if (this.variant === 'full')
      return {
        zIndex: '2',
        fontSize: '14px',
        marginLeft: '16px',
        color: this.position === 'off' ? '#707070' : '#fff'
      };
  }
}
