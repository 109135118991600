import { Component, Host, Input, Optional } from '@angular/core';
import { ExpandableWrapperComponent } from '../expandable/afc-expandable-wrapper/afc-expandable-wrapper.component';

@Component({
  selector: 'afc-expandable-card',
  templateUrl: './expandable-card.component.html',
  styleUrls: ['./expandable-card.component.scss']
})
export class ExpandableCardComponent {
  constructor(@Host() @Optional() public wrapper: ExpandableWrapperComponent) {}

  @Input() firstTitle: string;
  @Input() secondTitle: string;
  @Input() buttonTitle: string;
  public isExpanded = false;

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
