import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'afc-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() variant: 'normal' | 'small' = 'normal';
  @Input() hideBorderLine: boolean;
  @Input() subtitleColor: 'grey' | 'orange' = 'grey';
  @Input() hasSubtitle = true;
  @Output() onClick = new EventEmitter();

  ngOnInit(): void {}

  toggleMobileContent() {
    this.onClick.emit();
  }
}
