import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'afc-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent {
  @Input() label: string;
  @Input() isActive: boolean = false;
  @Input() tabButtons: TemplateRef<any>;
}
