import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingPageService {
  private isLoading$$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoading$$.asObservable();

  private isAuthorized$$ = new BehaviorSubject<boolean>(false);
  public isAuthorized$ = this.isAuthorized$$.asObservable();

  private authorizationError$$ = new BehaviorSubject<boolean>(false);
  public authorizationError$ = this.authorizationError$$.asObservable();

  constructor(private router: Router) { }

  public startLoading(): void {
    this.isLoading$$.next(true);
  }

  public stopLoading(): void {
    this.isLoading$$.next(false);
    this.router.navigate(['/dashboard']);
  }

  public authorizeResult(isAuthorized: boolean): void {
    if (isAuthorized) {
      this.isAuthorized$$.next(true);
    } else {
      this.authorizationError$$.next(true);
    }
  }
}
