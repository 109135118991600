
<div class="contact-info-form" [formGroupName]="currentControlName">
  <span class="asterisk-info" *ngIf="fillRequiredText">
    <span class="asterisk">*</span> Please fill in at least one of the following fields: 
    <ul>
      <li>Phone Number</li>
      <li>Email Address</li>
    </ul>
  </span>
  <div class="form-section">
    <span class="title">Phone number</span>
    <ng-container *ngTemplateOutlet="phoneSection; context: { form: contactForm }"></ng-container>
  </div>

  <div class="form-section">
    <span class="title">Email address</span>
    <ng-container *ngTemplateOutlet="emailSection; context: { form: contactForm }"></ng-container>
  </div>

  <div class="form-section">
    <span class="title">Address details</span>
    <ng-container *ngTemplateOutlet="addressSection; context: { form: contactForm }"></ng-container>

    <span class="asterisk-info">Fields containing <span class="asterisk">*</span> are required.</span>
  </div>
</div>

<ng-template #phoneSection let-form="form">
  <div class="phone-group">
    <afc-form-group
      [formGroup]="form"
      ngClass="non-ciam-users"
      class="primary-phone-group">
      <afc-select
        ngClass="countryCode"
        label="Country code"
        errorReferenceText="Country code"
        type="country-code"
        size="small"
        formControlName="primaryCountryCode"
        [withAsterisk]="coutryCodeAsterisk"
        [maxItemsShown]="5"
        [filter]="true"
        [filterBy]="['countryCode', 'name', 'callingCode']"
        [variant]="isPreviewMode ? 'read-only' : 'editable'"
      >
      </afc-select>
      <afc-input
        errorReferenceText="Phone number"
        class="phone-number__input"
        formControlName="primaryPhoneNumber" [variant]="isPreviewMode ? 'read-only' : 'editable'">
      </afc-input>
    </afc-form-group>
    <!-- <afc-form-group
      [formGroup]="form"
      [ngClass]="{ 'non-ciam-users': !this.profile.registrationType }"
      class="secondary-phone-group">
      <afc-select
        ngClass="countryCode"
        label="Recovery"
        errorReferenceText="Country code"
        type="country-code"
        size="small"
        formControlName="recoveryCountryCode"
        [maxItemsShown]="5"
        [filter]="true"
        [filterBy]="['countryCode', 'name', 'callingCode']"
      >
      </afc-select>
      <afc-input
        errorReferenceText="Phone number"
        class="phone-number__input"
        formControlName="recoveryPhoneNumber">
      </afc-input>
    </afc-form-group> -->
  </div>
</ng-template>

<ng-template #emailSection let-form="form">
  <afc-form-group [formGroup]="form">
    <afc-input
      label="Primary"
      formControlName="primaryEmailAddress"
      [withAsterisk]="emailAsterisk" [variant]="isPreviewMode ? 'read-only' : 'editable'">
    </afc-input>
    <!-- <afc-input
      label="Recovery"
      formControlName="recoveryEmailAddress"
      [hidden]="!this.profile.registrationType">
    </afc-input> -->
  </afc-form-group>
</ng-template>

<ng-template #addressSection let-form="form">
  <div [formGroup]="form">
    <afc-form-group>
      <afc-input label="Postal address" formControlName="streetName" [variant]="isPreviewMode ? 'read-only' : 'editable'"></afc-input>
      <afc-input label="Suburb" formControlName="suburb" [variant]="isPreviewMode ? 'read-only' : 'editable'"></afc-input>
      <afc-select
        label="Country"
        type="country"
        formControlName="country"
        [maxItemsShown]="5"
        [filter]="true"
        [filterBy]="'name'"
        [variant]="isPreviewMode ? 'read-only' : 'editable'"
      >
      </afc-select>
    </afc-form-group>
    <afc-form-group>
      <afc-input label="City" formControlName="city" [variant]="isPreviewMode ? 'read-only' : 'editable'"></afc-input>
      <afc-input label="Postal code" formControlName="postalCode" [variant]="isPreviewMode ? 'read-only' : 'editable'"></afc-input>
    </afc-form-group>
  </div>
</ng-template>

