import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  constructor(private responsive: BreakpointObserver) {}

  public isDesktop$ = this.responsive.observe(['(max-width: 768px)']).pipe(map((result) => !result.matches));

  public isMobile$ = this.responsive.observe(['(max-width: 768px)']).pipe(map((result) => result.matches));
}
