import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmitValue } from 'src/app/core/constants/base.constants';

@Component({
  selector: 'afc-svg-button',
  templateUrl: './svg-button.component.html',
  styleUrls: ['./svg-button.component.scss']
})
export class SvgButtonComponent implements OnInit {
  @Input() src: string;
  @Input() emitValue: EmitValue;
  @Input() customSize: number;
  @Input() size: 'large' | 'medium' | 'small' | 'logo' | 'none' = 'none';

  @Output() clickBtn = new EventEmitter<string | null>();
  constructor() {}

  ngOnInit(): void {}
}
