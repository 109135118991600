import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ChartData } from './interfaces';
import * as d3 from 'd3';

const MAX_BENEFICIARIES_DATA_LENGTH = 14;

interface Legend {
  name: string;
  color: string;
}

@Component({
  selector: 'afc-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  @Input() data: ChartData[];

  private colorRange: string[];
  public legendData: Legend[] = [{
    name: '',
    color: ''
  }];
  private degree135 = (-90 * Math.PI/180 + 0.75*Math.PI).toFixed(1);
  private degree225 = (-90 * Math.PI/180 + 1.25*Math.PI).toFixed(1);
  private degree270 = (-90 * Math.PI/180 + 1.5*Math.PI).toFixed(1);
  private degree180 = (-90 * Math.PI/180 + Math.PI).toFixed(1);
  private degree45 = (-90 * Math.PI/180 + 0.25*Math.PI).toFixed(1);
  private degree90 = (-90 * Math.PI/180 + 0.5*Math.PI).toFixed(1);

  constructor(private cdr: ChangeDetectorRef) {}
  
  ngOnInit(): void {}

  public ngAfterViewInit(): void {
    this.data = this.groupDataByValues(this.data);
    this.colorRange = this.createColorRange(this.data);
    this.legendData = this.createLegend(this.data, this.colorRange);

    this.createSvg();
    this.drawChart(this.data);
    this.cdr.detectChanges();
  }

  private groupDataByValues(data: ChartData[]): ChartData[] {
    const smallValues = data.filter(item => item.value <= 2);
    const sumSmallValues = smallValues.reduce((sum, item) => sum + item.value, 0);
    data = data.filter(item => item.value > 2);
    
    if (data.length <= MAX_BENEFICIARIES_DATA_LENGTH) {
      const obj = {name: `${sumSmallValues}%`, value: sumSmallValues, text: 'Others'};
      if (sumSmallValues > 0) {
        data.push(obj);
      }
    } else {
      data.sort((a, b) => a.value - b.value);
      const extraValues = data.splice(0, data.length - MAX_BENEFICIARIES_DATA_LENGTH);
      const sumExtraValues = extraValues.reduce((sum, item) => sum + item.value, 0);
      const sumOthersValues = sumSmallValues + sumExtraValues;

      const obj = {name: `${sumOthersValues}%`, value: sumOthersValues, text: 'Others'};
      if (sumSmallValues + sumExtraValues > 0) {
        data.push(obj);
      }
    }

    return data;
  }

  private createColorRange(data: ChartData[]): string[] {
    const colorArr = [
      '#A5242F',
      '#15808D',
      '#FF4940',
      '#103240',
      '#124E57',
      '#F15A22',
      '#A3A580',
      '#F69521',
      '#3D7369',
      '#7AC643',
      '#F2B81A',
      '#2DBED9',
      '#F19C52',
      '#006FB7'
    ];

    // Limit number of colors based on the number of 'data' items
    // needed to configure domain range in d3
    const resultingColorArray = colorArr.slice(0, data.length);
    return resultingColorArray;
  }

  private flipTextAndArcCondition(d: any): boolean {
    const startAngle = d.startAngle.toFixed(1);
    const endAngle = d.endAngle.toFixed(1);

    if (startAngle >= this.degree180) {
      return true;
    }

    if (startAngle >= this.degree135 && endAngle <= this.degree225) {
      return false;
    }

    if (startAngle >= this.degree135) {
      return true;
    }

    if (startAngle >= this.degree90 && endAngle > this.degree270) {
      return true;
    }

    if (startAngle >= this.degree45) {
      return false;
    }

    return false;
  }

  private svg: any;
  private chartSvg: any;
  // private legendSvg: any;
  private screenWidth = window.innerWidth;
  private width = Math.min(this.screenWidth, 250);
  private height = Math.min(this.screenWidth, 250);

  private createSvg(): void {
    this.svg = d3.select('figure#chart')
      .append('svg')
        .attr('width', this.width)
        .attr('height', this.height);

    this.chartSvg = this.svg
      .append('g')
        .attr('class', 'wrapper')
        .attr('transform', 'translate(' + this.width / 2 + ',' + this.height / 2 + ')');
    
    // this.legendSvg = this.svg
    //   .append('g')
    //     .attr('transform', 'translate(' + (this.width / 1.2) + ', -' + (this.height / 16) + ')');
  }

  // Create the donut slices and also the invisible arcs for the text
  private drawChart(data: any): void {
    const scope = this;
    // Create an arc function
    const arc = d3.arc()
      .innerRadius(this.width * 0.75/2) 
      .outerRadius(this.width * 0.75/2 + 30);

    const pie = d3.pie()
      .startAngle(-90 * Math.PI/180)
      .endAngle(-90 * Math.PI/180 + 2*Math.PI)
      .value((d: any) => d.value)
      .padAngle(.01)
      .sort(null);

    const colorScale = d3.scaleOrdinal()
      .domain(data)
      .range(this.colorRange)

    this.chartSvg.selectAll('.donutArcs')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('class', 'donutArcs')
      .attr('d', arc)
      .style('fill', (d: any, i: any) => {
        if(d.data.text === 'Others') return '#CCCCCC'; //Other
        else return colorScale(i);
      })
      .each(function(this: any, d: any, i: any) {
        // Search pattern for everything between the start and the first capital L
        let firstArcSection = /(^.+?)L/;

        // Grab everything up to the first Line statement
        let newArc = firstArcSection.exec(d3.select(this).attr('d'))?.[1];
        // Replace all the comma's so that IE can handle it
        newArc = newArc?.replace(/,/g , ' ');
        
        // If the end angle lies beyond a quarter of a circle (90 degrees or pi/2) 
        // flip the end and start position

        // TODO: Rethink calculation (consider start angle/end angle are around -1. to 4.)

        // Keep in mind: (d.endAngle.toFixed(2) >= 90 * Math.PI/90 && d.startAngle.toFixed(2) >= 90 * Math.PI/360) || d.startAngle.toFixed(2) >= (90 * Math.PI/180).toFixed(2)
        if (scope.flipTextAndArcCondition(d)) {
          let startLoc 	= /M(.*?)A/,		// Everything between the first capital M and first capital A
            middleLoc 	= /A(.*?)0 0 1/,	// Everything between the first capital A and 0 0 1
            endLoc 		= /0 0 1 (.*?)$/;	// Everything between the first 0 0 1 and the end of the string (denoted by $)
          // Flip the direction of the arc by switching the start en end point (and sweep flag)
          // of those elements that are below the horizontal line

          let middleLoc2 = /A(.*?)0 1 1/,
            endLoc2 = /0 1 1 (.*?)$/;

          let newStart = endLoc.exec(newArc!)?.[1] || endLoc2.exec(newArc!)?.[1];
          let newEnd = startLoc.exec(newArc!)?.[1];
          let middleSec = middleLoc.exec(newArc!)?.[1] || middleLoc2.exec(newArc!)?.[1];

          // Build up the new arc notation, set the sweep-flag to 0
          if (endLoc.exec(newArc!)?.[1] && middleLoc.exec(newArc!)?.[1]) {
            newArc = 'M' + newStart + 'A' + middleSec + '0 0 0 ' + newEnd;
          } else {
            newArc = 'M' + newStart + 'A' + middleSec + '1 1 0 ' + newEnd;
          }
        }

        // Create a new invisible arc that the text can flow along
        scope.chartSvg.append('path')
        .attr('class', 'hiddenDonutArcs')
        .attr('id', 'donutArc' + i)
        .attr('d', newArc)
        .style('fill', 'none');
    });
    
    // Append the label names on the outside
    this.chartSvg.selectAll('.donutText')
      .data(pie(data))
      .enter()
      .append('text')
      .attr('class', 'donutText')
      .attr('font-size', '0.75rem')
      // Move the labels below the arcs for those slices with an end angle greater than 90 degrees
      .attr('dy', (d: any, i: any) => {
        // d.endAngle.toFixed(2) > 90 * Math.PI / 180
        return (scope.flipTextAndArcCondition(d) ? -11 : 18)
      })
      // .attr('dy', 18) //Move the text down
      .append('textPath')
      .attr('startOffset','50%')
      .style('text-anchor','middle')
      .attr('xlink:href', (d: any, i: any) => '#donutArc' + i)
      .text((d: any) => d.data.name)
      .style('fill', '#FFF');

    
    // Add text in center of Donut Chart
    this.chartSvg.append('svg:text')
        .attr('text-anchor', 'middle')
        .attr('font-size','2rem')
        .attr('font-family', 'Hero New Bold')
      .text('100%')
      .append('tspan')
      .text('allocation')
        .attr('font-size', '1.1rem')
        .attr('x', '0')
        .attr('dy', '1.5rem')
    

    // Add one dot in the legend for each name.
    // this.legendSvg.selectAll('mydots')
    //   .data(data)
    //   // .data(data)
    //   .enter()
    //   .append('circle')
    //     .attr('cx', 100)
    //     .attr('cy', (d: any, i: any) => 30 + i * 25) // 100 is where the first dot appears. 25 is the distance between dots
    //     .attr('r', 7)
    //     .style('fill', (d: any, i: any) => colorScale(i))

        
    // Add one dot in the legend for each name.
    // this.legendSvg.selectAll('mylabels')
    //   .data(data.map(item => item['text']))
    //   // .data(data)
    //   .enter()
    //   .append('text')
    //     .attr('x', 120)
    //     .attr('y', (d: any, i: any) => 30 + i * 25) // 100 is where the first dot appears. 25 is the distance between dots
    //     .style('fill', '#061426')
    //     .attr('font-size', '14px')
    //     .attr('font-family', 'Hero New SemiBold')
    //   .text((d: any) => d)
    //       .attr('text-anchor', 'left')
    //       .style('alignment-baseline', 'middle')
  }

  private createLegend(data: ChartData[], colors: string[]): Legend[] {
    const legendData = data.map((item, index) => {
      return {
        name: item.text,
        color: item.text === 'Others' ? '#CCCCCC' : colors[index]
      }
    });

    return legendData;
  }
}
