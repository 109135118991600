import { Component, Input } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';
import { interval, take, finalize } from 'rxjs';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { LoadingPageService } from '../services/loading-page.service';
import { Router } from '@angular/router';

export enum LoadingStatus {
  Authorizing = 0,
  LoadingProfileDetails = 1,
  PreparingDashboard = 2,
  Error = 3
}

@Component({
  selector: 'afc-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent {
  private readonly loadingMessages = ['Authorizing', 'Loading your profile details', 'Preparing your dashboard'];

  private readonly loadingImageNames = ['authorizing', 'profile-details', 'dashboard', 'error'];

  private imageIndex = 0;

  public loadingStatus: LoadingStatus = LoadingStatus.Authorizing;
  public progress: number = 0;
  public loadingMessage: string;
  public loadingImageName: string;
  public isMobile: boolean;
  public imageInterval: NodeJS.Timer;

  LoadingStatus = LoadingStatus;

  public readonly ellipsisColor = 'var(--secondary-fern-color)';

  @Input()
  public set status(status: LoadingStatus) {
    this.loadingStatus = status;
    this.loadingMessage = this.loadingMessages[status];

    if (status === LoadingStatus.Error) {
      this.loadingImageName = this.loadingImageNames[status];
      clearInterval(this.imageInterval);
    }
  }

  constructor(
    private profileService: ProfileService,
    private oidcSecurityService: OidcSecurityService,
    private breakpointService: BreakpointService,
    private loadingPageService: LoadingPageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadingPageService.startLoading();

    this.loadingMessage = this.loadingMessages[this.loadingStatus];
    this.loadingImageName = this.loadingImageNames[this.loadingStatus];
    this.loadProgressBar();

    this.imageInterval = setInterval(() => {
      this.imageIndex = this.imageIndex === 2 ? 0 : ++this.imageIndex;
      this.loadingImageName = this.loadingImageNames[this.imageIndex];
    }, 4000);

    this.loadingPageService.isAuthorized$.subscribe((isAuthorized) => {
        if (isAuthorized) {
          this.status = LoadingStatus.LoadingProfileDetails;
        }
      });

    this.loadingPageService.authorizationError$.subscribe((error) => {
      if (error) {
        this.status = LoadingStatus.Error;
      }
    });

    this.profileService.isLoadingProfile$.subscribe((isLoading) => {
      if (!isLoading) {
        this.progress = 100;
        this.loadingPageService.stopLoading();
      }
    });

    this.breakpointService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  loadProgressBar() {
    interval(1000)
      .pipe(
        take(8),
        finalize(() => {
          if (this.loadingStatus !== LoadingStatus.Error) {
            this.status = LoadingStatus.PreparingDashboard;
          }
        })
      )
      .subscribe(() => {
        if (this.progress < 80 && this.loadingStatus !== LoadingStatus.Error) {
          this.progress += 10;
        }
      });
  }

  login() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }
  
  ngOnDestroy(): void {
    this.loadingPageService.stopLoading();
    clearInterval(this.imageInterval);
  }
}
