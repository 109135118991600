import { Injectable } from '@angular/core';
import {
  ExternalFund,
  InvestementTypesDto,
  InvestmentTypes,
  ExternalFundResponse,
  CreateExternalFundDto,
  ExternalFundFormData,
  ExternalFundDto
} from '../models/external.funds.model';
import {
  FundMasterMember,
  InternalFund,
  InternalFundDto,
  InternalPoliciesDto,
  InternalPolicy,
  NOBCover,
  ProductType
} from '../models/funds.model';
import { BehaviorSubject, filter, map, Observable, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { ApiPaths } from '../../core/constants/api-paths.constants';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { Beneficiary } from '../models/beneficiaries.model';
import { HttpClientService } from 'src/app/shared/services/http-client.service';
import { Profile } from 'src/app/profile/models/profile.model';
import { BaseResponseModel } from 'src/app/shared/models/base-response-model';
import { DocumentsService } from 'src/app/documents/services/documents.service';
import { FuneralCoverService } from 'src/app/funeral-cover/services/funeral-cover.service';
import AppSettings from 'src/assets/appsetting.json';

@Injectable({
  providedIn: 'root'
})
export class BeneficiariesService {
  constructor(
    private http: HttpClientService,
    private profileService: ProfileService,
    private documentService: DocumentsService
  ) {
    this.getInternalFundSummary();

    if (AppSettings.featureToggles.policies) {
      this.getInternalPolicies();
    }
    
    if (AppSettings.featureToggles.externalProducts) {
      this.getExternalFunds();
      this.getInvestmentTypes();
    }
  }

  private isLoadingInternal$$ = new BehaviorSubject<boolean>(true);
  public isLoadingInternal$ = this.isLoadingInternal$$.asObservable();

  private isLoadingExternal$$ = new BehaviorSubject<boolean>(true);
  public isLoadingExternal$ = this.isLoadingExternal$$.asObservable();

  private beneficiaries$$ = new BehaviorSubject<Beneficiary[]>([]);
  public beneficiaries$ = this.beneficiaries$$.asObservable().pipe(filter((beneficiaries) => beneficiaries.length > 0));

  private internalFundSummary$$ = new BehaviorSubject<InternalFund[] | null>(null);
  public internalFundSummary$ = this.internalFundSummary$$.asObservable().pipe(shareReplay(1));

  private internalFundSummaryError$$ = new BehaviorSubject<boolean>(false);
  public internalFundSummaryError$ = this.internalFundSummaryError$$.asObservable().pipe(shareReplay(1));

  private internalPolicies$$ = new BehaviorSubject<InternalPolicy[] | null>(null);
  public internalPolicies$ = this.internalPolicies$$.asObservable().pipe(shareReplay(1));

  private internalPoliciesError$$ = new BehaviorSubject<boolean>(false);
  public internalPoliciesError$ = this.internalPoliciesError$$.asObservable().pipe(shareReplay(1));

  private externalFunds$$ = new BehaviorSubject<ExternalFund[] | null>(null);
  public externalFunds$ = this.externalFunds$$.asObservable().pipe(shareReplay(1));

  private externalFundsError$$ = new BehaviorSubject<boolean>(false);
  public externalFundsError$ = this.externalFundsError$$.asObservable().pipe(shareReplay(1));

  private investementTypes$$ = new Subject<InvestmentTypes[]>();
  public investementTypes$ = this.investementTypes$$.asObservable().pipe(shareReplay(1));

  private productType$$ = new BehaviorSubject<ProductType[] | null>(null);
  public productType$ = this.productType$$.asObservable().pipe(shareReplay(1));

  private masterMembers$$ = new BehaviorSubject<FundMasterMember[] | null>(null);
  public masterMembers$ = this.masterMembers$$.asObservable().pipe(shareReplay(1));

  private funeralCover$$ = new BehaviorSubject<NOBCover | null>(null);
  public funeralCover$ = this.funeralCover$$.asObservable().pipe(shareReplay(1));

  private lifeCover$$ = new BehaviorSubject<NOBCover | null>(null);
  public lifeCover$ = this.lifeCover$$.asObservable().pipe(shareReplay(1));

  public getInvestmentTypes(): void {
    this.http
      .get<InvestementTypesDto>(ApiPaths.getInvestmentTypes())
      .subscribe((response) => this.investementTypes$$.next(response.externalInvestmentAndProductTypes));
  }

  public addExternalFund(fundFormData: ExternalFundFormData): Observable<ExternalFundResponse> {
    return this.profileService.profile$.pipe(
      switchMap((profile) => {
        const addExternalFundDto = this.addProfileInfoToCreateFundDto(fundFormData, profile);
        return this.http.post<ExternalFundResponse>(ApiPaths.addExternalFund(), addExternalFundDto);
      })
    );
  }

  public getInternalFundSummary(): void {
    this.isLoadingInternal$$.next(true);

    this.http
      .get<InternalFundDto>(ApiPaths.getInternal)
      .pipe(
        tap((response) => {
          if (!response.success) {
            throw new Error('Data fetch unsuccessful');
          }
        })
      )
      .subscribe({
        next: (response) => {
          if (AppSettings.featureToggles.usefulDocs) {
            this.getMemberDocuments(response.internalFunds);
          }
          this.setProductType(response.internalFunds);
          this.setMasterMembers(response.internalFunds);

          this.isLoadingInternal$$.next(false);
          this.internalFundSummary$$.next(response.internalFunds);
          this.funeralCover$$.next(response.funeralCover?.[0]);
          this.lifeCover$$.next(response.glaCover?.[0]);
        },
        error: () => {
          this.documentService.getMemberDocuments(['']);

          this.isLoadingInternal$$.next(false);
          this.internalFundSummaryError$$.next(true);
        }
      });
  }

  public getExternalFunds(): void {
    this.http
      .get<ExternalFundDto>(ApiPaths.getExternalFunds)
      .pipe(
        tap((response) => {
          if (!response.success) {
            throw new Error('Data fetch unsuccessful');
          }
        }),
        map((response) => response.externalFunds)
      )
      .subscribe({
        next: (response) => {
          this.isLoadingExternal$$.next(false);
          this.externalFunds$$.next(response);
        },
        error: (err) => {
          this.isLoadingExternal$$.next(false);
          this.externalFundsError$$.next(true);
        }
      });
  }

  private getInternalPolicies(): void {
    this.isLoadingInternal$$.next(true);

    this.http
      .get<InternalPoliciesDto>(ApiPaths.getInternalPolicies)
      .pipe(
        tap((response) => {
          if (!response.success) {
            throw new Error('Data fetch unsuccessful');
          }
        }),
        map((response) => response.policies)
      )
      .subscribe({
        next: (response) => {
          this.isLoadingInternal$$.next(false);
          this.internalPolicies$$.next(response);
        },
        error: (err) => {
          this.isLoadingInternal$$.next(false);
          this.internalPoliciesError$$.next(true);
        }
      });
  }

  public editExternalFund(fundDTO: ExternalFundFormData): Observable<ExternalFundResponse> {
    return this.http.put<ExternalFundResponse>(ApiPaths.updateExternalFund(), fundDTO);
  }

  public deleteExternalFund(id: string): Observable<BaseResponseModel> {
    return this.http.delete<BaseResponseModel>(ApiPaths.deleteExternalFund(id));
  }

  public clearExternalFunds() {
    this.externalFunds$$.next(null);
  }

  private getMemberDocuments(fund: InternalFund[]): void {
    const refNum3Array = fund.map((item) => item.refNum3);
    this.documentService.getMemberDocuments([...new Set(refNum3Array)]);
  }

  private setProductType(fund: InternalFund[]): void {
    const productType = fund.map((item) => {
      return {
        productId: item.schemeNumber,
        productTypeCategory: item.productTypeCategory
      };
    });

    this.productType$$.next(productType || null);
  }

  private setMasterMembers(fund: InternalFund[]): void {
    const masterMembers = fund.map((item) => {
      return {
        fundId: item.schemeNumber,
        masterMemberStatus: item.masterMemberStatus
      };
    });

    this.masterMembers$$.next(masterMembers || null);
  }

  private addProfileInfoToCreateFundDto(
    fundFormData: ExternalFundFormData,
    profile: Profile | null
  ): CreateExternalFundDto {
    return {
      ...fundFormData,
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      dateOfBirth: profile?.dateOfBirth,
      gender: profile?.gender,
      maritalStatus: profile?.maritalStatus,
      retirementAge: profile?.retirementAge || 0,
      smokingInd: profile?.smokingInd || 0
    };
  }
}
