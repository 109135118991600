import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AfGroupService } from './af-group.service';
import { Observable, timer, concatMap } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private httpClient: HttpClient, private afGroupService: AfGroupService) {}

  public get<T>(url: string, showLoad: boolean = true): Observable<T> {
    if (showLoad) {
      return this.onRequestEnd(this.httpClient.get<T>(url));
    }

    return this.httpClient.get<T>(url);
  }

  public getWithGroupId<T>(url: string, params?: object): Observable<T> {
    return this.afGroupService.getAfGroupId().pipe(
      concatMap((groupId) => {
        const queryParams = new HttpParams({ fromObject: { ...params, AFGroupID: groupId } });
        return this.httpClient.get<T>(url, { params: queryParams });
      })
    );
  }

  public getRequestWithParams<T>(url: string, params: any, showLoad: boolean = true): Observable<T> {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (typeof params[key] === 'undefined' || params[key] === null) {
          delete params[key];
        }
      }
    }

    const queryParams = new HttpParams({ fromObject: params });

    if (showLoad) {
      return this.onRequestEnd(this.httpClient.get<T>(url, { params: queryParams }));
    }

    return this.httpClient.get<T>(url, { params: queryParams });
  }

  public put<T>(url: string, body: any, options: { [key: string]: any } = {}): Observable<T> {
    return this.onRequestEnd(this.httpClient.put<T>(url, body, options || this.httpOptions));
  }

  public patch<T>(url: string, body: any, options: { [key: string]: any } = {}): Observable<T> {
    return this.onRequestEnd(this.httpClient.patch<T>(url, body, options || this.httpOptions));
  }

  public post<T>(
    url: string,
    body: any,
    options: { [key: string]: any } = {},
    showLoad: boolean = true
  ): Observable<T> {
    if (showLoad) {
      return this.onRequestEnd(this.httpClient.post<T>(url, body, options || this.httpOptions));
    }

    return this.httpClient.post<T>(url, body, this.httpOptions);
  }

  public delete<T>(url: string): Observable<T> {
    return this.onRequestEnd(this.httpClient.delete<T>(url));
  }

  private onRequestEnd<T>(observable: Observable<T>) {
    return observable.pipe(
      tap({
        next: () => {
          timer(50).subscribe(() => {});
        },
        error: (error) => {
          console.log('on error', error.message);
        }
      })
    );
  }
}
