import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { map, combineLatest, Subscription } from 'rxjs';
import { DatepickerVariants } from 'src/app/shared/enums/datepicker.enum';
import { CustomValidators } from 'src/app/shared/validation/custom-validators';
import {
  EMAIL_INPUT_MAX_LENGTH,
  IDENTITY_NUMBER_INPUT_LENGTH,
  PASSPORT_NUMBER_INPUT_LENGTH_NOB,
  PHONE_INPUT_LENGTH,
  INTERNATIONAL_PHONE_INPUT_MIN_LENGTH
} from 'src/app/core/constants/base.constants';
import { NobService } from '../../services/nob.service';
import { CountriesCollection } from 'src/app/profile-details/components/contact-details-info/country-collection';
import { Profile } from 'src/app/profile/models/profile.model';

enum DatepickerType {
  DOB = 'dateOfBirth',
  ExpiryDate = 'expiryDate'
}

@Component({
  selector: 'afc-beneficiary-form',
  templateUrl: './beneficiary-form.component.html',
  styleUrls: ['./beneficiary-form.component.scss']
})
export class BeneficiaryFormComponent implements OnInit {
  public data$ = combineLatest([
    this.nobService.titles$,
    this.nobService.relationshipTypes$,
    this.nobService.nominationTypeId$,
    this.nobService.beneficiaryTypes$
  ]).pipe(
    map(([titles, relationshipTypes, nominationTypeId, beneficiaryTypes]) => ({
      titles,
      relationshipTypes,
      nominationTypeId,
      beneficiaryTypes
    }))
  );

  @Input() form: FormGroup;
  @Input() title: string = '';
  @Input() revert: boolean = false;
  @Input() isApproved: boolean = false;
  @Input() profile: Profile;

  public currentControlName = 'beneficiary';

  public DatepickerVariants = DatepickerVariants;
  public DatepickerType = DatepickerType;

  public dateToday = new Date();

  public idNumberAsterisk = false;
  public passportAsterisk = false;
  public expiryDateAsterisk = false;
  public countryAsterisk = false;
  public memberNoteCharCount = 0;

  public phoneLength = PHONE_INPUT_LENGTH;
  public idLength = IDENTITY_NUMBER_INPUT_LENGTH;
  public passportLength = PASSPORT_NUMBER_INPUT_LENGTH_NOB;

  private validators: { [key: string]: ValidatorFn } = {
    digitValidator: CustomValidators.digitValidator(),
    lengthValidator: CustomValidators.lengthValidatorCustom(PHONE_INPUT_LENGTH, INTERNATIONAL_PHONE_INPUT_MIN_LENGTH),
    maxLength: Validators.maxLength(EMAIL_INPUT_MAX_LENGTH),
    email: Validators.email,
    validateAtLeastOne: CustomValidators.validateAtLeastOne(),
    required: Validators.required
  };

  private activeValidators = {
    phoneNumber: ['digitValidator', 'lengthValidator'],
    email: ['email', 'maxLength'],
    countryCode: ['required']
  };

  private get _phoneNumberAndEmailGroup(): AbstractControl | null {
    return this.form.get('phoneNumberAndEmailGroup');
  }

  private _subs: Subscription = new Subscription();

  constructor(public nobService: NobService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (!this.revert) {
      this.nobService.getValuesets(true);
    }

    this.addConditionalValidatorsPhoneEmail();
    this.initForm();
    this.patchValues();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public patchValues(): void {
    this._subs.add(
      this.data$.subscribe((data) => {
        this.form.patchValue({
          nominationTypeId: data.nominationTypeId
        });
      })
    );
  }

  public selectRadioBtn(event: string): void {
    this.addValidations(event);
  }

  public triggerDatepickerValidation(control: string): void {
    this.form.get(control)?.markAsDirty();
    this.form.get(control)?.markAsTouched();
  }

  public countCharacters(text: string): void {
    this.memberNoteCharCount = text.length;
  }

  private addValidations(idType: string) {
    const identityNumberInput = this.form.get('identityNumber');
    const passportNumberInput = this.form.get('passportNumber');
    const expiryDateInput = this.form.get('expiryDate');
    const countryInput = this.form.get('country');

    switch (idType) {
      case 'id':
        this.form.patchValue({
          expiryDate: '',
          country: ''
        });

        identityNumberInput?.setValidators([
          Validators.required,
          CustomValidators.digitValidator(),
          CustomValidators.lengthValidator(IDENTITY_NUMBER_INPUT_LENGTH)
        ]);
        identityNumberInput?.updateValueAndValidity();

        passportNumberInput?.clearValidators();
        passportNumberInput?.updateValueAndValidity();

        expiryDateInput?.clearValidators();
        expiryDateInput?.updateValueAndValidity();

        countryInput?.clearValidators();
        countryInput?.updateValueAndValidity();

        this.idNumberAsterisk = true;
        this.passportAsterisk = false;
        this.expiryDateAsterisk = false;
        this.countryAsterisk = false;
        break;
      case 'passport':
        identityNumberInput?.clearValidators();
        identityNumberInput?.updateValueAndValidity();

        passportNumberInput?.setValidators([
          Validators.required,
          CustomValidators.alphanumericValidator(),
          CustomValidators.lengthValidator(PASSPORT_NUMBER_INPUT_LENGTH_NOB)
        ]);
        passportNumberInput?.updateValueAndValidity();

        expiryDateInput?.setValidators(Validators.required);
        expiryDateInput?.updateValueAndValidity();

        countryInput?.setValidators(Validators.required);
        countryInput?.updateValueAndValidity();

        this.passportAsterisk = true;
        this.expiryDateAsterisk = true;
        this.countryAsterisk = true;
        this.idNumberAsterisk = false;
        break;
      default:
        break;
    }

    identityNumberInput?.markAsPristine();
    identityNumberInput?.markAsUntouched();

    passportNumberInput?.markAsPristine();
    passportNumberInput?.markAsUntouched();

    expiryDateInput?.markAsPristine();
    expiryDateInput?.markAsUntouched();

    countryInput?.markAsPristine();
    countryInput?.markAsUntouched();

    // TODO: preselecting passport causes ExpressionChangedAfterItHasBeenCheckedError
  }

  private initForm() {
    this.form.addControl(
      this.currentControlName,
      this.formBuilder.group({
        countryCode: ['', [CustomValidators.selectOptions(CountriesCollection.map((country) => country.callingCode))]]
      })
    );
  }

  private addConditionalValidatorsPhoneEmail(): void {
    this._subs.add(
      this._phoneNumberAndEmailGroup?.valueChanges.subscribe((values) => {
        const emptyCondition = values.phoneNumber === '' && values.email === '';

        const phoneNumberControl = this._phoneNumberAndEmailGroup?.get('phoneNumber');
        const emailControl = this._phoneNumberAndEmailGroup?.get('email');
        const countryCodeControl = this._phoneNumberAndEmailGroup?.get('countryCode');

        this.activeValidators.phoneNumber = this.updateActiveValidators('phoneNumber', emptyCondition);
        this.activeValidators.email = this.updateActiveValidators('email', emptyCondition);
        this.activeValidators.countryCode = values.phoneNumber !== '' ? ['required'] : [];
        this.activeValidators.phoneNumber =
          values.countryCode !== ''
            ? [...this.activeValidators.phoneNumber, 'required']
            : this.activeValidators.phoneNumber;

        this.applyValidators(phoneNumberControl, this.activeValidators.phoneNumber);
        this.applyValidators(emailControl, this.activeValidators.email);
        this.applyValidators(countryCodeControl, this.activeValidators.countryCode);
      })
    );
  }

  private updateActiveValidators(controlName: string, condition: boolean): string[] {
    const baseValidators =
      controlName === 'phoneNumber' ? ['digitValidator', 'lengthValidator'] : ['email', 'maxLength'];
    return condition ? [...baseValidators, 'validateAtLeastOne'] : baseValidators;
  }

  private applyValidators(control: AbstractControl | null | undefined, validatorIds: string[]): void {
    const validators = validatorIds.map((id) => this.validators[id]);
    control?.setValidators(validators);
    control?.updateValueAndValidity({ emitEvent: false });
  }
}
