import { NgModule } from '@angular/core';
import { TableComponent } from './components/table/table.component';
import { CommonModule } from '@angular/common';
import { TableRowComponent } from './components/table-row/table-row.component';
import { TableRowCardComponent } from './components/table-row-card/table-row-card.component';

@NgModule({
  declarations: [TableComponent, TableRowComponent, TableRowCardComponent],
  imports: [CommonModule],
  exports: [TableComponent, TableRowComponent, TableRowCardComponent]
})
export class TableModule {}
