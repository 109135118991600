<div class="header-container" [ngClass]="[
 variant,
    isStatic ? 'isStatic' : 'isExpandable',
    (wrapper && wrapper.isExpanded$ | async) === true ? 'expanded' : '',
    (wrapper && wrapper.isExpanded$ | async) === false ? 'collapsed' : ''
  ]" (click)="toggleMobileContent()">
  <div class="section-wrapper">
    <div class="{{variant}}">
      {{ title }}
    </div>
    <ng-content select="[icon]"></ng-content>
    <div class="buttons-container">
      <ng-content></ng-content>
    </div>
  </div>
  <div *ngIf="isStatic" class="border-line"></div>
</div>