import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { numberWithCommas } from '../../utilities/number.util';

@Component({
  selector: 'afc-form-validation-errors',
  templateUrl: './form-validation-errors.component.html',
  styleUrls: ['./form-validation-errors.component.scss']
})
export class FormValidationErrorsComponent {
  @Input() fieldText: string = 'Field';
  @Input() control: FormControl;
  public convertToNumberWithCommas = numberWithCommas;
}
