import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'afc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() currentPage: string;
  @Input() pages: { name: string, url: string, scrollTo?: string }[];

  public goToPage(url: string, scrollTo?: string) {
    this.router.navigate([url]);

    if (scrollTo) {
      setTimeout(() => {
        (document.getElementById(scrollTo) as HTMLElement)
          .scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      }, 100);
    }
  }

  constructor(private router: Router) {}
}
