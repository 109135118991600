import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  stagger,
  animateChild,
  group,
  sequence
} from '@angular/animations';

const TAB_SLIDER_TIMING_FN = '1000ms cubic-bezier(0.4, 0, 0.2, 1)';

export const flyInOutLeftColumn = [
  trigger('flyInOutLeftColumn', [
    state('in', style({ opacity: 0, transform: 'translateY(0)', background: 'blue' })),
    transition('void => *', [style({ opacity: 0, transform: 'translateY(-100%)' }), animate('310ms')]),
    transition('* => void', [animate('310ms', style({ opacity: 0, transform: 'translateY(-100%)' }))])
  ])
];

export const flyInOutRightColumn = [
  trigger('flyInOutRightColumn', [
    state('in', style({ opacity: 1, transform: 'translateY(0)' })),
    transition('void => *', [style({ opacity: 0, transform: 'translateY(-100%)' }), animate('310ms 310ms')]),
    transition('* => void', [animate('310ms 310ms', style({ opacity: 0, transform: 'translateY(-100%)' }))])
  ])
];

export const fadeAnimationGuide = trigger('fadeAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))])
]);

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms ease-out', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms ease-out', style({ opacity: 0 }))])
]);

export const fadeInOutRapid = trigger('fadeInOutRapid', [
  transition(':enter', [style({ opacity: 0 }), animate('150ms cubic-bezier(0.4, 0, 0.2, 1)', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('150ms cubic-bezier(0.4, 0, 0.2, 1)', style({ opacity: 0 }))])
]);

export const staggerFadeIn = [
  trigger('stagger', [
    transition('* => *', [
      query(':enter', [style({ opacity: 0 }), stagger(100, [animate('0.5s', style({ opacity: 1 }))])], {
        optional: true
      })
    ])
  ])
];

export const expandableContainer = [
  trigger('expandContainer', [
    transition(':enter', [
      style({ height: '0px', visibility: 'hidden' }),
      animate('300ms ease-out', style({ height: '*', visibility: 'visible' }))
    ]),
    transition(':leave', [animate('300ms ease-out', style({ height: '0px', visibility: 'hidden' }))])
  ])
];

export const expandFadeInOut = [
  trigger('expandFadeInOut', [
    transition(':enter', [
      style({ 
        height: '0px',
        'padding-bottom': '0px',
        opacity: 0,
        visibility: 'hidden',
        overflow: 'hidden'
      }),
      animate('300ms cubic-bezier(0.4, 0, 0.2, 1)', 
        style({ 
          height: '*',
          'padding-bottom': '*',
          opacity: '*',
          visibility: 'visible'
        })
      )
    ]),
    transition(':leave', [
      style({ overflow: 'hidden '}),
      animate('300ms cubic-bezier(0.4, 0, 0.2, 1)', 
        style({
          height: '0px',
          'padding-bottom': '0px',
          opacity: 0,
          visibility: 'hidden',
        })
      )
    ])
  ])
];

export const expandClaimDocument = [
  trigger('expandClaimDocument', [
    transition(':enter', [
      style({ height: '0px', opacity: 0, visibility: 'hidden' }),
      animate('160ms cubic-bezier(0.4, 0, 0.2, 1)', style({ height: '*', opacity: '*', visibility: 'visible' }))
    ]),
    transition(':leave', [animate('160ms ease-out', style({ height: '0px', opacity: 0, visibility: 'hidden' }))])
  ])
];

export const expandableArrow = [
  trigger('expandableArrow', [
    state('collapsed', style({ transform: 'rotate({{direction}}{{collapsedRotation}}deg)' }), {
      params: { collapsedRotation: '0', direction: '' }
    }),
    state('expanded', style({ transform: 'rotate({{direction}}{{expandedRotation}}deg)' }), {
      params: { expandedRotation: '90', direction: '' }
    }),
    transition('expanded <=> collapsed', animate('{{getNumberSpeed}}ms ease-out'), {
      params: { getNumberSpeed: 300 }
    }),
    transition('collapsed <=> expanded', animate('{{getNumberSpeed}}ms ease-out'), {
      params: { getNumberSpeed: 300 }
    })
  ])
];

export const selectAnimation = [
  trigger('selectAnimation', [
    transition(':enter', [
      style({ height: '0px', visibility: 'hidden' }),
      animate('260ms cubic-bezier(0.8, 0.5, 0.7, 1.0)', style({ height: '*', visibility: 'visible' }))
    ]),
    transition(':leave', [
      animate('260ms cubic-bezier(0.8, 0.5, 0.7, 1.0)', style({ height: '0px', visibility: 'hidden' }))
    ])
  ])
];

export const tabSlider = [
  trigger('tabSlider', [
    state('in-from-left', style({ opacity: 1 })),
    state('in-from-right', style({ opacity: 1 })),
    state('out-to-left', style({ opacity: 0, transform: 'translateX(0)' })),
    state('out-to-right', style({ opacity: 0, transform: 'translateX(0)' })),
    transition('* => out-to-right', animate(TAB_SLIDER_TIMING_FN, style({ transform: 'translateX(100%)' }))),
    transition('* => out-to-left', [animate(TAB_SLIDER_TIMING_FN, style({ transform: 'translateX(-100%)' }))]),
    transition('* => in-from-left', [
      sequence([
        style({ transform: 'translateX(-100%)', opacity: 1 }),
        animate(TAB_SLIDER_TIMING_FN, style({ transform: 'translateX(0)' }))
      ])
    ]),
    transition('* => in-from-right', [
      sequence([
        style({ transform: 'translateX(100%)', opacity: 1 }),
        animate(TAB_SLIDER_TIMING_FN, style({ transform: 'translateX(0)' }))
      ])
    ])
  ])
];

export const toggleButtonAnimation = [
  trigger('toggleButton', [
    state('on', style({ transform: 'translateX(100%)' })),
    state('off', style({ transform: 'translateX(0)' })),
    transition('on <=> off', animate('300ms ease-out'))
  ])
];

export const toggleBackgroundAnimation = [
  trigger('toggleBackground', [
    state('on', style({ background: 'var(--primary-connected-orange-color)' })),
    state('off', style({ background: 'var(--toggle-button-grey-color)' })),
    transition('on <=> off', [group([query('@toggleButton', [animateChild()]), animate('300ms ease-out')])])
  ])
];

export const flyInOut = [
  trigger('flyInOut', [
    state('in', style({ opacity: 1, transform: 'translateY(0)' })),
    transition('void => *', [style({ opacity: 0, transform: 'translateY(-100%)' }), animate(200)]),
    transition('* => void', [animate(200, style({ opacity: 0, transform: 'translateY(-100%)' }))])
  ])
];

export const fadeInFromBottom = [
  trigger('fadeInFromBottom', [
    state('void', style({ opacity: 0.3, transform: 'translateY(90%)' })), // Initial state for :enter
    state('enter', style({ opacity: 1, transform: 'translateY(0)' })), // Final state for :enter
    transition(
      'void => enter',
      [
        style({ opacity: 0.3, transform: 'translateY(90%)' }),
        animate('1000ms {{delay}}ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ],
      {
        params: { delay: '0' }
      }
    )
  ])
];

export const flyInOutRight = [
  trigger('flyInOutRight', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(9%)' }),
      animate('250ms ease', style({ opacity: 1, transform: 'translateX(0%)'}))
    ]),
    transition(':leave', [
      style({position: 'absolute'}),
      animate('150ms ease', style({ opacity: 0, transform: 'translateX(9%)' }))
    ])
  ])
];

export const opaqueAbsolute = [
  trigger('opaqueAbsolute', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('150ms ease', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      style({ position: 'absolute' }),
      animate('250ms ease', style({ opacity: 0 }))
    ])
  ])
];
