<div
  *ngIf="showTooltip"
  [ngStyle]="{
    top: top,
    right: right,
    zIndex: zIndex,
    left: left,
    minWidth: minWidth,
    maxHeight: maxHeight
  }"
  [ngClass]="['tooltip__text__box', position, autoSize ? 'autoSize' : '']"
  #tooltipTextBox
>
  <div class="tooltip__text" [ngClass]="[noContentPadding ? 'noPadding' : '']">
    {{ text }}
    <ng-content select="[text]"></ng-content>
  </div>
</div>
<ng-content></ng-content>
