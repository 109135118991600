import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, map, Observable, of, Subject, tap } from 'rxjs';
import { ApiPaths } from 'src/app/core/constants/api-paths.constants';
import { AfGroupIdDto } from '../models/af-group-id-model';

@Injectable({
  providedIn: 'root'
})
export class AfGroupService {
  constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) {}

  private afGroupId: string;

  private error$$ = new BehaviorSubject<boolean>(false);
  public error$ = this.error$$.asObservable();

  public getAfGroupId(): Observable<string> {

    this.oidcSecurityService.getUserData().subscribe((userData) => {
      if (userData.afgroupid != undefined) {
        this.afGroupId = userData.afgroupid;
      }
    });

    if (this.afGroupId) {
      return of(this.afGroupId);
    }
    return this.http.get<AfGroupIdDto>(ApiPaths.getAfGroupId).pipe(
      map((response) => {
        if (response.success) {
          return response.afGroupID;
        } else {
          return this.handleError(response);
        }
      }),
      tap((id) => {
        this.afGroupId = id;
      })
    );
  }

  private handleError(response: AfGroupIdDto): string {
    // To do
    this.error$$.next(true);
    return '';
  }
}
