import { Component, ContentChild, TemplateRef } from '@angular/core';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';

@Component({
  selector: 'tr[afc-table-row-card]',
  templateUrl: './table-row-card.component.html',
  styleUrls: ['./table-row-card.component.scss']
})
export class TableRowCardComponent {
  constructor(public breakpointService: BreakpointService) {}

  @ContentChild('stickyColumn') public stickyColumn: TemplateRef<any>;
}
