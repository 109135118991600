import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'afc-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() size: 'standard' | 'small' = 'standard';

  constructor() { }

  ngOnInit(): void {
  }
}
