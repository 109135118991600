<div class="chart__container">
  <figure id="chart"></figure>
  <div class="chart__legend">
    <ul>
      <li
        *ngFor="let item of legendData"
        [ngStyle]="{ 'color': item.color }">
        <span>
          {{ item.name }}
        </span>
      </li>
    </ul>
  </div>
</div>
