import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'afc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(newValue: boolean) {
    this._checked = newValue;
    this.propagateChange(this._checked);
  }

  @Output() onClick = new EventEmitter<boolean>();

  private _checked: boolean;
  private propagateChange: Function = (_: string) => {};
  private propagateTouch: Function = (_: string) => {};

  toggle(isChecked: boolean): void {
    this.checked = isChecked;
    this.onClick.emit(this.checked);
  }

  writeValue(obj: any): void {
    this.checked = obj;
  }

  registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
