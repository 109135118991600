<ng-container [ngSwitch]="activeModalSection">
    <ng-container *ngSwitchCase="ActiveModalSection.Beneficiary1">
        <ng-container *ngTemplateOutlet="addBeneficiary1"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActiveModalSection.Beneficiary2">
        <ng-container *ngTemplateOutlet="addBeneficiary2"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActiveModalSection.EditBeneficiary">
        <ng-container *ngTemplateOutlet="edit"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActiveModalSection.SelectBeneficiary">
        <ng-container *ngTemplateOutlet="selectBeneficiary"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActiveModalSection.ReplaceBeneficiary">
        <ng-container *ngTemplateOutlet="replace"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActiveModalSection.Cancel">
        <span class="confirm-text">The information captured will not be saved on your record.</span>
        <div class="buttons-container">
            <afc-button (clickBtn)="onCancel()" title="No" [size]="isMobile ? 'medium' : 'large'" variant="cancel"></afc-button>
            <afc-button (clickBtn)="onClose()" title="Yes" [size]="isMobile ? 'medium' : 'large'" variant="filled"></afc-button>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="ActiveModalSection.Confirm">
        <span class="confirm-text">This record will be removed from the list of your beneficiaries.</span>
        <div class="buttons-container">
            <afc-button (clickBtn)="onCancelConfirm()" title="Cancel" [size]="isMobile ? 'medium' : 'large'" variant="cancel"></afc-button>
            <afc-button (clickBtn)="onConfirm()" title="Replace" [size]="isMobile ? 'medium' : 'large'" variant="filled"></afc-button>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ActiveModalSection.Success">
        <div class="success-title">Hi <span class="highlight">{{ userData.given_name }}</span>,</div>
        <div class="success-text">Good news!</div>
        <div class="success-text">We've successfully recorded and saved your beneficiary information.</div>
        <afc-content-container class="success-reminder" ngClass="beneficiary-container">
            <div class="success-text">
                <strong>Just a friendly reminder:</strong> remember to keep your personal and beneficiary details up to date.
                It's a good practice to review and update them at least once a year.
                Adding new beneficiaries replaces the previous ones.
            </div>
         </afc-content-container>
         <div class="success-text">
            You can also download a PDF version of your completed form for your records or to submit to HR.
         </div>
        <div class="buttons-container download-buttons">
            <afc-button
                *ngIf="activeDownloadSection != ActiveDownloadSectionType.Loading"
                (clickBtn)="downloadPdf()"
                title="Download"
                size="medium"
                [iconSize]="20"
                [iconSrc]="'assets/icons/download_no_bg.svg'"
            >
            </afc-button>
            <afc-button 
                *ngIf="activeDownloadSection == ActiveDownloadSectionType.Loading" 
                size="medium" 
                class="loading"
            >
                <afc-loading-ellipsis loading-spinner [size]="10" [spacing]="4"></afc-loading-ellipsis>
            </afc-button>
            <afc-button
                (clickBtn)="onClose()"
                title="Close"
                size="medium"
                variant="filled"
            >
            </afc-button>
        </div>
    </ng-container>
</ng-container>

<ng-template #addBeneficiary1>
    <ng-container *ngTemplateOutlet="disclaimerBeneficiary1"></ng-container>
    <afc-fc-beneficiary-form title="Beneficiary 1" [form]="beneficiaryForm" [revert]="isRevert"></afc-fc-beneficiary-form>
    <afc-button
        (clickBtn)="switchBeneficiaryForm(beneficiaryForm)"
        title="Beneficiary 2"
        size="large"
        ngClass="moveToEnd"
        iconSrc="'assets/icons/nextArrow.svg'"
        iconPosition="end"
        [iconSize]="24"
    >
    </afc-button>
</ng-template>

<ng-template #addBeneficiary2>
    <ng-container
        *ngIf="!loading && ((nobService.beneficiaryCrudError$ | async) || (nobService.beneficiaryDetailsError$ | async))"
        [ngTemplateOutlet]="error"
        [ngTemplateOutletContext]="{ message: 'Something went wrong adding your beneficiaries. Please try again later.' }"
    >
    </ng-container>
    <ng-container *ngTemplateOutlet="disclaimerBeneficiary2"></ng-container>
    <afc-fc-beneficiary-form title="Beneficiary 2" [form]="beneficiary2Form" [revert]="isRevert"></afc-fc-beneficiary-form>
    <ng-container *ngTemplateOutlet="consent"></ng-container>
    <div class="buttons-container add-beneficiary-button-container">
        <afc-button
            [disabled]="loading"
            (clickBtn)="switchBeneficiaryForm(beneficiary2Form)"
            title="Beneficiary 1"
            size="large"
            iconSrc="'assets/icons/edit.svg'"
            [iconSize]="16"
        >
        </afc-button>
        <afc-button
            [disabled]="loading || valuesetsError || !isChecked"
            (clickBtn)="submitForm(beneficiary2Form)"
            ngClass="position-relative"
            title="Submit"
            variant="filled"
            size="large"
        >
        <afc-loading-spinner #loading loading-spinner ngClass="beneficiary-spinner" *ngIf="loading" size="small"></afc-loading-spinner>
        </afc-button>
    </div>
</ng-template>

<ng-template #edit>
    <ng-container
        *ngIf="(nobService.beneficiaryCrudError$ | async) || (nobService.beneficiaryDetailsError$ | async)"
        [ngTemplateOutlet]="error"
        [ngTemplateOutletContext]="{ message: 'Something went wrong editing your beneficiaries. Please try logging in later.' }"
    >
    </ng-container>
    <ng-container
        *ngIf="editFormNomineeIdentifierId === 1;
        then disclaimerBeneficiary1;
        else disclaimerBeneficiary2"
    >
    </ng-container>
    <afc-fc-beneficiary-form
        title="Beneficiary {{ editFormNomineeIdentifierId }}"
        [form]="editForm"
        [revert]="isRevert"
    >
    </afc-fc-beneficiary-form>
    <ng-container *ngTemplateOutlet="consent"></ng-container>
    <afc-button
        [disabled]="loading || valuesetsError || !isChecked || editForm.pristine"
        (clickBtn)="submitForm(editForm)"
        ngClass="position-relative moveToEnd"
        title="Submit changes"
        size="large"
    >
        <afc-loading-spinner
            loading-spinner
            size="small"
            ngClass="beneficiary-spinner"
            *ngIf="isConfirmed && !(nobService.beneficiaryCrudError$ | async) && !(nobService.beneficiaryDetailsError$ | async)"
        >
        </afc-loading-spinner>
    </afc-button>
</ng-template>

<ng-template #selectBeneficiary>
    <div class="replace-title">
        To add a new beneficiary, you must remove an existing one. Adding new beneficiaries will cancel all previous nominations.
    </div>
    <afc-radio-group
        #beneficiaryRadio
        class="beneficiary-select-radio"
        label="Select a beneficiary to replace"
        direction="column"
        variant="checkbox"
    >
    <ng-container *ngIf="nobService.beneficiaryDetails$ | async as data">
        <ng-container *ngFor="let row of nobService.getMembership(data?.membership, NominationTypeId.Funeral, null)?.beneficiaries; index as i">
            <div
                class="select-item"
                [class.selected]="beneficiaryRadio.value === (i + 1).toString()"
                (click)="radioGroupSelected((row.nomineeIdentifier)!.toString())"
            >
                <div class="beneficiary-list-identifier">Beneficiary {{ row.nomineeIdentifier }}</div>
                <div class="beneficiary-list-name">{{ row.firstName }} {{ row.lastName }}</div>
                <afc-radio-button
                    [checked]="false"
                    [value]="(row.nomineeIdentifier)!.toString()"
                >
                </afc-radio-button>
            </div>
        </ng-container>
    </ng-container>
    </afc-radio-group>
    <afc-button
        [disabled]="!beneficiaryRadio.value"
        (clickBtn)="replaceBeneficiary(beneficiaryRadio.value)"
        title="Replace"
        size="large"
        ngClass="moveToEnd"
    >
    </afc-button>
</ng-template>

<ng-template #replace>
    <ng-container
        *ngIf="(nobService.beneficiaryCrudError$ | async) || (nobService.beneficiaryDetailsError$ | async)"
        [ngTemplateOutlet]="error"
        [ngTemplateOutletContext]="{ message: 'Something went wrong replacing your beneficiaries. Please try logging in later.' }"
    >
    </ng-container>
    <ng-container
        *ngIf="selectedBeneficiary === 1;
        then disclaimerBeneficiary1;
        else disclaimerBeneficiary2"
    >
    </ng-container>
    <afc-fc-beneficiary-form title="Beneficiary {{ selectedBeneficiary }}" [form]="replaceForm" [revert]="isRevert"></afc-fc-beneficiary-form>
    <ng-container *ngTemplateOutlet="consent"></ng-container>
    <afc-button
        [disabled]="loading || valuesetsError || !isChecked"
        (clickBtn)="submitForm(replaceForm)"
        ngClass="position-relative moveToEnd"
        title="Submit changes"
        size="large"
    >
        <afc-loading-spinner
            loading-spinner
            size="small"
            ngClass="beneficiary-spinner"
            *ngIf="isConfirmed && !(nobService.beneficiaryCrudError$ | async) && !(nobService.beneficiaryDetails$ | async)"
        >
        </afc-loading-spinner>
    </afc-button>
</ng-template>

<ng-template #consent let-form="form">
    <div class="termsAndConditions">
        <afc-checkbox [disabled]="loading" (onClick)="toggleCheckbox($event)"></afc-checkbox>
        <div class="termsAndConditions__text">
            <div>
                I declare that I understand that this beneficiary nomination cancels all previous nominations, if any, that I have made for the 
                unapproved funeral benefit under the insurance scheme(s) provided by my employer.
            </div>
            <div>
                The contact details for my beneficiaries are accurate as stated in this form.
            </div>
            <div>
                I hereby authorise Alexforbes to process all the information provided herein, with the understanding that such processing will adhere to 
                the Protection of Information Act and Alexforbes’ rigorous policies on the confidentiality of personal data. 
                Additionally, I consent to the utilisation of my personal information by Alexforbes for the administration of insurance products 
                and agree to its sharing with the Alexforbes network.
            </div>
        </div>
    </div>
</ng-template>

<ng-template #disclaimerBeneficiary1>
    <div class="disclaimer">
        I nominate the following person as the recipient of the payable funeral benefit in the event of my passing.
    </div>
</ng-template>
<ng-template #disclaimerBeneficiary2>
    <div class="disclaimer">
        If the person I nominated earlier is unable to receive the benefit, the payable funeral benefit should be paid to the person below.
    </div>
</ng-template>

<ng-template #error let-message="message">
    <afc-inline-error
        *ngIf="showError"
        size="large"
        ngClass="nob-error"
        src="assets/icons/warning.svg"
        [message]="message"
        [withCloseBtn]="true"
        (onClose)="hideErrorMessage()"
    >
    </afc-inline-error>
</ng-template>
