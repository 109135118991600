<div [class]="['container', 'container--overview', profileService.getUserType() === USER_TYPE.Retail && 'retail-color']">
  <img
    [src]="'assets/icons/' + (profileService.getUserType() === USER_TYPE.Institutional ? 'funeral_cover_institutional' : 'funeral_cover_retail') + '.svg'"
    alt="wallet"
    width="70"
  >
</div>

<div class="footer">
  <div class="title">
    <div class="title__chip">
      <img src="assets/icons/warning.svg" />
      <span>Refer to your member booklet for comprehensive information regarding this benefit</span>
    </div>
    <div class="title__chip">
      <span>Beneficiaries</span>
      <afc-chip>
        <span class="title__chip-content">{{ beneficiaries || 0 }}/2</span>
      </afc-chip>
    </div>
  </div>

  <div *ngIf="!beneficiaries" class="empty_state">
    <span class="empty_state__txt">No beneficiaries loaded yet.</span>
    <div class="empty_state__btn">
      <afc-tooltip
        ngClass="empty_state__btn-tooltip"
        text="If no beneficiary is appointed, the benefit will be paid to your estate as required by law."
        top="2rem">
          <afc-button title="Add" (clickBtn)="openModal()" size="medium" variant="link"></afc-button>
          <img src="assets/icons/warning.svg" />
      </afc-tooltip>
    </div>
  </div>
</div>

<afc-modal
  *ngIf="nobModalService.isFcModalOpen$ | async"
  header="Nomination of beneficiary - unapproved funeral benefit"
  (onClose)="closeModal()"
  [width]="850"
  [isOpen]="(nobModalService.isFcModalOpen$ | async) || false"
  [disableOutsideClickClose]="true"
>
    <afc-fc-beneficiary-modal
      #beneficiaryModal
      [isInEditMode]="inEditMode"
      [beneficiaryId]="beneficiaryId"
      [isReplaceJourney]="isReplaceJourney"
    >
    </afc-fc-beneficiary-modal>
</afc-modal>
