<div class="success-content">
  <p class="text">
    Thank you for using this service.<br />
    Please note the following important points.
  </p>
  <ul class="items-list">
    <li class="list-item">
      <span class="marker"></span>
      <span class="text"> Your <span class="text-accent">online profile</span> has been updated. </span>
    </li>
    <li class="list-item">
      <span class="marker"></span>
      <span class="text">
        To update your <span class="text-accent">personal details on your pension/retirement fund</span>, please contact
        your <span class="text-accent">HR department</span>.
      </span>
    </li>
    <li class="list-item">
      <span class="marker"></span>
      <span class="text">
        To update your personal details for <span class="text-accent">retail products</span> you may have with
        Alexforbes, <span class="text-accent">contact your financial advisor</span> or alternatively contact us on 0860
        100 333, Monday to Friday between 08:00 and 17:00.
      </span>
    </li>
  </ul>
</div>
<div class="success-footer">
  <afc-button (clickBtn)="onClose()" title="Okay" variant="filled" size="x-large"></afc-button>
</div>
