import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, shareReplay, Subject, map, Subscription, tap, Observable, ReplaySubject } from 'rxjs';
import { ApiPaths } from 'src/app/core/constants/api-paths.constants';
import { FaqResponse, FaqResponseList } from 'src/app/shared/models/faq-response';
import { NotificationResponse, NotificationResponseList } from 'src/app/shared/models/notification-response.model';
import {
  DownloadMemberDocumentResponse,
  MemberDocumentResponseList,
  MemberDocumentsResponse
} from '../models/documents.model';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import AppSettings from 'src/assets/appsetting.json';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  public getSubscription = new Subscription();

  private Error$$ = new BehaviorSubject<boolean>(false);
  public Error$ = this.Error$$.asObservable();

  private notificationError$$ = new BehaviorSubject<boolean>(false);
  public notificationError$ = this.notificationError$$.asObservable();

  private faq$$ = new ReplaySubject<FaqResponseList[]>();
  public faq$ = this.faq$$.asObservable().pipe(shareReplay(1));

  private memberDocuments$$ = new BehaviorSubject<MemberDocumentResponseList[] | null>(null);
  public memberDocuments$ = this.memberDocuments$$.asObservable().pipe(shareReplay(1));

  private downloadDocument$$ = new ReplaySubject<DownloadMemberDocumentResponse>();
  public downloadDocument$ = this.downloadDocument$$.asObservable();

  private FaQError$$ = new BehaviorSubject<boolean>(false);
  public FaQError$ = this.FaQError$$.asObservable();

  private notification$$ = new BehaviorSubject<NotificationResponseList[] | null>(null);
  public notification$ = this.notification$$.asObservable().pipe(shareReplay(1));

  constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) {
    // Rethink this implementation, 'getNotifications' needs to be called from landing page
    this.oidcSecurityService.isAuthenticated$.subscribe((authData) => {
      if (authData.isAuthenticated && AppSettings.featureToggles.faq) {
        this.getFAQ();
      }
    });

    this.getNotifications();
  }

  public getMemberDocuments(refNum3: string[]): void {
    let params = new HttpParams();
    refNum3.forEach((item) => {
      params = params.append('refNum3', item);
    });

    const options = { params: params };

    this.getSubscription = this.http.get<MemberDocumentsResponse>(ApiPaths.getMemberDocuments(), options).subscribe((response) => {
      if (!response.success) {
        this.Error$$.next(true);
      } else {
        this.memberDocuments$$.next(response.memberDocumentResponseList);
      }
    });
  }

  public downloadMemberDocument(fieldId: string): void {
    this.http
      .get<DownloadMemberDocumentResponse>(ApiPaths.downloadMemberDocument(fieldId))
      .subscribe((response: any) => {
        if (!response.success) {
          this.Error$$.next(true);
        } else {
          this.downloadDocument$$.next(response);
        }
      });
  }

  public getFAQ(): void {
    this.http.get<FaqResponse>(ApiPaths.getFaqUrl).subscribe((response) => {
      if (!response.success) {
        this.FaQError$$.next(true);
      } else {
        this.faq$$.next(response.afConnectFAQList);
      }
    });
  }

  public getNotifications(): void {
    this.http
      .get<NotificationResponse>(ApiPaths.getNotificationUrl)
      .pipe(
        map((response) => ({
          ...response,
          result: response.result
            ?.map((result) => ({
              ...result,
              sourceSystem: result.sourceSystem.filter((item) => item.includes('AFConnect'))
            }))
            .filter((result) => result.sourceSystem.length > 0)
        }))
      )
      .subscribe((response) => {
        if (!response.isSuccess) {
          this.notificationError$$.next(true);
        } else {
          this.notification$$.next(response.result || null);
        }
      });
  }

  public downloadStaffClaimForm(): Observable<Blob> {
    return this.http.get<Blob>(ApiPaths.downloadStaffClaimForm, { responseType: 'blob' as 'json' });
  }
}
