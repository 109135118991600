<div class="slider-container">
  <div [ngClass]="label ? 'hasLabel' : 'noLabel'" class="label-container">
    <label class="label"> {{ label }}</label>
  </div>
  <div class="slider-wrapper">
    <div #slider class="slider" [class.animating]="!isDragging">
      <div 
        #thumb
        class="thumb"
        [ngStyle]="{ 'width.px': thumbSize, 'height.px': thumbSize, 'left.px': (-thumbRadius) }"
      ></div>
      <div class="track" [style.width.%]="sliderValue"></div>
    </div>
    <div class="slider-wrapper__amount">{{ sliderValue || 0 }}%</div>
  </div>
</div>
