<div class="breadcrumb-wrapper">
  <div class="breadcrumb-container">
    <div class="home-icon-container" (click)="goToPage('/dashboard')">
      <afc-svg-button [customSize]="24" src="assets/icons/home.svg" emitValue="isCloseModal"></afc-svg-button>
    </div>
    <div class="titles-container">
      <div class="pointer-title" (click)="goToPage('/dashboard')">Home</div>
      <afc-expandable-arrow></afc-expandable-arrow>
      <ng-container *ngFor="let page of pages">
        <div class="pointer-title" (click)="goToPage(page.url, page.scrollTo)">{{ page.name }}</div>
        <afc-expandable-arrow></afc-expandable-arrow>
      </ng-container>
      <div class="current-title">{{ currentPage }}</div>
    </div>
  </div>
</div>
