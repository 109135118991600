import { Component, Host, Input, OnInit, Optional, Output, EventEmitter } from '@angular/core';
import { ExpandableWrapperComponent } from '../expandable/afc-expandable-wrapper/afc-expandable-wrapper.component';
import { BreakpointService } from '../../services/breakpoint.service';

@Component({
  selector: 'afc-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() isStatic: boolean = true;
  @Input() variant: 'default' | 'portfolio' = 'default';
  @Input() isLoading: boolean = false;
  @Output() onClick = new EventEmitter();

  isMobile = false;

  constructor(@Host() @Optional() public wrapper: ExpandableWrapperComponent, private breakpointService: BreakpointService) {}

  ngOnInit(): void {
    this.breakpointService.isMobile$.subscribe(mobile => this.isMobile = mobile);
  }

  toggleMobileContent() {
    this.onClick.emit();
  }
}
