import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { WelcomeBannerComponent } from './components/welcome-banner/welcome-banner.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { SharedModule } from '../shared/shared.module';
import { DesktopTemplateDirective } from './directives/desktop-template/desktop-template.directive';
import { MobileTemplateDirective } from './directives/mobile-template/mobile-template.directive';
import { DynamicBackgroundComponent } from './components/dynamic-background/dynamic-background.component';

@NgModule({
  declarations: [PageLayoutComponent, WelcomeBannerComponent, DesktopTemplateDirective, MobileTemplateDirective, DynamicBackgroundComponent],
  imports: [CommonModule, MaterialModule, SharedModule],
  exports: [PageLayoutComponent, WelcomeBannerComponent, DesktopTemplateDirective, MobileTemplateDirective]
})
export class CoreModule {}
