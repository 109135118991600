import { Component, Host, Optional } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'afc-update-success',
  templateUrl: './update-success.component.html',
  styleUrls: ['./update-success.component.scss']
})
export class UpdateSuccessComponent {
  constructor(@Host() @Optional() public modal: ModalComponent) {}

  public onClose() {
    if (this.modal) {
      this.modal.toggleModal();
    }
  }
}
