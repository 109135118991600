import { BehaviorSubject, shareReplay, Subject, tap, delay, defer, catchError, throwError, map, of, distinctUntilChanged, debounceTime, take, filter, merge, skip } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { ProfileDto, Profile, UpdateProfileDetailsDto, UpdateProfileDetails, UserType } from '../models/profile.model';
import { ApiPaths } from '../../core/constants/api-paths.constants';
import { HttpClientService } from 'src/app/shared/services/http-client.service';
import { BeneficiariesService } from 'src/app/beneficiaries/services/beneficiaries.service';
import { PROFILE_ID_NUMBER } from 'src/app/core/constants/base.constants';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private _beneficiariesService: BeneficiariesService;
  private userType: UserType;

  constructor(private http: HttpClientService, private injector: Injector) {}

  get beneficiariesService(): BeneficiariesService {
    if (!this._beneficiariesService) {
      this._beneficiariesService = this.injector.get(BeneficiariesService);
    }
    return this._beneficiariesService;
  }

  public idLabel: string;

  private getProfileError$$ = new BehaviorSubject<boolean>(false);
  public getProfileError$ = this.getProfileError$$.asObservable();

  private updateProfileError$$ = new Subject<boolean | null>();
  public updateProfileError$ = this.updateProfileError$$.asObservable();

  private profile$$ = new BehaviorSubject<Profile | null>(null);
  public profile$ = this.profile$$.asObservable().pipe(shareReplay(1));

  private updateProfile$$ = new BehaviorSubject<boolean>(false);
  public updateProfile$ = this.updateProfile$$.asObservable().pipe(shareReplay(1));

  private hybridUserIndicator$$ = new BehaviorSubject<boolean>(false);
  public hybridUserIndicator$ = this.hybridUserIndicator$$.asObservable().pipe(shareReplay(1));

  private isLoadingProfile$$ = new BehaviorSubject<boolean>(true);
  public isLoadingProfile$ = this.isLoadingProfile$$.asObservable();

  private userType$$ = new BehaviorSubject<UserType>(UserType.Institutional);
  public userType$ = this.userType$$.asObservable().pipe(shareReplay(1));

  public getProfile(): void {
    this.isLoadingProfile$$.next(true);

    this.http
      .get<ProfileDto>(ApiPaths.getProfile)
      .pipe(
        tap((response) => {
          if (!response.success) {
            throw new Error('Data fetch unsuccessful');
          }

          if (!response.success) {
            this.getProfileError$$.next(true);
          }
        }),
        map((response) => response.profile)
      )
      .subscribe({
        next: (response) => {
          this.userType = response.userType;
          this.setUserHybridIndicator(response.userType);
          this.idLabel = response?.identifierType === PROFILE_ID_NUMBER ? 'ID number' : 'Passport number';

          this.isLoadingProfile$$.next(false);
          this.profile$$.next(response);
        },
        error: () => {
          this.getProfileError$$.next(true);
          this.beneficiariesService.getInternalFundSummary();
          this.setProductHybridIndicator();
        }
      });
  }

  public updateProfile(data: UpdateProfileDetails): void {
    this.updateProfile$$.next(true);
    this.updateProfileError$$.next(null);
    this.http
      .put<UpdateProfileDetailsDto>(ApiPaths.putProfile(), data)
      .pipe(
        catchError((error) => {
          this.updateProfileError$$.next(true);
          this.updateProfile$$.next(false);
          return throwError(() => new Error(error));
        })
      )
      .subscribe((response) => {
        if (response.success) {
          this.updateProfileError$$.next(false);
        } else {
          this.updateProfileError$$.next(true);
        }
        this.updateProfile$$.next(false);
        this.updateProfileResponse(data);
      });
  }

  private updateProfileResponse(data: UpdateProfileDetails) {
    const profile = this.profile$$.getValue();
    if (profile) {
      this.profile$$.next(null);

      const updatedProfile: Profile = {
        ...profile,
        address: {
          ...profile.address,
          address: data.streetName,
          suburb: data.suburb,
          city: data.city,
          postalCode: data.postalCode,
          country: data.country
        },
        dateOfBirth: data.dateOfBirth,
        firstName: data.firstName,
        idNumber: data.identityNumber,
        identifierType: data.identifierType,
        initials: data.initials,
        lastName: data.surname,
        middleName: data.middleName,
        passportNumber: data.identityNumber,
        primaryContactNumber: data.primaryPhoneNumber,
        primaryEmailAddress: data.primaryEmailAddress,
        title: data.title
      };

      of({})
        .pipe(delay(1000))
        .subscribe(() => this.profile$$.next(updatedProfile));
    }
  }

  public clear(): void {
    this.profile$$.next({} as Profile);
  }

  public getUserType(): UserType.Institutional | UserType.Retail {
    const type = sessionStorage.getItem('USER_TYPE') as UserType.Institutional | UserType.Retail;
    return Object.values(UserType).includes(type) ? type : UserType.Institutional;
  }

  /**
   * Show both Institutional and Retail products (specific requirement for the near future)
   * To be removed when switch indicator is back
   */
  public getUserFilterType(): UserType {
    return this.userType;
  }

  private setUserHybridIndicator(userType: UserType) {
    const isHybridMember = userType === UserType.Hybrid;
    this.hybridUserIndicator$$.next(isHybridMember);
    
    if (sessionStorage.getItem('USER_TYPE') === null) {
      sessionStorage.setItem('USER_TYPE', isHybridMember ? UserType.Institutional : userType);
    }
  }

  private setProductHybridIndicator() {
    this.beneficiariesService.internalFundSummary$
      .pipe(skip(1), take(1))
      .subscribe(funds => {
        const productTypeCategories = funds?.reduce<string[]>((acc, item) => {
          if (!acc.includes(item.productTypeCategory)) {
              acc.push(item.productTypeCategory);
          }
          return acc;
        }, []);

        switch (productTypeCategories?.length) {
          case 0:
            if (sessionStorage.getItem('USER_TYPE') === null) {
              sessionStorage.setItem('USER_TYPE', UserType.Institutional);
            }
            break;
          case 1:
            const productType = productTypeCategories[0];

            if (sessionStorage.getItem('USER_TYPE') === null) {
              sessionStorage.setItem('USER_TYPE', productType);
            }
            break;
          default:
            this.hybridUserIndicator$$.next(true);

            if (sessionStorage.getItem('USER_TYPE') === null) {
              sessionStorage.setItem('USER_TYPE', UserType.Institutional);
            }
            break;
        }
      });

    this.beneficiariesService.isLoadingInternal$
      .pipe(skip(2), take(1))
      .subscribe((loading) => {
        if (!loading) {
          this.isLoadingProfile$$.next(false);
        }
      });
  }
}
