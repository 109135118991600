import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_BACKGROUND_IMAGE, routeBackgroundData } from './dynamic-background.dataset';

@Component({
  selector: 'afc-dynamic-background',
  templateUrl: './dynamic-background.component.html',
  styleUrls: ['./dynamic-background.component.scss']
})
export class DynamicBackgroundComponent implements OnInit {
  public backgroundImagePosition: 'absolute' | 'fixed' = 'absolute';
  public imageName: string = DEFAULT_BACKGROUND_IMAGE;

  constructor(private router: Router) {}

  ngOnInit() {
    this.imageName = routeBackgroundData
      .find(r => r.pathKeywords.some(k => this.router.url.includes(k)))?.imageName || DEFAULT_BACKGROUND_IMAGE;
    this.setImagePosition();
  }

  @HostListener('window:scroll')
  setImagePosition() {
    if (window.scrollY > 302 && this.backgroundImagePosition !== 'fixed') {
      this.backgroundImagePosition = 'fixed';
    } else if (window.scrollY <= 302 && this.backgroundImagePosition !== 'absolute') {
      this.backgroundImagePosition = 'absolute';
    }
  }
}
