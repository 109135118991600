import { Injectable } from "@angular/core";
import { DatepickerComponent } from "../components/datepicker/datepicker.component";

@Injectable({ providedIn: 'root' })
export class DatepickerService {
  private openDatepicker: DatepickerComponent | null = null;

  public setOpenDatepicker(datepicker: DatepickerComponent): void {
    this.openDatepicker = datepicker;
  }

  public closeOpenDatepicker(): void {
    if (this.openDatepicker) {
      this.openDatepicker.onCalendarClose.emit();
      this.openDatepicker = null;
    }
  }
}
