import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuardService implements CanActivate {
  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService
  ) { }

  canActivate(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((isAuthorized) => {
        if (isAuthorized.isAuthenticated) {
          this.router.navigate(['dashboard']);
          return false;
        }
        return true;
      })
    )
  }
}
