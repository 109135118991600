import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';

@Component({
  selector: 'afc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges, OnInit, OnDestroy {
  @Input() tableHeaders: string[] = [];
  @Input() mobileStickyLastColumn: boolean = false;
  @Input() variant: 'normal' | 'card' | 'flex' | 'chart' = 'normal';

  public tableHeadersPresentation: string[];
  private subscriptions = new Subscription();

  constructor(public breakpointService: BreakpointService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableHeaders']) {
      this.tableHeadersPresentation = [...this.tableHeaders];
    }
  }

  ngOnInit() {   
    if (this.mobileStickyLastColumn) {
      this.setupBreakpointServiceSubscription();
    }
  } 

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private setupBreakpointServiceSubscription() {
    this.subscriptions.add(
      this.breakpointService.isMobile$.subscribe((isMobile) => {
        if (isMobile) {
          this.moveLastHeaderToFirstPosition();
        } else {
          this.restoreInitialHeaders();
        }
      })
    );
  }

  private moveLastHeaderToFirstPosition() {
    const lastHeader = this.tableHeadersPresentation.pop();
    if (lastHeader !== undefined) {
      this.tableHeadersPresentation.unshift(lastHeader);
    }
  }

  private restoreInitialHeaders() {
    this.tableHeadersPresentation = [...this.tableHeaders];
  }
}
