<div class="paginator-container">
  <div class="paginator-button-wrapper">
    <afc-button
      *ngIf="hasPrevious"
      @fadeInOutRapid
      [title]="(breakpointService.isDesktop$ | async) ? 'Previous' : ''"
      size="medium"
      iconSrc="./assets/icons/previous-arrow.svg"
      ngClass="align-left paginator-button"
      (clickBtn)="previous()"
    ></afc-button>
  </div>
  <span class="metadata">
    {{pageNumber}} of {{totalPages}}
  </span>
  <div class="paginator-button-wrapper">
    <afc-button
      *ngIf="hasNext"
      @fadeInOutRapid
      [title]="(breakpointService.isDesktop$ | async) ? 'Next' : ''"
      size="medium"
      iconSrc="./assets/icons/next-arrow.svg"
      iconPosition="end"
      ngClass="align-right paginator-button"
      (clickBtn)="next()"
    >
    </afc-button>
  </div>
</div>
