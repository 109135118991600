import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NotificationType } from '../../services/notification.service';

@Component({
  selector: 'afc-toaster-message',
  templateUrl: './toaster-message.component.html',
  styleUrls: ['./toaster-message.component.scss']
})

export class ToasterMessageComponent {
  @Input() public title: string;
  @Input() public message: string;
  @Input() src: string;
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() type: string;
  @Input() closeButtonSize: 'small' | 'large' = 'large';
  @Input() width: string;
  @Input() timer = 5000;
  @Output() onClose = new EventEmitter<boolean>();
  
  ngOnInit() {
    this.checkType()
    setTimeout(() => this.onClose.emit(), this.timer)
  }

  public closeToast() {
    this.onClose.emit();
  }

  private checkType() {
    if (this.type === NotificationType.Default){
      this.type = NotificationType.Info;
    } 
  }
}
