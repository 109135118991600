import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { Subscription, debounceTime, fromEvent } from 'rxjs';

@Directive({
  selector: '[closeClickOutside]',
  exportAs: 'clickOutsideDirective'
})
export class ClickOutsideDirective {
  @Input() closeClickOutside: boolean;
  @Output() clickOutside = new EventEmitter<void>();
  public subs = new Subscription();

  public isOpenInternal = false;

  constructor(public elementRef: ElementRef) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.subs.add(
      fromEvent(document, 'click')
        .pipe(debounceTime(50))
        .subscribe((event) => {
          const isInsideClick = this.elementRef.nativeElement.contains(event.target);
          const calendar = (event.target as HTMLSpanElement).classList.contains('mat-calendar-body-cell-content');
          if (!isInsideClick && !calendar) {
            if (this.isOpenInternal) {
              this.clickOutside.emit();
              this.isOpenInternal = false;
            } else if (this.isOpenInternal === false && this.closeClickOutside === true) {
              this.itemHasJustOpened();
            }
          } else {
            this.isOpenInternal = false;
          }
        })
    );
  }

  private itemHasJustOpened() {
    this.isOpenInternal = true;
  }
}
