import { ContactDetails, ContactDetailsDto } from 'src/app/profile-details/models/contact-details.model';
import { ProfileDetails, ProfileDetailsDto } from 'src/app/profile-details/models/profile-details.model';
import { BaseResponseModel } from 'src/app/shared/models/base-response-model';

export interface ProfileDto extends BaseResponseModel {
  profile: Profile;
}

export interface UpdateProfileDetailsDto extends ProfileDetailsDto, ContactDetailsDto, BaseResponseModel {}

export interface Profile {
  address: Address;
  afGroupId: string | null;
  dateOfBirth: string;
  dependants: number;
  firstName: string | null;
  gender: string | null;
  idNumber: string | null;
  identifierType: string;
  initials: string | null;
  lastName: string | null;
  maritalStatus: string | null;
  middleName: string | null;
  passportNumber: string | null;
  primaryContactNumber: string | null;
  primaryEmailAddress: string | null;
  recoveryContactNumber: string | null;
  recoveryEmailAddress: string | null;
  registrationType: string | null;
  retirementAge: number;
  samAccount: string | null;
  smokingInd: number;
  title: string | null;
  preferredContactMethod: string | null;
  userType: UserType;
}

export interface UpdateProfileDetails extends ProfileDetails, ContactDetails {}

export interface Address {
  address: string | null;
  suburb: string | null;
  city: string | null;
  postalCode: string | null;
  country: string | null;
  isPostal: number;
}

export enum UserType {
  Retail = 'Retail',
  Institutional = 'Institutional',
  Hybrid = 'Hybrid'
}

export enum ProductTypeCategory {
  Retail = 'Retail',
  Institutional = 'Institutional'
}
