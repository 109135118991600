import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, Router, RouterState } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter, Observable, Subscription, switchMap, take, timer } from 'rxjs';
import appsetting from 'src/assets/appsetting.json';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { IdleStateService } from './idle-state/services/idle-state.service';
import {
  IDLE_TIMEOUT_DURATION_IN_MINUTES,
  IDLE_MODAL_TIMER_DURATION_IN_MINUTES
} from './core/constants/base.constants';
import { UserType } from './profile/models/profile.model';
import { ProfileService } from './profile/services/profile.service';
import { NotificationInfo, NotificationService, NotificationType } from './shared/services/notification.service';
import { flyInOut } from './core/constants/animation.constants';
import { LoadingPageService } from './loading-page/services/loading-page.service';
import AppSettings from 'src/assets/appsetting.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [flyInOut]
})
export class AppComponent implements OnInit, OnDestroy {
  public username: string;
  public title = 'AFC-Member-Dashboard-UI';
  public isAuthenticated = false;
  public isFinishedNavigation$: Observable<boolean>;
  public showExpiredSessionModal = false;
  public idleModalDurationInMinutes: number;
  public notificationInfo: NotificationInfo;
  public notificationStatus$: Subscription;
  public NotificationType = NotificationType;
  public disableProfile = !appsetting.featureToggles.profile;
  public isLoading = false;

  USER_TYPE = UserType;
  hasSwitchIndicator = false;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private titleService: Title,
    private idleStateService: IdleStateService,
    public profileService: ProfileService,
    public notificationService: NotificationService,
    public loadingPageService: LoadingPageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.handleRouteEvents();
  }

  ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe({
      next: ({ isAuthenticated, userData, errorMessage }) => {
        if (errorMessage) {
          this.loadingPageService.authorizeResult(false);
          return;
        }

        if (isAuthenticated) {
          // Change the loading state
          this.loadingPageService.authorizeResult(true);

          this.isAuthenticated = isAuthenticated;
          this.username = `${userData.given_name} ${userData.family_name}`;
          this.idleStateService.timeout = IDLE_TIMEOUT_DURATION_IN_MINUTES;
          this.idleModalDurationInMinutes = IDLE_MODAL_TIMER_DURATION_IN_MINUTES;

          // Fetch Profile and indicate type of dashboard shown
          if (!this.disableProfile) {
            this.profileService.getProfile();
            this.profileService.hybridUserIndicator$.subscribe((indicator) => (this.hasSwitchIndicator = indicator));
          }
        } else {
          this.loadingPageService.authorizeResult(false);
        }
      },
      error: (err) => {
        this.loadingPageService.authorizeResult(false);
      }
    });

    // Temporary solution to know if the token endpoint is failing
    timer(5000)
      .pipe(switchMap(() => this.oidcSecurityService.getAccessToken()))
      .subscribe({
        next: (token) => {
          this.loadingPageService.authorizeResult(!!token);
        },
        error: () => {
          this.loadingPageService.authorizeResult(false);
        }
      });

    this.isFinishedNavigation$ = this.router.events.pipe(
      filter((event: any) => {
        let isLoaded = false;
        if (event.url !== '/unauthorized') {
          switch (true) {
            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError: {
              isLoaded = true;
            }
          }
        }
        return isLoaded;
      }),
      take(1)
    );

    this.idleStateService.idleState$.subscribe((isIdle: boolean) => {
      this.showExpiredSessionModal = isIdle;
      if (isIdle) this.idleStateService.stopTimer();
    });

    this.notificationStatus$ = this.notificationService.notificationSection$.subscribe(
      (notificationInfo: NotificationInfo) => {
        this.notificationInfo = notificationInfo;
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadingPageService.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  ngOnDestroy(): void {
    this.idleStateService.stopTimer();
    this.notificationStatus$.unsubscribe();
  }

  handleRouteEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        this.titleService.setTitle(title);
        gtag('event', 'page_view', {
          page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: this.document.location.href
        });
      }
    });
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

  resetPassword() {
    window.open(appsetting.oidcConfig.adfsResetPassword, '_self');
  }

  linkToContactAdviser() {
    window.open(AppSettings.urls.contactAdviser, '_blank');
  }

  logout() {
    // setTimeout(() => sessionStorage.removeItem('USER_TYPE'), 250);
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe();
  }

  continueUserSession(): void {
    this.showExpiredSessionModal = false;
    this.idleStateService.startTimer();
  }

  switchUserType(type: UserType): void {
    sessionStorage.setItem('USER_TYPE', type);
  }

  closeNotification(): void {
    this.notificationService.showNotification({ notificationType: NotificationType.Default, title: '' });
  }
}
