import { EMPTY_LINE } from 'src/app/core/constants/base.constants';

export const formatDataField = (data?: any): string | number | null => {
  if (!data && data !== 0)
    return EMPTY_LINE;

  if (typeof data === 'string' || typeof data === 'number')
    return data;
    
  return null;
};
