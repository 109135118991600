<div
  [ngClass]="['chip', variant]"
  [ngStyle]="{
    borderRadius: borderRadius + 'px',
    background: additionalStyle && additionalStyle.background ? additionalStyle.background : ''
  }"
>
  <div
    [ngStyle]="{
      color: additionalStyle && additionalStyle.color ? additionalStyle.color : ''
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
