import { Injectable } from '@angular/core';
import { ApiPaths } from '../../core/constants/api-paths.constants';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class FuneralCoverService {
  constructor(private http: HttpClientService) {}

  downloadBeneficiaryPdf(body: any): any {
    return this.http.post<any>(ApiPaths.pdfGenerationNOB, body);
  }
}
