<div
  class="field-container"
  [ngClass]="{
    'label-before': labelPosition === 'before',
    'size-box': size === 'box',
    checked: checked,
    disabled: disabled
  }"
>
  <div [ngClass]="['radio-container', variant]">
    <input
      #input
      type="radio"
      class="input"
      [id]="inputId"
      [checked]="checked"
      [disabled]="disabled"
      [attr.value]="value"
      (change)="onChange($event)"
      (blur)="onBlur()"
    />
    <div class="touch-target" (click)="onTouchTargetClick($event)"></div>
    <div class="radio-visualization">
      <span [class]="['outer-circle', variant, outerCircleColor]"></span>
      <span *ngIf="variant === 'default'" class="inner-circle"></span>
      <span *ngIf="variant === 'checkbox' || variant === 'cube'" class="check-mark"></span>
    </div>
  </div>
  <label class="label" [for]="inputId" [ngStyle]="{ 'font-size': labelSize }">
    <ng-content></ng-content>
  </label>
</div>
