import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum ActiveDownloadSection {
  Success = 'Success',
  Error = 'Error',
  Loading = 'Loading',
  Default = 'Default'
}

@Component({
  selector: 'afc-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss']
})
export class DownloadModalComponent {
  private _activeDownloadSection: ActiveDownloadSection | undefined;
  public ActiveModalSection = ActiveDownloadSection;
  public modalStartingText = 'Downloading...';
  public modalHeader = this.modalStartingText;
  public modalVariant: 'error' | 'info' = 'info';

  @Input() set activeDownloadSection(activeDownloadSection: ActiveDownloadSection | undefined) {
    this._activeDownloadSection = activeDownloadSection;

    if (this.activeDownloadSection === ActiveDownloadSection.Success) {
      this.modalHeader = 'Success!';
    }

    if (this.activeDownloadSection === ActiveDownloadSection.Error) {
      this.modalHeader = 'Something went wrong!';
      this.modalVariant = 'error';
    }
  }

  public get activeDownloadSection() {
    return this._activeDownloadSection;
  }

  @Output() onCloseDownload = new EventEmitter<void>();

  public onClose() {
    this.modalVariant = 'info';
    this.modalHeader = this.modalStartingText;
    this.activeDownloadSection = undefined;
    this.onCloseDownload.emit();
  }
}
