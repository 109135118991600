import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
    name: 'fileSize'
  })
  export class FileSizePipe implements PipeTransform {
  
    transform(bytes: number = 0, decimals: number = 2): string {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1024,
         dm = decimals || 2,
         sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
         i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  
  }