<button
  [class.hybrid]="hybrid"
  [ngClass]="[
    alternateDisabledColor ? 'disabled-blue-color' : '',
    fullWidth ? 'fullWidth' : '',
    'button',
    size,
    variant,
    activeItem === title || isActive ? 'active' : '',
    iconPosition,
    loading ? 'loading' : ''
  ]"
  title=""
  [disabled]="disabled || loading"
  (click)="onClick($event)"
>
  <ng-content select="[loading-spinner]"></ng-content>
  <div
    *ngIf="iconSrc"
    [ngStyle]="{
      '-webkit-mask-image': 'url(' + iconSrc + ')',
      width: iconSize + 'px'
    }"
    class="icon"
  ></div>
  <span [class]="['title', size, variant]"
    >{{ title }}
    <ng-content></ng-content>
  </span>
  <ng-content select="[content-on-right]"></ng-content>
  <ng-content select="[contentAfterTitle]"></ng-content>
  <span *ngIf="loading" class="loading-bg">
    <afc-loading-ellipsis [size]="10" [spacing]="4"></afc-loading-ellipsis>
  </span>
</button>
<input *ngIf="isUploadButton" #fileInput class="file-input" type="file" (change)="onFileUpload($event)" />
