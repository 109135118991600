import { Component, EventEmitter, Output, Input } from '@angular/core';
import { RelationshipType } from 'src/app/funeral-cover/models/funeral-cover.model';
import { Beneficiary } from 'src/app/shared/models/nob.model';
import { NobService, NominationTypeId } from 'src/app/shared/services/nob.service';

@Component({
  selector: 'afc-fc-beneficiaries',
  templateUrl: './fc-beneficiaries.component.html',
  styleUrls: ['./fc-beneficiaries.component.scss']
})
export class FcBeneficiariesComponent {
  tableHeaders = ['', 'First name', 'Surname', 'Email', 'Phone number', 'ID number', 'Relationship', ''];
  NominationTypeId = NominationTypeId;

  @Input() data: any;
  @Output() onEditAction = new EventEmitter<number>();

  constructor(public nobService: NobService) {}

  openModal(beneficiaryId: number): void {
    this.onEditAction.emit(beneficiaryId);
  }

  sort(beneficiaries: Beneficiary[] | undefined): Beneficiary[] | undefined {
    return beneficiaries?.sort((a, b) => a.nomineeIdentifier! - b.nomineeIdentifier!);
  }

  getRelationship(relationshipId: number): string {
    let relationshipTypes: RelationshipType[] | null | undefined;
    
    this.nobService.relationshipTypes$.subscribe(types => relationshipTypes = types);
    return relationshipTypes?.find((r: RelationshipType) => r.id === relationshipId)?.value || '';
  }
}
