<div class="loading-icon">
    <img 
      src="assets/images/loading-images/{{ loadingImageName }}.webp" 
      alt="Image not found" 
      [width]="isMobile ? 302 : 400">
</div>
<div class="loading-bar">
  <ng-container *ngIf="loadingStatus != LoadingStatus.Error then loading; else error"></ng-container>
</div>

<ng-template #loading>
  <afc-progress-bar 
    [withProgressAnimation]="true" 
    [progressValue]="progress" 
    primaryColor="secondary-green"
    secondaryColor="yellow"
    [width]="isMobile ? 17.75 : 37.5" 
    [height]="0.70"
    [loadingBar]="true"
  >
  </afc-progress-bar>
  <div class="loading-state">
    <span class="loading-message">{{ loadingMessage }}</span>
    <afc-loading-ellipsis [color]="ellipsisColor" [size]="isMobile ? 11 : 16"></afc-loading-ellipsis>
  </div>
</ng-template>

<ng-template #error>
  <div class="error-state">
    <span class="loading-message error">
      There was an issue loading your dashboard. Please 
      <a class="redirect-link" (click)="login()">try again</a>
      later.
    </span>
  </div>
</ng-template>
