<div class="field-container">
  <div [ngClass]="['label-container', withTooltip ? 'tooltip' : '', label ? 'hasLabel' : 'noLabel']">
    <label class="label" [for]="id"> {{ label }}<span *ngIf="withAsterisk" class="asterisk">*</span></label>

    <div #secondaryLabel class="secondary-label">
      <ng-content select="[secondaryLabel]"></ng-content>
    </div>
    <afc-form-validation-errors 
      *ngIf="control && control.dirty && control.touched && control.invalid && type !== 'textarea'"
      [control]="control"
      [fieldText]="errorReferenceText || label"
      [class.full-width]="secondaryLabel.children.length > 0"
    >
    </afc-form-validation-errors>
    <span *ngIf="additionalDesc" class="additional-description">{{ additionalDesc }}</span>
  </div>
  <div class="input-container">
    <ng-container
      *ngTemplateOutlet="!specialCharAtEnd ? (type === 'textarea' ? textarea : regularInput) : specialCharInput"
    >
    </ng-container>
    <ng-container *ngIf="type === 'textarea'" [ngTemplateOutlet]="textareaValidation"> </ng-container>
  </div>
  <div
    *ngIf="control && control.errors && control.dirty && control.touched && withIcon && type !== 'textarea'"
    class="error-container"
  >
    <img src="assets/icons/warning.svg" />
  </div>
  <img *ngIf="customIcon" class="end-of-line-icon" width="16" height="16" [src]="customIcon" />
</div>

<ng-template #regularInput>
  <input
    [type]="type"
    [step]="step"
    size="10"
    class="field-container-input"
    [ngClass]="[
      variant,
      isDisabled ? 'disabled' : 'not-disabled',
      noFormEnabledBorder ? 'no-form-border' : '',
      (control && control.errors && control.dirty && control.touched) || hasError ? 'hasError' : '',
      control && variant === 'read-only' && control.disabled ? 'non-editable' : ''
    ]"
    [ngStyle]="{ 'border-radius': borderRadius + 'px' }"
    [attr.maxLength]="maxLength"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [disabled]="isDisabled || variant === 'read-only'"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (keyup)="handleInputChange($event)"
  />
</ng-template>

<ng-template #specialCharInput>
  <input
    [type]="type"
    [step]="step"
    size="10"
    class="field-container-input"
    [ngClass]="[
      variant,
      isDisabled ? 'disabled' : 'not-disabled',
      noFormEnabledBorder ? 'no-form-border' : '',
      (control && control.errors && control.dirty && control.touched) || hasError ? 'hasError' : '',
      control && variant === 'read-only' && control.disabled ? 'non-editable' : ''
    ]"
    [style.borderRadius.px]="borderRadius"
    [attr.maxLength]="maxLength"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [disabled]="isDisabled || variant === 'read-only'"
    [inputSpecialChar]="specialCharAtEnd"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (keyup)="handleInputChange($event)"
  />
</ng-template>

<ng-template #textarea>
  <textarea
    class="field-container-input textarea"
    [ngClass]="[
      type,
      variant,
      isDisabled ? 'disabled' : 'not-disabled',
      control && control.errors && control.dirty && control.touched ? 'hasError' : '',
      control && variant === 'read-only' && control.disabled ? 'non-editable' : ''
    ]"
    [attr.maxLength]="maxLength"
    [(ngModel)]="value"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (keyup)="handleInputChange($event)"
  >
  </textarea>
</ng-template>

<ng-template #textareaValidation>
  <div class="validation-errors__textarea">
    <afc-form-validation-errors
      *ngIf="control && type === 'textarea'"
      [control]="control"
      [fieldText]="errorReferenceText || label"
    >
    </afc-form-validation-errors>
    <div class="character-count">{{ charCount }}/{{ maxCharLimit }}</div>
  </div>
</ng-template>
