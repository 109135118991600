import { ClaimSubmissionType } from 'src/app/beneficiaries/models/submit.claim.model';
import { BankDetailsQuery } from 'src/app/beneficiaries/models/two-pot.model';
import { HistoricalStatementsRequest } from 'src/app/statements-and-documents/models/statements.model';
import AppSettings from 'src/assets/appsetting.json';

export class ApiPaths {
  /* Base constants*/
  public static readonly domainUrl: string = AppSettings.urls.afConnectUrl;
  public static readonly afOnlineBaseUrl: string = AppSettings.urls.afOnlineBaseUrl;
  public static readonly documentsBaseUrl: string = AppSettings.urls.documentGenUrl;
  public static readonly benefitsBaseUrl: string = AppSettings.urls.benefitsUrl;
  public static readonly digitalClaimsBaseUrl: string = AppSettings.urls.digitalClaimsUrl;

  /* afGroupId */
  private static readonly afGroupIdBaseUrl: string = ApiPaths.domainUrl + '/AFGroupID/';
  public static readonly getAfGroupId: string = ApiPaths.afGroupIdBaseUrl + 'GetAFGroupID';
  public static readonly ClearSVOPRecordCacheUrl = (schemeNumber: string, sourceSystemName: string) =>
    ApiPaths.afGroupIdBaseUrl + `ClearSVOPRecordCache?schemeNumber=${schemeNumber}&sourceSystemName=${sourceSystemName}`;
  public static readonly SVOPRecordUrl = (schemeNumber: string, sourceSystemName: string) => 
    ApiPaths.afGroupIdBaseUrl + `GetSVOPRecord?schemeNumber=${schemeNumber}&sourceSystemName=${sourceSystemName}`;

  /* Faq */
  public static readonly getFaqUrl: string = ApiPaths.documentsBaseUrl + '/PDF/GetAFConnectFAQ';

  /* Notifications */
  public static readonly getNotificationUrl: string = ApiPaths.documentsBaseUrl + '/Notice/GetNoticeToDisplay';

  /* Profile */
  public static readonly profileDetailsUrl: string = ApiPaths.afOnlineBaseUrl + '/Account/UpdatePersonalDetails';

  /* Products */
  public static readonly productsUrl: string = 'https://www.alexforbes.com/za/en/individual/products-individual.html';

  /* Contact adviser */
  public static readonly contactAdviser: string =
    'https://kfta.app.alexanderforbes.co.za/TotalAgility/Forms/Digital/AFCWAFAmobile.form';
  public static readonly contactAdviserWeb: string =
    'https://kfta.app.alexanderforbes.co.za/TotalAgility/Forms/digital/AFCWAFA.form?vddlRegion=SouthAfrica';

  /* Investments and policies */
  public static readonly addExternalUrl: string = ApiPaths.afOnlineBaseUrl + '/OMNI/BeneficiaryNomination';

  /* External saving and investments */
  public static readonly externalSavingAndInvestmentsUrl: string = ApiPaths.afOnlineBaseUrl + '/Portal/Individual/Home';

  /* Digital Claims */
  public static readonly digitalClaimsMaintainUrl: string = ApiPaths.digitalClaimsBaseUrl + '/ClaimsMaintain';
  public static readonly digitalClaimsVerifyBankingDetails: string =
    ApiPaths.digitalClaimsBaseUrl + '/Verification/verifybankingdetails';
  public static readonly uploadSupportingDocumentUrl: string =
    ApiPaths.digitalClaimsMaintainUrl + '/uploadsupportingdocument';
  public static readonly processByMemberClaimUrl: string = ApiPaths.digitalClaimsMaintainUrl + '/processbymemberclaim';
  public static readonly processByMemberClaimV2Url = (claimSubmissionType: ClaimSubmissionType) =>
    `${ApiPaths.digitalClaimsMaintainUrl}/processbymemberclaimv2?claimSubmissionType=${claimSubmissionType}`;
  public static readonly cancelClaim: string = ApiPaths.digitalClaimsMaintainUrl + '/cancelClaim';

  /* Claims */
  public static readonly claimsBaseUrl: string = ApiPaths.domainUrl + '/Claims';
  public static readonly claimsUrl: string = ApiPaths.domainUrl + '/Claims/GetMemberClaims';
  public static readonly claimDetailsUrl = (caseNo: number) =>
    `${ApiPaths.domainUrl}/Claims/GetClaimDetails?caseNo=${caseNo}`;
  public static readonly getPaymentLetters = (caseNo: number) =>
    `${ApiPaths.domainUrl}/Claims/GetPaymentLetters?caseNo=${caseNo}`;
  public static readonly getPublishedPaymentLetter = `${ApiPaths.domainUrl}/TaxCertificates/GetPublishedDocumentDetails`;
  public static readonly checkClaimAllowedUrl: string = `${ApiPaths.claimsBaseUrl}/CheckClaimAllowed`;

  /* Statements */
  public static readonly statementsUrl: string = ApiPaths.domainUrl + '/Statements';
  public static readonly getLatestStatementsDetails = () => `${ApiPaths.statementsUrl}/LatestStatementsDetails`;
  public static readonly getHistoricalStatements = () => `${ApiPaths.statementsUrl}/GetHistoricalStatements`;
  public static readonly downloadStatementPdf = `${ApiPaths.statementsUrl}/DownloadStatement`;

  /* Tax Certificates */
  public static readonly taxCertificatesUrl: string = ApiPaths.domainUrl + '/TaxCertificates';
  public static readonly getTaxCertificatesDetails = () =>
    `${ApiPaths.taxCertificatesUrl}/LatestTaxCertificatesDetails`;
  public static readonly getHistoricalTaxCertificates = () =>
    `${ApiPaths.taxCertificatesUrl}/GetHistoricalTaxCertificates`;
  public static readonly downloadTaxCertificates = (certType: string, periodType: string) =>
    `${ApiPaths.taxCertificatesUrl}/DownloadPDF/${certType}?periodType=${periodType}`;
  public static readonly getMembershipCerificates = (caseKey: string, caseMemberKey: string) =>
    `${ApiPaths.taxCertificatesUrl}/GetMembershipCertificates?caseKey=${caseKey}&caseMemberKey=${caseMemberKey}`;
  public static readonly getPaidUpMembershipCerificates = (caseKey: string, caseMemberKey: string) =>
    `${ApiPaths.taxCertificatesUrl}/GetPaidUpMembershipCertificates?caseKey=${caseKey}&caseMemberKey=${caseMemberKey}`;

  /* Beneficiaries */
  private static readonly beneficiariesBaseUrl: string = ApiPaths.domainUrl + 'beneficiaries';
  public static readonly beneficiariesGetItem = (id: number) => `${ApiPaths.beneficiariesBaseUrl}/${id}`;
  public static readonly getFunds = (afGroupID: string | null, samAccount: string | null) =>
    `${ApiPaths.domainUrl}/Fund/GetFundSummary?AFGroupID=${afGroupID}&samAccount=${samAccount}`;

  public static readonly getInternalFundSummary = `${ApiPaths.domainUrl}/Fund/GetInternalFundSummary`;
  public static readonly getInternal = `${ApiPaths.domainUrl}/Fund/Internal`;
  public static readonly getExternalFunds = `${ApiPaths.domainUrl}/Fund/GetExternalFunds`;
  public static readonly getInternalPolicies = `${ApiPaths.domainUrl}/Fund/GetInternalPolicies`;

  public static readonly getFundDetails = (fundId: string) => `${ApiPaths.domainUrl}/Fund/GetFundDetails/${fundId}`;
  public static readonly getTransactionDetails = (fundId: string) =>
    `${ApiPaths.domainUrl}/Fund/GetFundDetails/${fundId}/TransactionDetails`;
  public static readonly getIrr = (fundId: string) => `${ApiPaths.domainUrl}/Fund/GetFundDetails/${fundId}/Irr`;
  public static readonly getInvestmentTypes = () => `${ApiPaths.domainUrl}/Fund/GetExternalInvestmentAndProductTypes`;
  public static readonly addExternalFund = () => `${ApiPaths.domainUrl}/Fund/AddExternalFund`;
  public static readonly updateExternalFund = () => `${ApiPaths.domainUrl}/Fund/UpdateExternalFund`;
  public static readonly deleteExternalFund = (fundId: string) =>
    `${ApiPaths.domainUrl}/Fund/DeleteExternalFund?fundid=${fundId}`;
  public static readonly getBeneficiaries = () => `${ApiPaths.domainUrl}/beneficiaries/GetBeneficiariesDetails`;
  //  public static readonly beneficiariesGetTwoParams = (id: number, something?: number) => `${ApiPaths.beneficiariesBaseUrl}/${id}?something=${something}`;
  public static readonly downloadPortfolio = (udwID: string) =>
    `${ApiPaths.domainUrl}/Udw/DownloadPortfolioDocument/${udwID}`;

  public static readonly getProfile = `${ApiPaths.domainUrl}/Profile/GetProfileDetails`;
  public static readonly putProfile = () => `${ApiPaths.domainUrl}/Profile/UpdateProfileDetails`;

  /* Documents */
  public static readonly getMemberDocuments = () => `${ApiPaths.documentsBaseUrl}/PDF/GetMemberDocuments`;

  public static readonly downloadMemberDocument = (fileId: string) =>
    `${ApiPaths.documentsBaseUrl}/PDF/DownloadMemberDocument?fileId=${fileId}`;
  public static readonly downloadStaffClaimForm = `${ApiPaths.documentsBaseUrl}/PDF/DownloadTemplate?filename=AF-Connect-SavingsPotWithdrawalForm`;

  /* Benefits NOB Unapproved */
  public static readonly getNomination = `${ApiPaths.benefitsBaseUrl}/Nomination`;
  public static readonly processNomination = `${ApiPaths.benefitsBaseUrl}/Nomination/processNomination`;
  public static readonly replaceUnapprovedNOB = `${ApiPaths.benefitsBaseUrl}/Nomination/replace`;
  public static readonly getValuesetsNOB = `${ApiPaths.benefitsBaseUrl}/Nomination/valuesets/all`;
  public static readonly pdfGenerationNOB = `${ApiPaths.benefitsBaseUrl}/Pdf/GenerateUnapprovedFuneralNOBPDF`;
  public static readonly nominationPdf = `${ApiPaths.benefitsBaseUrl}/Pdf/GenerateRetirementSavingsNOBPDF`;
  public static readonly unapprovedLumpSumPdfPdf = `${ApiPaths.benefitsBaseUrl}/Pdf/GenerateUnapprovedLumpSumNOBPDF`;

  /* Two-Pot */
  public static readonly getKbaBase: string = ApiPaths.domainUrl + '/KBA';
  public static readonly getKbaRequestId: string = ApiPaths.getKbaBase + '/GetRequestId';
  public static readonly getKbaQuestions = (reqId: string, respType: string) =>
    `${ApiPaths.getKbaBase}/GetQuestions?requestId=${reqId}&responseType=${respType}`;
  public static readonly postKbaAnswers = (afGroupID: string) =>
    `${ApiPaths.getKbaBase}/PostAnswers?afGroupId=${afGroupID}`;
  public static readonly getKbaStatus = `${ApiPaths.getKbaBase}/CheckMemberStatus`;

  public static readonly deleteSupportingDocumentsUrl: string = ApiPaths.domainUrl + '/Documents/DeleteDocument';
  public static readonly twoPotsInstitutionalUrl: string = ApiPaths.domainUrl + '/TwoPotInstitutional';
  public static readonly twoPotVerifyId: string = ApiPaths.twoPotsInstitutionalUrl + '/IdVerification';
  public static readonly taxNumberValidation = (taxNumber: string) =>
    `${ApiPaths.twoPotsInstitutionalUrl}/TaxNumberValidation?taxNumber=${taxNumber}`;
  public static readonly idNumberValidation = (idNumber: string) =>
    `${ApiPaths.twoPotsInstitutionalUrl}/IdNumberValidation?idNumber=${idNumber}`;
  public static readonly getTwoPotDocTypes = () => `${ApiPaths.twoPotsInstitutionalUrl}/GetDocumentTypes`;
  public static readonly bankDetailsValidation = (query: BankDetailsQuery) =>
    `${ApiPaths.twoPotsInstitutionalUrl}/BankDetailsValidation?accountNumber=${query.accountNumber}&accountType=${query.accountType}&branchCode=${query.branchCode}`;

  public static readonly statementsCount = `${ApiPaths.statementsUrl}/Count`;
  public static readonly taxCertificatesCount = `${ApiPaths.taxCertificatesUrl}/Count`;
  public static readonly getStatements = (query?: HistoricalStatementsRequest) =>
    `${ApiPaths.statementsUrl}${`?IncludeOlder=${query?.includeOlder ? query.includeOlder : false}`}${
      query?.timeInterval?.fromDate ? `&TimeInterval.FromDate=${query.timeInterval?.fromDate}` : ''
    }${query?.timeInterval?.toDate ? `&TimeInterval.ToDate=${query.timeInterval?.toDate}` : ''}${
      query?.years ? query.years.map((year) => `&Years=${year}`).join('') : ''
    }${query?.fundNames ? query.fundNames.map((name) => `&FundNames=${name}`).join('') : ''}${
      query?.statementTypes ? query.statementTypes.map((type) => `&StatementTypes=${type}`).join('') : ''
    }${query?.pageNumber ? `&PageNumber=${query.pageNumber}` : ''}`;
  public static readonly statementsDownload = (dataUploadSk: number, statementURL: string) =>
    `${ApiPaths.statementsUrl}/Download?DataUploadSk=${dataUploadSk}&StatementUrl=${statementURL}`;

  /* Notifications */
  public static readonly notificationsBaseUrl: string = ApiPaths.domainUrl + '/Notifications';
  public static readonly deceasedMemberAlertUrl: string = ApiPaths.notificationsBaseUrl + '/SendDeceasedMemberAlert';
}
