<afc-modal
  [variant]="modalVariant"
  [isOpen]="!!activeDownloadSection"
  variant="info"
  [header]="modalHeader"
  (onClose)="onClose()"
  [width]="370"
>
  <ng-container [ngSwitch]="activeDownloadSection">
    <ng-container *ngSwitchCase="ActiveModalSection.Success">
      <div class="download-content">Your download is ready!</div>

      <div class="button-row">
        <afc-button title="Okay" size="large" variant="filled" [fullWidth]="true" (clickBtn)="onClose()"> </afc-button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="ActiveModalSection.Error">
      <div class="download-content">Unfortunately there was a problem with your download. Please try again later.</div>
      <div class="button-row">
        <afc-button title="Close" size="large" variant="filled" [fullWidth]="true" (clickBtn)="onClose()"> </afc-button>
      </div>
    </ng-container>
    <div *ngSwitchCase="ActiveModalSection.Loading" class="spinner-container">
      <afc-loading-spinner></afc-loading-spinner>
    </div>
  </ng-container>
</afc-modal>
