<afc-card variant="small" ngClass="expandable-card">
  <afc-expandable-wrapper>
    <div class="latest-statements-card-mobile" (click)="toggle()">
      <div class="latest-statements-card-mobile__date bold">{{ firstTitle }}</div>
      <div class="latest-statements-card-mobile__name"><ng-content select="[secondTitle]"></ng-content></div>
      <afc-expandable-arrow></afc-expandable-arrow>
    </div>

    <afc-expandable-container [isExpanded]="isExpanded">
      <div class="items-container">
        <ng-content></ng-content>
      </div>
      <afc-button *ngIf="buttonTitle" [title]="buttonTitle" size="small" variant="filled"></afc-button>
      <ng-content select="afc-button"></ng-content>
    </afc-expandable-container>
  </afc-expandable-wrapper>
</afc-card>
