import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Position } from './types';

@Component({
  selector: 'afc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() text: string;
  @Input() position: Position = 'bottomRight';
  @Input() showTooltip: boolean = true;
  @Input() top: string;
  @Input() right: string;
  @Input() left: string;
  @Input() autoSize: boolean;
  @Input() zIndex: number = 10;
  @Input() noContentPadding = false;
  @Input() dynamicPosition = false;
  @Input() minWidth: string;
  @Input() maxHeight: string;
  @ViewChild('tooltipTextBox') tooltipTextBox: ElementRef;

  ngOnInit(): void {
    if (this.dynamicPosition) this.adjustPosition();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.dynamicPosition) this.adjustPosition();
  }

  public adjustPosition() {
    const element = this.tooltipTextBox.nativeElement;
    const elementRect = element.getBoundingClientRect();
    const windowWidth = window.innerWidth;

    element.style.left = elementRect.left < windowWidth / 3 ? '-8rem' : '-19rem';
  }
}
