import { NgModule } from '@angular/core';
import {
  AbstractSecurityStorage,
  AuthModule
} from 'angular-auth-oidc-client';
import appsetting from '../../assets/appsetting.json';
import { AuthStorageService } from './services/AuthStorageService';

@NgModule({
  imports: [
    AuthModule.forRoot({
    config: {
      authority: appsetting.oidcConfig.adfsStsServer,
      redirectUrl: `${window.location.origin}/unauthorized`,
      postLogoutRedirectUri: window.location.origin,
      clientId: appsetting.oidcConfig.adfsClientId,
      scope: appsetting.oidcConfig.adfsScope,
      responseType: appsetting.oidcConfig.adfsResponseType,
      silentRenew: true,
      autoUserInfo: false,
      renewTimeBeforeTokenExpiresInSeconds: 60,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      issValidationOff: false,
      useRefreshToken: true,
      customParamsAuthRequest: {
        prompt: 'select_account', // login, consent
      },
      ignoreNonceAfterRefresh: true,
      triggerRefreshWhenIdTokenExpired: false,
      customParamsRefreshTokenRequest: {
        scope: appsetting.oidcConfig.adfsScope
      },
      // adfs uses a single repeating refresh token; available for 8 hours before expiry
      allowUnsafeReuseRefreshToken: true
    }
  })
],
  providers: [{ provide: AbstractSecurityStorage, useClass: AuthStorageService}],
  exports: [AuthModule],
})
export class AuthConfigModule {}
