<div
  [class]="['card-header', variant]"
  [class.no-subtitle]="!hasSubtitle"
  (click)="toggleMobileContent()">
  <div class="title-container">
    <div class="inner-title-container">
      <div class="heading-container">
        <span>{{ title }}</span>
        <ng-content select="[headingChip]"></ng-content>
      </div>
      <div class="chip-container">
        <div class="chip-container-section">
            <ng-content select="[chip]"></ng-content>
            <ng-content select="[subtitle]" class="subtitle" class="[subtitleColor]"></ng-content>
        </div>
      </div>
    </div>
    <div *ngIf="hasSubtitle" class="subtitle" [class]="[subtitleColor]">{{ subTitle }}</div>
  </div>
  <div class="buttons-container">
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="!hideBorderLine" [class]="['border-line', variant]"></div>
