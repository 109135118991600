import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { formatAfcDate } from '../utilities/date.util';
/**
 * Transforms date to AFC date forms
 */
@Pipe({ name: 'afcDate' })
@Injectable({
  providedIn: 'root'
})
export class AfcDatePipe implements PipeTransform {
  transform(value: Date | string | null | undefined, format: string = 'D MMM YYYY'): string {
    return formatAfcDate(value, format);
  }
}
