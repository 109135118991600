import { Component, ContentChild, ElementRef, Input, TemplateRef } from '@angular/core';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';

@Component({
  selector: 'tr[afc-table-row]',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss']
})
export class TableRowComponent {
  constructor(public breakpointService: BreakpointService) {}
  
  @ContentChild('stickyColumn') public stickyColumn: TemplateRef<any>;
}
