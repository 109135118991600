import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Default = 'default'
}

export interface NotificationInfo {
  notificationType: NotificationType;
  title: string;
  message?: string;
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private notificationSection$$ = new BehaviorSubject<NotificationInfo>({notificationType: NotificationType.Default, title: ''});
  public notificationSection$ = this.notificationSection$$.asObservable();

  showNotification(notificationInfo: NotificationInfo){
    this.notificationSection$$.next(notificationInfo);
  }
}
