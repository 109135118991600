<div
  class="header-container"
  [ngClass]="[
    variant,
    isStatic ? 'isStatic' : 'isExpandable',
    (wrapper && wrapper.isExpanded$ | async) === true ? 'expanded' : '',
    (wrapper && wrapper.isExpanded$ | async) === false ? 'collapsed' : ''
  ]"
  (click)="toggleMobileContent()"
>
  <div class="section-wrapper">
    <div class="title {{ variant }}">
      {{ title }}
      <afc-loading-ellipsis
        *ngIf="isLoading"
        content-on-right
        ngClass="header"
        [size]="isMobile ? 10 : 16"
        [spacing]="4"
      ></afc-loading-ellipsis>
    </div>
    <ng-content select="[icon]"></ng-content>
    <div class="buttons-container">
      <ng-content></ng-content>
    </div>
  </div>
  <div *ngIf="isStatic" class="border-line"></div>
</div>
