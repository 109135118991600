import { Component, Input } from '@angular/core';
import { NotificationResponseList } from 'src/app/shared/models/notification-response.model';

@Component({
  selector: 'afc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() data: NotificationResponseList;
  @Input() variant: 'small' | 'wide';
}
