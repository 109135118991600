import { Component, Input, Output, EventEmitter } from '@angular/core';

export enum Severity {
  Error = "Error!",
  Warning = "Warning!",
};

@Component({
  selector: 'afc-inline-error',
  templateUrl: './inline-error.component.html',
  styleUrls: ['./inline-error.component.scss']
})
export class AfcInlineErrorComponent {
  @Input() public severity: Severity = Severity.Error;
  @Input() public message: string;
  @Input() title?: string;
  @Input() src: string;
  @Input() size: 'large' | 'medium' | 'small' | 'none' = 'none';
  @Input() withCloseBtn = false;
  @Output() onClose = new EventEmitter<boolean>();

  public closeErrorMessage() {
    this.onClose.emit();
  }
}
