import {
  Component,
  ContentChildren,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { debounceTime, fromEvent, Subscription } from 'rxjs';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'afc-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements OnDestroy {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @ViewChild('slider', { read: ElementRef }) slider: ElementRef<HTMLSpanElement>;
  @ViewChildren('tab', { read: ElementRef }) tabsRefs: QueryList<ElementRef<HTMLLIElement>>;
  public activeTabIndex: number;
  public subs = new Subscription();

  constructor(public cdr: ChangeDetectorRef) {}

  @Input() selectedTabIndex: number;

  @Output() activeTabSelected = new EventEmitter<number>();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.subs.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(50))
        .subscribe(() => {
          this.triggerSlider(this.activeTabIndex);
        })
    );
  }

  ngAfterContentChecked() {
    if (!this.selectedTabIndex) {
      const activeTab = this.tabs.find((tab) => tab.isActive);
      if (!activeTab) {
        const firstTab = this.tabs.first;
        firstTab.isActive = true;
        this.activeTabIndex = 0;
        this.activeTabSelected.emit(0);
      }
    } else {
      this.setActiveLabel();
    }
  }

  ngAfterViewChecked() {
    if (!this.activeTabIndex) {
      this.triggerSlider(0);
    }
    this.triggerSlider(this.activeTabIndex);
  }

  get activeTab() {
    return this.tabs.find((tab) => tab.isActive) || this.tabs.first;
  }

  activateTab(selectedTab: TabComponent, index: number) {
    this.tabs.forEach((tab) => {
      tab.isActive = tab === selectedTab;
    });
    this.activeTabIndex = index;
    this.triggerSlider(index);
    this.activeTabSelected.emit(index);
  }

  private triggerSlider(i: number) {
    const activeTabRef = this.tabsRefs.get(i);
    this.slider.nativeElement.style.left = `${activeTabRef?.nativeElement.offsetLeft}px`;
    this.slider.nativeElement.style.width = `${activeTabRef?.nativeElement.offsetWidth}px`;
  }

  private setActiveLabel() {
    this.tabs.find((tab, i) => {
      const foundTab = i === this.selectedTabIndex;
      if (foundTab) {
        this.activeTabIndex = i;
        tab.isActive = true;
      }
      return foundTab;
    });
  }
}
