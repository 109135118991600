import { Pipe, PipeTransform } from '@angular/core';
import { UserType } from 'src/app/profile/models/profile.model';
import { InternalFund } from 'src/app/beneficiaries/models/funds.model';
/**
 * Transforms Given Products array to only include values from a given user type
 */
@Pipe({ name: 'filterByUserType' })
export class FilterByUserTypePipe implements PipeTransform {
  transform(products: InternalFund[], userType: UserType): InternalFund[] {
    // Show both Institutional and Retail products (specific requirement for the near future)
    if (userType) {
      if (userType === UserType.Hybrid) {
        return products;
      }
  
      return products?.filter(item => item.productTypeCategory === userType);
    }

    return products;
  }
}
