import { Component, Input } from '@angular/core';

@Component({
  selector: 'afc-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
  @Input() title: string;
  @Input() borderRadius: number;
  @Input() additionalStyle: { background?: string; color?: string };
  @Input() variant:
    | 'greenish-text'
    | 'greenish-count'
    | 'percentage'
    | 'status'
    | 'claims'
    | 'dot'
    | 'dot-orange'
    | 'dot-teal'
    | 'dot-space-black'
    | 'tick'
    | 'case-number' = 'greenish-text';
}
