import { Component, Input } from '@angular/core';

@Component({
  selector: 'afc-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent {
  @Input() src: string;
  @Input() title: string;
  @Input() message: string;
  @Input() alignContent: 'center' | 'left' = 'left';
  @Input() height: 'small' | 'medium' | 'large' | 'xLarge' | 'fill' = 'small';

  constructor() {}

  ngOnInit() {}
}
