export type EmitValue = 'isCloseModal' | '';
export const DISCLAIMER = 'DISCLAIMER';
export const EMPTY_LINE = '--';
export const INPUT_MAX_LENGTH = 20;
export const INPUT_NAME_MAX_LENGTH = 60;
export const PHONE_INPUT_LENGTH = 10;
export const EMAIL_INPUT_MAX_LENGTH = 60;
export const CONTACT_DETAILS_INPUT_MAX_LENGTH = 60;
export const IDLE_TIMEOUT_DURATION_IN_MINUTES = 15;
export const IDLE_MODAL_TIMER_DURATION_IN_MINUTES = 5;
export const IDENTITY_NUMBER_INPUT_LENGTH = 13;
export const PASSPORT_NUMBER_INPUT_LENGTH = 8;
export const PASSPORT_NUMBER_MAX_LENGTH = 13;
export const PASSPORT_NUMBER_INPUT_LENGTH_NOB = 9;
export const TAX_NUMBER_LENGTH = 10;
export const PROFILE_ID_NUMBER = 'Id Number';
export const PROFILE_PASSPORT = 'Passport';
export const PASSPORT_LABEL = 'Passport number';
export const ID_LABEL = 'ID number';
export const SOUTH_AFRICA = 'South Africa';
export const CURRENCY_AMOUNT_MAX_DECIMAL_PLACES = 2;
export const UNIT_NUMBER_MAX_LENGTH = 5;
export const COMPLEX_NAME_MIN_LENGTH = 3;
export const STREET_NUMBER_MAX_LENGTH = 5;
export const STREET_NAME_MIN_LENGTH = 3;
export const SUBURB_MIN_LENGTH = 2;
export const SUBURB_MAX_LENGTH = 33;
export const CITY_MIN_LENGTH = 2;
export const CITY_MAX_LENGTH = 22;
export const POSTAL_CODE_LENGTH = 4;
export const ADDRESS_2_MIN_LENGTH = 2;
export const ADDRESS_3_MIN_LENGTH = 3;
export const TWO_POT_ADDRESS_FIELD_MAX_LENGTH = 200;
export const INPUT_NATIVE_MAX_LENGTH = 524_288;
export const FIVE_MB_BYTES = 5242880;
export const DEFAULT_TIMEOUT_MS = 100_000;
export const STAFF_FUND_SOURCE_SYSTEM = 'KhanyaStaff';
export const STAFF_FUND_EMAIL = 'AFSTAFF2POTCLAIMS@alexforbes.com';

// Messages:

export const HISTORICAL_STATEMENTS_NO_CONTENT_TITLE = 'No historical statements available.';
export const HISTORICAL_STATEMENTS_SELECT_FILTERS_TITLE = 'No statements match the set criteria.';
export const HISTORICAL_STATEMENTS_SELECT_FILTERS_MESSAGE =
  'Please select different filter options by clicking on the "Filter" button.';

export const SUCCESSFULL_DOWNLOAD_TITLE = 'Download successful!';
export const FAILED_DOWNLOAD_TITLE = 'Download failed!';
export const FAILED_DOWNMLOAD_MESSAGE = 'Please try again later.';

export const RELATED_RETAIL_PRODUCTS = [
  'AF Tax Free Savings Account',
  'AF Retirement Income Solution LA',
  'AF Managed Living Annuity'
];

export const TWO_POT_PRODUCTS_WITH_PENSION_SERVICE_COMMENCEMENT_DATE = [
    'AF Retirement Income Solution GRA',
    'AlexForbes Group Retirement Annuity Fund'
];

export const FAILED_UPLOAD_DOCUMENT_TITLE = 'Upload failed!';
export const FAILED_UPLOAD_DOCUMENT_MESSAGE = 'One of your documents failed to upload.';
