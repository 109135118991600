import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  HostListener,
  forwardRef,
  Injector,
  ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { selectAnimation } from 'src/app/core/constants/animation.constants';
import { CountriesCollection } from 'src/app/profile-details/components/contact-details-info/country-collection';

const SMALL_SIZE = 40;
const NORMAL_SIZE = 40;
const LARGE_SIZE = 46;

enum SizeEnum {
  small = SMALL_SIZE,
  normal = NORMAL_SIZE,
  large = LARGE_SIZE
}

@Component({
  selector: 'afc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [selectAnimation],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)'
  }
})
export class SelectComponent implements ControlValueAccessor {
  private _options: any[];
  private _maxItemsShown: number = 8;
  private currentIndex = -1;
  private propagateChange: Function = (_: string) => {};
  private propagateTouch: Function = (_: string) => {};

  public currentValue: any;
  public control: FormControl;
  public filteredOptions: any[] = [];
  public itemsShown = 0;
  public dropdownOpen: boolean = false;
  public dropdownSearch$ = new Subject<string>();
  public SizeEnum = SizeEnum;

  public get maxItemsShown() {
    return this._maxItemsShown;
  }

  @Input() set maxItemsShown(itemsCount: number) {
    this.itemsShown = itemsCount;
    this._maxItemsShown = itemsCount;
  }

  public get options() {
    return this._options;
  }

  @Input() set options(options: any[]) {
    if (this.emptyOption) {
      options.unshift(this.emptyOption);
    }

    this._options = options;
  }

  @Input() public set value(value: any) {
    this.setSelectType();

    // Only update the value and perform additional logic if it has changed

    if (value && value[this.valueField] && value[this.textField]) {
      setTimeout(() => {
        this.propagateChange(value[this.valueField]);
        this.currentValue = value[this.textField];
        this.cdr.detectChanges();
      }, 50);
    } else if (value && this.valueField && this.textField) {
      const selectedItem = this.options.find((item) => item[this.valueField] === value);
      this.currentValue = selectedItem ? selectedItem[this.textField] : this.placeholder;
    } else {
      this.currentValue = value;
    }
  }

  @Input() label: string;
  @Input() errorReferenceText: string;
  @Input() placeholder = 'Please select...';
  @Input() emptyOption: string;
  @Input() withAsterisk: boolean = false;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() isReadOnly: boolean;
  @Input() readOnlyPlaceholder: string;
  @Input() isDisabled: boolean;
  @Input() maxLength: number = 50;
  @Input() size: 'small' | 'normal' | 'large' = 'normal';
  @Input() variant: 'editable' | 'non-editable' | 'read-only' = 'editable';
  @Input() filter = false;
  @Input() filterBy: string | string[];
  // Types of select dropdown
  @Input() type: 'regular' | 'country' | 'country-code' = 'regular';

  @Output() currentValueChange = new EventEmitter();

  @HostListener('document:click', ['$event']) click(event: any) {
    const isInsideClick = this.elementRef.nativeElement.contains(event.target);
    if (this.dropdownOpen) {
      this.dropdownOpen = isInsideClick ? this.dropdownOpen : false;
      if (!isInsideClick) {
        this.markControl();
      }
    }
  }

  constructor(private elementRef: ElementRef, private injector: Injector, private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.dropdownSearch$.pipe(debounceTime(250), distinctUntilChanged()).subscribe((input) => {
      this.itemsShown = 0;
      const userInput = input ? input.toLowerCase() : '';
      // Filter an array by its contents
      if (!this.filterBy) {
        this.filteredOptions = this.options.filter((option) => {
          if (option.toLowerCase().toLowerCase().includes(userInput.toLowerCase())) {
            if (this.itemsShown < this.maxItemsShown) this.itemsShown++;
            return true;
          }
          return false;
        });
      }
      // Filter By an array of objects for a single given key
      if (typeof this.filterBy === 'string') {
        this.filteredOptions = this.options.filter((option) => {
          if (option[this.filterBy as 'string'].toLowerCase().toLowerCase().includes(userInput.toLowerCase())) {
            if (this.itemsShown < this.maxItemsShown) this.itemsShown++;
            return true;
          }
          return false;
        });
      }
      // Filter By an array of obejcts for given keys
      if (Array.isArray(this.filterBy)) {
        this.filteredOptions = this.options.filter((option) => {
          for (const key of this.filterBy) {
            if (option[key].toLowerCase().toLowerCase().includes(userInput.toLowerCase())) {
              if (this.itemsShown < this.maxItemsShown) this.itemsShown++;
              return true;
            }
          }
          return false;
        });
      }
      this.cdr.detectChanges();
    });
  }

  private triggerDropdownSearch(value: string): void {
    if (this.filter) this.dropdownSearch$.next(value);
  }

  private setSelectType(): void {
    switch (this.type) {
      case 'regular':
        this.filteredOptions = this._options;
        break;
      case 'country':
        this.placeholder = 'Please select a country...';
        this.textField = 'name';
        this.valueField = 'name';
        this._options = CountriesCollection;
        this.filteredOptions = CountriesCollection;
        break;
      case 'country-code':
        this.placeholder = '';
        this.textField = 'callingCode';
        this.valueField = 'callingCode';
        this._options = CountriesCollection;
        this.filteredOptions = CountriesCollection;
        break;
    }
  }

  private markControl(): void {
    this.control.markAsTouched();
    this.control.markAsDirty();
  }

  private resetDropdownValue(): void {
    setTimeout(() => {
      this.setSelectType();
      this.itemsShown = this._maxItemsShown;
    }, 250);
  }

  public ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
    if (ngControl) {
      this.control = ngControl.control as FormControl;
    }
    this.itemsShown = this.maxItemsShown;
  }

  public ngOnDestroy(): void {
    this.dropdownSearch$.unsubscribe();
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public onBlur(): void {
    this.propagateTouch();
  }

  public onChange(eventValue: string): void {
    this.triggerDropdownSearch(eventValue);
    this.dropdownOpen = true;
    this.propagateChange(eventValue);
    this.currentValue = eventValue;
    this.currentValueChange.emit(this.currentValue);
  }

  public handleKeyboardEvents($event: KeyboardEvent) {
    if (this.dropdownOpen) {
    } else {
      return;
    }
    if ($event.code === 'ArrowUp') {
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      } else if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      this.elementRef.nativeElement.querySelectorAll('li').item(this.currentIndex).focus();
    } else if ($event.code === 'ArrowDown') {
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      } else if (this.currentIndex < this.options.length - 1) {
        this.currentIndex++;
      }
      this.elementRef.nativeElement.querySelectorAll('li').item(this.currentIndex).focus();
    } else if (($event.code === 'Enter' || $event.code === 'NumpadEnter') && this.currentIndex >= 0) {
      this.selectByIndex(this.currentIndex);
      this.closeDropdown();
    } else if ($event.code === 'Escape') {
      this.closeDropdown();
    }
  }

  public closeDropdown() {
    this.currentIndex = -1;
    this.dropdownOpen = false;
  }

  public selectByIndex(i: number) {
    let value = this.options[i];
    this.select(value);
  }

  public select(value: any) {
    this.dropdownOpen = !this.dropdownOpen;
    if (this.textField && this.valueField) {
      this.propagateChange(value[this.valueField]);
      this.currentValue = value[this.textField];
    } else {
      this.propagateChange(value);
      this.currentValue = value;
    }
    this.currentValueChange.emit(this.currentValue);

    this.toggleDropdown();
    this.resetDropdownValue();
  }

  public toggleDropdown(e?: any) {
    e && e.preventDefault();
    if (!this.isDisabled && this.control && !this.control.disabled && this.variant !== 'read-only') {
      this.dropdownOpen = !this.dropdownOpen;
    }
    if (this.dropdownOpen === false && !this.isDisabled && !this.isReadOnly) {
      this.markControl();
    }
  }
}
