import { NgModule, LOCALE_ID  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { AuthConfigModule } from './auth/auth-config.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './auth/interceptors/token-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material/material.module';
import { AfConnectComponentsModule } from 'af-connect-components';
import { CookieService } from 'ngx-cookie-service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    AuthConfigModule,
    SharedModule,
    MaterialModule,
    AfConnectComponentsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    CookieService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ServerErrorInterceptor,
    //   multi: true,
    // },
    {
      provide: 'googleTagManagerId',  useValue: 'GTM-K9KDXTK3'
    },
    { provide: LOCALE_ID, useValue: 'en-US' },
    GoogleTagManagerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
