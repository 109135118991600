import { Component, ViewChild, Host, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';
import { ProfileDetailsInfoComponent } from '../profile-details-info/profile-details-info.component';
import { ContactDetailsInfoComponent } from '../contact-details-info/contact-details-info.component';
import { UpdateProfileDetails } from 'src/app/profile/models/profile.model';
import { flyInOut } from 'src/app/core/constants/animation.constants';
import { NavigationStart, Router, ActivationStart, NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'afc-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  animations: [flyInOut]
})
export class ProfileDetailsComponent {
  public isEditable: boolean = false;
  public pageName = 'Profile information';
  public parentForm: FormGroup = new FormGroup({});
  public isPopupOpen = false;
  public isOpen = false;
  public openConfirmation = false;
  
  @ViewChild(ProfileDetailsInfoComponent) profileDetailsComponent: ProfileDetailsInfoComponent;
  @ViewChild(ContactDetailsInfoComponent) contactDetailsComponent: ContactDetailsInfoComponent;

  constructor(
    public profileService: ProfileService,
    public breakpointService: BreakpointService,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'profile-details',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  // Map form values to expected keys in UpdateProfileDetails
  private updateContactPayload(): void {
    const contactForm = this.parentForm.value['contact'];
    contactForm['primaryPhoneNumber'] =
    contactForm['primaryCountryCode']?.substring(1) + (contactForm['primaryCountryCode'] === '+27'
     ? contactForm['primaryPhoneNumber']?.substring(1)
     : contactForm['primaryPhoneNumber']);
    delete contactForm['primaryCountryCode'];

    this.profileService.profile$.subscribe(profile => {
      if (profile)
        contactForm['dateOfBirth'] = profile.dateOfBirth;
        contactForm['registrationType'] = profile?.registrationType;
        contactForm['preferredContactMethod'] = profile?.preferredContactMethod;
    });

    // contactForm['recoveryPhoneNumber'] = contactForm['recoveryCountryCode'].substring(1) + contactForm['recoveryPhoneNumber'].substring(1);
    // delete contactForm['recoveryCountryCode'];

    // Do not send recovery details in cases where registrationType is null
    // Send registrationType along with request params otherwise
    // this.profileService.profile$.subscribe(profile => {
    //   if (profile) {
    //     const regType = profile.registrationType;
    //     if (!regType) {
    //       delete contactForm['recoveryPhoneNumber'];
    //       delete contactForm['recoveryEmailAddress'];
    //     } else {
    //       contactForm['registrationType'] = regType;
    //     }
    //   }
    // });

    return contactForm;
  }

  onToggleEdit() {
    this.isEditable = true;
    this.parentForm.enable();
    this.profileDetailsComponent.isPreviewMode=false
    this.contactDetailsComponent.isPreviewMode=false
  }

  onUpdate() {

    const gtmTag = {
      event: 'profile-update-click',
      data: 'profile-update-event',
    };
    this.gtmService.pushTag(gtmTag);

    //Show toast message
    this.isOpen = true;
    this.parentForm.markAsPristine();
    
    this.updateContactPayload();

    // Concatenate response from both forms
    const formsValue: UpdateProfileDetails = {
      ...this.parentForm.value['profile'],
      ...this.parentForm.value['contact']
    };

    // Disable both forms
    this.isEditable = false;
    this.parentForm.disable();

    // Control Success / Error message
    this.isPopupOpen = !this.isPopupOpen;
    // Update Profile Details put request
    this.profileService.updateProfile(formsValue);
  }

  onCancel() {
    if (!this.parentForm.pristine) {
      this.openConfirmation = true;
    } else {
      this.onReset();
    }
  }

  onReset() {
    this.profileDetailsComponent.patchInitialValues();
    this.contactDetailsComponent.patchInitialValues();
    this.parentForm.disable();
    this.isEditable = false;
    this.openConfirmation = false;
    this.parentForm.markAsPristine();
  }

  closeToast() {
    this.isOpen = false;
  }
}
