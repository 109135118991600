import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NobModalService } from 'src/app/beneficiaries/services/nob-modal.service';
import { FcBeneficiaryModalComponent } from 'src/app/funeral-cover/components/funeral-cover/fc-beneficiary-modal/fc-beneficiary-modal.component';
import { UserType } from 'src/app/profile/models/profile.model';
import { ProfileService } from 'src/app/profile/services/profile.service';

@Component({
  selector: 'afc-funeral-cover-summary',
  templateUrl: './funeral-cover-summary.component.html',
  styleUrls: ['./funeral-cover-summary.component.scss']
})
export class FuneralCoverSummaryComponent implements OnInit {
  @Input() beneficiaries: number | null;
  @ViewChild('beneficiaryModal') beneficiaryModal: FcBeneficiaryModalComponent;

  inEditMode = false;
  beneficiaryId?: number;
  isReplaceJourney = false;
  USER_TYPE = UserType;

  constructor(public profileService: ProfileService, public nobModalService: NobModalService) {}

  ngOnInit(): void {}

  public openModal(editMode?: boolean, beneficiaryId?: number): void {
    if (editMode) {
      this.inEditMode = true;
      this.beneficiaryId = beneficiaryId;
    }
    this.nobModalService.openFcModal();
  }

  public closeModal(): void {
    this.beneficiaryModal.onInitiateClose();
  }
}
