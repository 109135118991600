import { Directive, ElementRef, HostListener, Renderer2, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Directive({
  selector: '[inputSpecialChar]'
})
export class InputSpecialCharDirective implements AfterViewInit, OnDestroy {

  @Input('inputSpecialChar') specialChar: string;
  private resizeSubject = new Subject<void>();

  constructor(private el: ElementRef<HTMLInputElement>, private renderer: Renderer2) {
    this.resizeSubject.subscribe(() => {
      this.updateSpecialCharPosition();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.createPercentageSign();
    });
    window.addEventListener('resize', this.onResize.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  private onResize(): void {
    this.resizeSubject.next();
  }
  
  private createPercentageSign(): void {
    const value = this.el.nativeElement.value;
    const parent = this.renderer.parentNode(this.el.nativeElement);
    const span = this.renderer.createElement('span');
    this.renderer.addClass(span, 'special-char');
    this.renderer.setProperty(span, 'textContent', this.specialChar);
    this.renderer.appendChild(parent, span);

    if (!value)
      this.renderer.setStyle(span, 'visibility', 'hidden');
    else
      this.updatePercentageSign(span, value);
  }
  
  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const parent = this.renderer.parentNode(this.el.nativeElement);
    const span = parent.querySelector('.special-char');
    if (!span) return;

    value = value.replace(/[^0-9]/g, '').replace(/^0+/, '');
    value = value.slice(0, 3);
    const intValue = parseInt(value, 10);
    if (intValue > 100) value = '100';
      this.el.nativeElement.value = value;

    this.updatePercentageSign(span, value);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    
    if ((event.key.length === 1 && !event.ctrlKey && !event.metaKey && /\d/.test(event.key)) && ((parseInt(value + event.key, 10) > 100) || (value.length === 3 && parseInt(value, 10) < 100))) {
      event.preventDefault();
    }
  }

  private updateSpecialCharPosition(): void {
    const parent = this.renderer.parentNode(this.el.nativeElement);
    const span = parent.querySelector('.special-char');
    if (!span) return;
    
    const value = this.el.nativeElement.value;
    this.updatePercentageSign(span, value);
  }

  private updatePercentageSign(span: HTMLElement, value: string): void {
    const visibility = value === '0' || value === '' ? 'hidden' : 'visible';
    this.renderer.setStyle(span, 'visibility', visibility);

    const textWidth = this.measureTextWidth(value);
    
    const screenWidth = window.innerWidth;
    const offset = screenWidth <= 480 ? 14 : 22;

    this.renderer.setStyle(span, 'left', `${textWidth + offset}px`);
  }

  private measureTextWidth(text: string): number {
    const dummy = this.renderer.createElement('span');
    this.renderer.setStyle(dummy, 'visibility', 'hidden');
    this.renderer.setStyle(dummy, 'white-space', 'pre');
    this.renderer.setProperty(dummy, 'textContent', text);
    this.renderer.appendChild(this.renderer.parentNode(this.el.nativeElement), dummy);

    const width = dummy.offsetWidth;
    this.renderer.removeChild(this.renderer.parentNode(this.el.nativeElement), dummy);
    return width;
  }
}