<div class="chart__container">
  <figure id="chart"></figure>
  <div class="chart__line"></div>
  <afc-table class="chart__table" [tableHeaders]="[tableTitle, '']" [variant]="'chart'">
    <tr afc-table-row class="table-row" *ngFor="let beneficiary of tableData.value">
      <td>{{ beneficiary.fullName }}</td>
      <td>{{ beneficiary.allocation }}%</td>
    </tr>
  </afc-table>
</div>
