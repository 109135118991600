<afc-table
  id="tbl_funeral_cover_beneficiaries"
  [tableHeaders]="tableHeaders"
  [mobileStickyLastColumn]="true"
>
  <tr id="row_funeral_cover_beneficiary" afc-table-row class="table-row" *ngFor="let row of sort(nobService.getMembership(data?.membership, NominationTypeId.Funeral, null)?.beneficiaries)">
    <td id="cell_nomineeIdentifier">
      <div class="circle-icon-outer">
        <span>{{ row.nomineeIdentifier }}</span>
      </div>
    </td>
    <td id="cell_first_name">{{ row.firstName }}</td>
    <td id="cell_surname">{{ row.lastName }}</td>
    <td id="cell_email">{{ row.email }}</td>
    <td id="cell_phone_number">{{ row.phoneNumber }}</td>
    <td id="cell_id_number">{{ row.identityNumber }}</td>
    <td id="cell_relationship">{{ getRelationship(row.relationshipId) }}</td>
    <ng-template #stickyColumn>
      <td id="cell_edit">
        <afc-svg-button id="btn_edit" src="assets/icons/edit.svg" (clickBtn)="openModal(row.nomineeIdentifier! - 1)"></afc-svg-button>
      </td>
    </ng-template>
  </tr>
</afc-table>
