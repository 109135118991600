<label>{{ label }}</label>
<button
    type="button"
    class="toggle-button"
    [ngClass]="{'invalid': hasError}"
    (click)="this.clickBtn.emit(emitValue); togglePosition();"
    [disabled]="disabled"
    [ngStyle]="getButtonStyles()">
    <span
        *ngIf="onStateText"
        [ngStyle]="getOnStateTextStyles() || null"
        class="onStateText">
        {{onStateText}}
    </span>
    <div
      class="toggle-ball"
      [@toggleButton]="position"
      [ngStyle]="getToggleBallStyles() || null">
    </div>
    <span
        *ngIf="offStateText"
        [ngStyle]="getOffStateTextStyles() || null"
        class="offStateText"> 
        {{offStateText}}
    </span>
</button>
<span *ngIf="label && verbose" class="state-info-text">{{ position === 'on' ? 'Yes' : 'No' }}</span>