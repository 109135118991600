import { ErrorComponent } from './components/error/error.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from './components/section/section.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { InnerLayoutComponent } from './components/inner-layout/inner-layout.component';
import { CardComponent } from './components/card/card.component';
import { CardHeaderComponent } from './components/card/card-header/card-header.component';
import { ChipComponent } from './components/chip/chip.component';
import { SvgButtonModule } from './components/svg-button/svg-button.module';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { MaterialModule } from '../material/material.module';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ExpandableContainerComponent } from './components/expandable/expandable-container/expandable-container.component';
import { ExpandableWrapperComponent } from './components/expandable/afc-expandable-wrapper/afc-expandable-wrapper.component';
import { ExpandableArrowComponent } from './components/expandable-arrow/expandable-arrow.component';
import { ModalComponent } from './components/modal/modal.component';
import { AfConnectComponentsModule } from 'af-connect-components';
import { InputComponent } from './components/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AfcInlineErrorComponent } from './components/inline-error/inline-error.component';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tab/tab.component';
import { SelectComponent } from './components/select/select.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.components';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FormValidationErrorsComponent } from './components/form-validation-errors/form-validation-errors.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { NoContentComponent } from './components/no-content/no-content.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { ChartComponent } from './components/chart/chart.component';
import { FileSizePipe } from './pipes/file-size';
import { CalculatePercentage } from './pipes/calculate-percentage-pipe';
import { NumberPipe } from './pipes/number-pipe';
import { AfcDatePipe } from './pipes/date-pipe';
import { ToasterMessageComponent } from './components/toaster-message/toaster-message.component';
import { IdleStateModalComponent } from '../idle-state/idle-state-modal/idle-state-modal.component';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { ClickOutsideDirective } from './directives/close-outside-directive';
import { TableModule } from './components/table/table.module';
import { NotificationComponent } from './components/notification/notification/notification.component';
import { ResizeObserverDirective } from './directives/resize-observer.directive';
import { ExpandableCardComponent } from './components/expandable-card/expandable-card.component';
import { ExpandableCardItemComponent } from './components/expandable-card/expandable-card-item/expandable-card-item.component';
import { FcBeneficiaryModalComponent } from '../funeral-cover/components/funeral-cover/fc-beneficiary-modal/fc-beneficiary-modal.component';
import { FcBeneficiariesComponent } from '../funeral-cover/components/funeral-cover/fc-beneficiaries/fc-beneficiaries.component';
import { FcBeneficiaryFormComponent } from '../funeral-cover/components/funeral-cover/fc-beneficiary-form/fc-beneficiary-form.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { InputSpecialCharDirective } from './directives/input-special-char.directive';
import { BeneficiaryFormComponent } from './components/beneficiary-form/beneficiary-form.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DownloadModalComponent } from './components/download-modal/download-modal.component';
import { DataFieldPipe } from './pipes/data-field.pipe';
import { SliderComponent } from './components/slider/slider.component';
import { FilterByUserTypePipe } from './pipes/filter-by-user-type.pipe';
import { LoadingEllipsisComponent } from './components/loading-ellipsis/loading-ellipsis.component';
import { InfoWarningComponent } from './info-warning/info-warning.component';
import { NormalizeCasePipe } from './pipes/normalize-case.pipe';
import { StepperComponent } from './components/stepper/stepper.component';
import { ButtonComponent } from './components/button/button.component';

@NgModule({
  declarations: [
    SectionComponent,
    SectionHeaderComponent,
    InnerLayoutComponent,
    CardComponent,
    CardHeaderComponent,
    ChipComponent,
    ContentContainerComponent,
    ProgressBarComponent,
    TooltipComponent,
    LoadingSpinnerComponent,
    ExpandableContainerComponent,
    ExpandableWrapperComponent,
    ExpandableArrowComponent,
    ModalComponent,
    InputComponent,
    ErrorComponent,
    AfcInlineErrorComponent,
    FormGroupComponent,
    TabsComponent,
    TabComponent,
    SelectComponent,
    ToggleButtonComponent,
    BreadcrumbComponent,
    FormValidationErrorsComponent,
    RadioGroupComponent,
    RadioButtonComponent,
    NoContentComponent,
    DatepickerComponent,
    ChartComponent,
    FileSizePipe,
    CalculatePercentage,
    NumberPipe,
    AfcDatePipe,
    ToasterMessageComponent,
    IdleStateModalComponent,
    IntersectionObserverDirective,
    ClickOutsideDirective,
    NotificationComponent,
    ResizeObserverDirective,
    ExpandableCardComponent,
    ExpandableCardItemComponent,
    FcBeneficiaryModalComponent,
    FcBeneficiariesComponent,
    FcBeneficiaryFormComponent,
    PaginatorComponent,
    InputSpecialCharDirective,
    BeneficiaryFormComponent,
    CheckboxComponent,
    DownloadModalComponent,
    DataFieldPipe,
    SliderComponent,
    FilterByUserTypePipe,
    LoadingEllipsisComponent,
    InfoWarningComponent,
    FilterByUserTypePipe,
    NormalizeCasePipe,
    StepperComponent,
    ButtonComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AfConnectComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    SvgButtonModule,
    TableModule
  ],
  exports: [
    SectionComponent,
    SectionHeaderComponent,
    InnerLayoutComponent,
    SvgButtonModule,
    CardComponent,
    CardHeaderComponent,
    ChipComponent,
    ContentContainerComponent,
    ProgressBarComponent,
    TooltipComponent,
    LoadingSpinnerComponent,
    ExpandableContainerComponent,
    ExpandableWrapperComponent,
    ExpandableArrowComponent,
    ModalComponent,
    AfConnectComponentsModule,
    InputComponent,
    ErrorComponent,
    AfcInlineErrorComponent,
    FormGroupComponent,
    TabsComponent,
    TabComponent,
    SelectComponent,
    ToggleButtonComponent,
    BreadcrumbComponent,
    FormValidationErrorsComponent,
    RadioGroupComponent,
    RadioButtonComponent,
    NoContentComponent,
    DatepickerComponent,
    MaterialModule,
    ChartComponent,
    FileSizePipe,
    CalculatePercentage,
    NumberPipe,
    AfcDatePipe,
    ToasterMessageComponent,
    IdleStateModalComponent,
    IntersectionObserverDirective,
    ClickOutsideDirective,
    TableModule,
    NotificationComponent,
    ResizeObserverDirective,
    ExpandableCardComponent,
    ExpandableCardItemComponent,
    FcBeneficiaryModalComponent,
    FcBeneficiariesComponent,
    FcBeneficiaryFormComponent,
    PaginatorComponent,
    InputSpecialCharDirective,
    BeneficiaryFormComponent,
    CheckboxComponent,
    DownloadModalComponent,
    DataFieldPipe,
    SliderComponent,
    FilterByUserTypePipe,
    LoadingEllipsisComponent,
    InfoWarningComponent,
    FilterByUserTypePipe,
    NormalizeCasePipe,
    StepperComponent,
    ButtonComponent
  ]
})
export class SharedModule {}
