export const CountriesCollection = [
    {
        name: 'South Africa',
        countryCode: 'ZA',
        callingCode: '+27'
    },
    {
        name: 'Afghanistan',
        countryCode: 'AF',
        callingCode: '+93'
    },
    {
        name: 'Aland Islands',
        countryCode: 'AX',
        callingCode: '+358'
    },
    {
        name: 'Albania',
        countryCode: 'AL',
        callingCode: '+355'
    },
    {
        name: 'Algeria',
        countryCode: 'DZ',
        callingCode: '+213'
    },
    {
        name: 'American Samoa',
        countryCode: 'AS',
        callingCode: '+1684'
    },
    {
        name: 'Andorra',
        countryCode: 'AD',
        callingCode: '+376'
    },
    {
        name: 'Angola',
        countryCode: 'AO',
        callingCode: '+244'
    },
    {
        name: 'Anguilla',
        countryCode: 'AI',
        callingCode: '+1264'
    },
    {
        name: 'Antarctica',
        countryCode: 'AQ',
        callingCode: '+672'
    },
    {
        name: 'Antigua and Barbuda',
        countryCode: 'AG',
        callingCode: '+1268'
    },
    {
        name: 'Argentina',
        countryCode: 'AR',
        callingCode: '+54'
    },
    {
        name: 'Armenia',
        countryCode: 'AM',
        callingCode: '+374'
    },
    {
        name: 'Aruba',
        countryCode: 'AW',
        callingCode: '+297'
    },
    {
        name: 'Australia',
        countryCode: 'AU',
        callingCode: '+61'
    },
    {
        name: 'Austria',
        countryCode: 'AT',
        callingCode: '+43'
    },
    {
        name: 'Azerbaijan',
        countryCode: 'AZ',
        callingCode: '+994'
    },
    {
        name: 'Bahamas',
        countryCode: 'BS',
        callingCode: '+1242'
    },
    {
        name: 'Bahrain',
        countryCode: 'BH',
        callingCode: '+973'
    },
    {
        name: 'Bangladesh',
        countryCode: 'BD',
        callingCode: '+880'
    },
    {
        name: 'Barbados',
        countryCode: 'BB',
        callingCode: '+1246'
    },
    {
        name: 'Belarus',
        countryCode: 'BY',
        callingCode: '+375'
    },
    {
        name: 'Belgium',
        countryCode: 'BE',
        callingCode: '+32'
    },
    {
        name: 'Belize',
        countryCode: 'BZ',
        callingCode: '+501'
    },
    {
        name: 'Benin',
        countryCode: 'BJ',
        callingCode: '+229'
    },
    {
        name: 'Bermuda',
        countryCode: 'BM',
        callingCode: '+1441'
    },
    {
        name: 'Bhutan',
        countryCode: 'BT',
        callingCode: '+975'
    },
    {
        name: 'Bolivia (Plurinational State of)',
        countryCode: 'BO',
        callingCode: '+591'
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        countryCode: 'BQ',
        callingCode: '+5997'
    },
    {
        name: 'Bosnia and Herzegovina',
        countryCode: 'BA',
        callingCode: '+387'
    },
    {
        name: 'Botswana',
        countryCode: 'BW',
        callingCode: '+267'
    },
    {
        name: 'Bouvet Island',
        countryCode: 'BV',
        callingCode: '+47'
    },
    {
        name: 'Brazil',
        countryCode: 'BR',
        callingCode: '+55'
    },
    {
        name: 'British Indian Ocean Territory',
        countryCode: 'IO',
        callingCode: '+246'
    },
    {
        name: 'United States Minor Outlying Islands',
        countryCode: 'UM',
        callingCode: '+1'
    },
    {
        name: 'Virgin Islands (British)',
        countryCode: 'VG',
        callingCode: '+1284'
    },
    {
        name: 'Virgin Islands (U.S.)',
        countryCode: 'VI',
        callingCode: '+1340'
    },
    {
        name: 'Brunei Darussalam',
        countryCode: 'BN',
        callingCode: '+673'
    },
    {
        name: 'Bulgaria',
        countryCode: 'BG',
        callingCode: '+359'
    },
    {
        name: 'Burkina Faso',
        countryCode: 'BF',
        callingCode: '+226'
    },
    {
        name: 'Burundi',
        countryCode: 'BI',
        callingCode: '+257'
    },
    {
        name: 'Cambodia',
        countryCode: 'KH',
        callingCode: '+855'
    },
    {
        name: 'Cameroon',
        countryCode: 'CM',
        callingCode: '+237'
    },
    {
        name: 'Canada',
        countryCode: 'CA',
        callingCode: '+1'
    },
    {
        name: 'Cabo Verde',
        countryCode: 'CV',
        callingCode: '+238'
    },
    {
        name: 'Cayman Islands',
        countryCode: 'KY',
        callingCode: '+1345'
    },
    {
        name: 'Central African Republic',
        countryCode: 'CF',
        callingCode: '+236'
    },
    {
        name: 'Chad',
        countryCode: 'TD',
        callingCode: '+235'
    },
    {
        name: 'Chile',
        countryCode: 'CL',
        callingCode: '+56'
    },
    {
        name: 'China',
        countryCode: 'CN',
        callingCode: '+86'
    },
    {
        name: 'Christmas Island',
        countryCode: 'CX',
        callingCode: '+61'
    },
    {
        name: 'Cocos (Keeling) Islands',
        countryCode: 'CC',
        callingCode: '+61'
    },
    {
        name: 'Colombia',
        countryCode: 'CO',
        callingCode: '+57'
    },
    {
        name: 'Comoros',
        countryCode: 'KM',
        callingCode: '+269'
    },
    {
        name: 'Congo',
        countryCode: 'CG',
        callingCode: '+242'
    },
    {
        name: 'Congo (Democratic Republic of the)',
        countryCode: 'CD',
        callingCode: '+243'
    },
    {
        name: 'Cook Islands',
        countryCode: 'CK',
        callingCode: '+682'
    },
    {
        name: 'Costa Rica',
        countryCode: 'CR',
        callingCode: '+506'
    },
    {
        name: 'Croatia',
        countryCode: 'HR',
        callingCode: '+385'
    },
    {
        name: 'Cuba',
        countryCode: 'CU',
        callingCode: '+53'
    },
    {
        name: 'Curacao',
        countryCode: 'CW',
        callingCode: '+599'
    },
    {
        name: 'Cyprus',
        countryCode: 'CY',
        callingCode: '+357'
    },
    {
        name: 'Czech Republic',
        countryCode: 'CZ',
        callingCode: '+420'
    },
    {
        name: 'Denmark',
        countryCode: 'DK',
        callingCode: '+45'
    },
    {
        name: 'Djibouti',
        countryCode: 'DJ',
        callingCode: '+253'
    },
    {
        name: 'Dominica',
        countryCode: 'DM',
        callingCode: '+1767'
    },
    {
        name: 'Dominican Republic',
        countryCode: 'DO',
        callingCode: '+1809'
    },
    {
        name: 'Ecuador',
        countryCode: 'EC',
        callingCode: '+593'
    },
    {
        name: 'Egypt',
        countryCode: 'EG',
        callingCode: '+20'
    },
    {
        name: 'El Salvador',
        countryCode: 'SV',
        callingCode: '+503'
    },
    {
        name: 'Equatorial Guinea',
        countryCode: 'GQ',
        callingCode: '+240'
    },
    {
        name: 'Eritrea',
        countryCode: 'ER',
        callingCode: '+291'
    },
    {
        name: 'Estonia',
        countryCode: 'EE',
        callingCode: '+372'
    },
    {
        name: 'Ethiopia',
        countryCode: 'ET',
        callingCode: '+251'
    },
    {
        name: 'Falkland Islands (Malvinas)',
        countryCode: 'FK',
        callingCode: '+500'
    },
    {
        name: 'Faroe Islands',
        countryCode: 'FO',
        callingCode: '+298'
    },
    {
        name: 'Fiji',
        countryCode: 'FJ',
        callingCode: '+679'
    },
    {
        name: 'Finland',
        countryCode: 'FI',
        callingCode: '+358'
    },
    {
        name: 'France',
        countryCode: 'FR',
        callingCode: '+33'
    },
    {
        name: 'French Guiana',
        countryCode: 'GF',
        callingCode: '+594'
    },
    {
        name: 'French Polynesia',
        countryCode: 'PF',
        callingCode: '+689'
    },
    {
        name: 'French Southern Territories',
        countryCode: 'TF',
        callingCode: '+262'
    },
    {
        name: 'Gabon',
        countryCode: 'GA',
        callingCode: '+241'
    },
    {
        name: 'Gambia',
        countryCode: 'GM',
        callingCode: '+220'
    },
    {
        name: 'Georgia',
        countryCode: 'GE',
        callingCode: '+995'
    },
    {
        name: 'Germany',
        countryCode: 'DE',
        callingCode: '+49'
    },
    {
        name: 'Ghana',
        countryCode: 'GH',
        callingCode: '+233'
    },
    {
        name: 'Gibraltar',
        countryCode: 'GI',
        callingCode: '+350'
    },
    {
        name: 'Greece',
        countryCode: 'GR',
        callingCode: '+30'
    },
    {
        name: 'Greenland',
        countryCode: 'GL',
        callingCode: '+299'
    },
    {
        name: 'Grenada',
        countryCode: 'GD',
        callingCode: '+1473'
    },
    {
        name: 'Guadeloupe',
        countryCode: 'GP',
        callingCode: '+590'
    },
    {
        name: 'Guam',
        countryCode: 'GU',
        callingCode: '+1671'
    },
    {
        name: 'Guatemala',
        countryCode: 'GT',
        callingCode: '+502'
    },
    {
        name: 'Guernsey',
        countryCode: 'GG',
        callingCode: '+44'
    },
    {
        name: 'Guinea',
        countryCode: 'GN',
        callingCode: '+224'
    },
    {
        name: 'Guinea-Bissau',
        countryCode: 'GW',
        callingCode: '+245'
    },
    {
        name: 'Guyana',
        countryCode: 'GY',
        callingCode: '+592'
    },
    {
        name: 'Haiti',
        countryCode: 'HT',
        callingCode: '+509'
    },
    {
        name: 'Heard Island and McDonald Islands',
        countryCode: 'HM',
        callingCode: '+672'
    },
    {
        name: 'Holy See',
        countryCode: 'VA',
        callingCode: '+379'
    },
    {
        name: 'Honduras',
        countryCode: 'HN',
        callingCode: '+504'
    },
    {
        name: 'Hong Kong',
        countryCode: 'HK',
        callingCode: '+852'
    },
    {
        name: 'Hungary',
        countryCode: 'HU',
        callingCode: '+36'
    },
    {
        name: 'Iceland',
        countryCode: 'IS',
        callingCode: '+354'
    },
    {
        name: 'India',
        countryCode: 'IN',
        callingCode: '+91'
    },
    {
        name: 'Indonesia',
        countryCode: 'ID',
        callingCode: '+62'
    },
    {
        name: "Côte d'Ivoire",
        countryCode: 'CI',
        callingCode: '+225'
    },
    {
        name: 'Iran (Islamic Republic of)',
        countryCode: 'IR',
        callingCode: '+98'
    },
    {
        name: 'Iraq',
        countryCode: 'IQ',
        callingCode: '+964'
    },
    {
        name: 'Ireland',
        countryCode: 'IE',
        callingCode: '+353'
    },
    {
        name: 'Isle of Man',
        countryCode: 'IM',
        callingCode: '+44'
    },
    {
        name: 'Israel',
        countryCode: 'IL',
        callingCode: '+972'
    },
    {
        name: 'Italy',
        countryCode: 'IT',
        callingCode: '+39'
    },
    {
        name: 'Jamaica',
        countryCode: 'JM',
        callingCode: '+1876'
    },
    {
        name: 'Japan',
        countryCode: 'JP',
        callingCode: '+81'
    },
    {
        name: 'Jersey',
        countryCode: 'JE',
        callingCode: '+44'
    },
    {
        name: 'Jordan',
        countryCode: 'JO',
        callingCode: '+962'
    },
    {
        name: 'Kazakhstan',
        countryCode: 'KZ',
        callingCode: '+7'
    },
    {
        name: 'Kenya',
        countryCode: 'KE',
        callingCode: '+254'
    },
    {
        name: 'Kiribati',
        countryCode: 'KI',
        callingCode: '+686'
    },
    {
        name: 'Kuwait',
        countryCode: 'KW',
        callingCode: '+965'
    },
    {
        name: 'Kyrgyzstan',
        countryCode: 'KG',
        callingCode: '+996'
    },
    {
        name: "Lao People's Democratic Republic",
        countryCode: 'LA',
        callingCode: '+856'
    },
    {
        name: 'Latvia',
        countryCode: 'LV',
        callingCode: '+371'
    },
    {
        name: 'Lebanon',
        countryCode: 'LB',
        callingCode: '+961'
    },
    {
        name: 'Lesotho',
        countryCode: 'LS',
        callingCode: '+266'
    },
    {
        name: 'Liberia',
        countryCode: 'LR',
        callingCode: '+231'
    },
    {
        name: 'Libya',
        countryCode: 'LY',
        callingCode: '+218'
    },
    {
        name: 'Liechtenstein',
        countryCode: 'LI',
        callingCode: '+423'
    },
    {
        name: 'Lithuania',
        countryCode: 'LT',
        callingCode: '+370'
    },
    {
        name: 'Luxembourg',
        countryCode: 'LU',
        callingCode: '+352'
    },
    {
        name: 'Macao',
        countryCode: 'MO',
        callingCode: '+853'
    },
    {
        name: 'Macedonia (the former Yugoslav Republic of)',
        countryCode: 'MK',
        callingCode: '+389'
    },
    {
        name: 'Madagascar',
        countryCode: 'MG',
        callingCode: '+261'
    },
    {
        name: 'Malawi',
        countryCode: 'MW',
        callingCode: '+265'
    },
    {
        name: 'Malaysia',
        countryCode: 'MY',
        callingCode: '+60'
    },
    {
        name: 'Maldives',
        countryCode: 'MV',
        callingCode: '+960'
    },
    {
        name: 'Mali',
        countryCode: 'ML',
        callingCode: '+223'
    },
    {
        name: 'Malta',
        countryCode: 'MT',
        callingCode: '+356'
    },
    {
        name: 'Marshall Islands',
        countryCode: 'MH',
        callingCode: '+692'
    },
    {
        name: 'Martinique',
        countryCode: 'MQ',
        callingCode: '+596'
    },
    {
        name: 'Mauritania',
        countryCode: 'MR',
        callingCode: '+222'
    },
    {
        name: 'Mauritius',
        countryCode: 'MU',
        callingCode: '+230'
    },
    {
        name: 'Mayotte',
        countryCode: 'YT',
        callingCode: '+262'
    },
    {
        name: 'Mexico',
        countryCode: 'MX',
        callingCode: '+52'
    },
    {
        name: 'Micronesia (Federated States of)',
        countryCode: 'FM',
        callingCode: '+691'
    },
    {
        name: 'Moldova (Republic of)',
        countryCode: 'MD',
        callingCode: '+373'
    },
    {
        name: 'Monaco',
        countryCode: 'MC',
        callingCode: '+377'
    },
    {
        name: 'Mongolia',
        countryCode: 'MN',
        callingCode: '+976'
    },
    {
        name: 'Montenegro',
        countryCode: 'ME',
        callingCode: '+382'
    },
    {
        name: 'Montserrat',
        countryCode: 'MS',
        callingCode: '+1664'
    },
    {
        name: 'Morocco',
        countryCode: 'MA',
        callingCode: '+212'
    },
    {
        name: 'Mozambique',
        countryCode: 'MZ',
        callingCode: '+258'
    },
    {
        name: 'Myanmar',
        countryCode: 'MM',
        callingCode: '+95'
    },
    {
        name: 'Namibia',
        countryCode: 'NA',
        callingCode: '+264'
    },
    {
        name: 'Nauru',
        countryCode: 'NR',
        callingCode: '+674'
    },
    {
        name: 'Nepal',
        countryCode: 'NP',
        callingCode: '+977'
    },
    {
        name: 'Netherlands',
        countryCode: 'NL',
        callingCode: '+31'
    },
    {
        name: 'New Caledonia',
        countryCode: 'NC',
        callingCode: '+687'
    },
    {
        name: 'New Zealand',
        countryCode: 'NZ',
        callingCode: '+64'
    },
    {
        name: 'Nicaragua',
        countryCode: 'NI',
        callingCode: '+505'
    },
    {
        name: 'Niger',
        countryCode: 'NE',
        callingCode: '+227'
    },
    {
        name: 'Nigeria',
        countryCode: 'NG',
        callingCode: '+234'
    },
    {
        name: 'Niue',
        countryCode: 'NU',
        callingCode: '+683'
    },
    {
        name: 'Norfolk Island',
        countryCode: 'NF',
        callingCode: '+672'
    },
    {
        name: "Korea (Democratic People's Republic of)",
        countryCode: 'KP',
        callingCode: '+850'
    },
    {
        name: 'Northern Mariana Islands',
        countryCode: 'MP',
        callingCode: '+1670'
    },
    {
        name: 'Norway',
        countryCode: 'NO',
        callingCode: '+47'
    },
    {
        name: 'Oman',
        countryCode: 'OM',
        callingCode: '+968'
    },
    {
        name: 'Pakistan',
        countryCode: 'PK',
        callingCode: '+92'
    },
    {
        name: 'Palau',
        countryCode: 'PW',
        callingCode: '+680'
    },
    {
        name: 'Palestine, State of',
        countryCode: 'PS',
        callingCode: '+970'
    },
    {
        name: 'Panama',
        countryCode: 'PA',
        callingCode: '+507'
    },
    {
        name: 'Papua New Guinea',
        countryCode: 'PG',
        callingCode: '+675'
    },
    {
        name: 'Paraguay',
        countryCode: 'PY',
        callingCode: '+595'
    },
    {
        name: 'Peru',
        countryCode: 'PE',
        callingCode: '+51'
    },
    {
        name: 'Philippines',
        countryCode: 'PH',
        callingCode: '+63'
    },
    {
        name: 'Pitcairn',
        countryCode: 'PN',
        callingCode: '+64'
    },
    {
        name: 'Poland',
        countryCode: 'PL',
        callingCode: '+48'
    },
    {
        name: 'Portugal',
        countryCode: 'PT',
        callingCode: '+351'
    },
    {
        name: 'Puerto Rico',
        countryCode: 'PR',
        callingCode: '+1'
    },
    {
        name: 'Qatar',
        countryCode: 'QA',
        callingCode: '+974'
    },
    {
        name: 'Republic of Kosovo',
        countryCode: 'XK',
        callingCode: '+383'
    },
    {
        name: 'Réunion',
        countryCode: 'RE',
        callingCode: '+262'
    },
    {
        name: 'Romania',
        countryCode: 'RO',
        callingCode: '+40'
    },
    {
        name: 'Russian Federation',
        countryCode: 'RU',
        callingCode: '+7'
    },
    {
        name: 'Rwanda',
        countryCode: 'RW',
        callingCode: '+250'
    },
    {
        name: 'Saint Barthélemy',
        countryCode: 'BL',
        callingCode: '+590'
    },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        countryCode: 'SH',
        callingCode: '+290'
    },
    {
        name: 'Saint Kitts and Nevis',
        countryCode: 'KN',
        callingCode: '+1869'
    },
    {
        name: 'Saint Lucia',
        countryCode: 'LC',
        callingCode: '+1758'
    },
    {
        name: 'Saint Martin (French part)',
        countryCode: 'MF',
        callingCode: '+590'
    },
    {
        name: 'Saint Pierre and Miquelon',
        countryCode: 'PM',
        callingCode: '+508'
    },
    {
        name: 'Saint Vincent and the Grenadines',
        countryCode: 'VC',
        callingCode: '+1784'
    },
    {
        name: 'Samoa',
        countryCode: 'WS',
        callingCode: '+685'
    },
    {
        name: 'San Marino',
        countryCode: 'SM',
        callingCode: '+378'
    },
    {
        name: 'Sao Tome and Principe',
        countryCode: 'ST',
        callingCode: '+239'
    },
    {
        name: 'Saudi Arabia',
        countryCode: 'SA',
        callingCode: '+966'
    },
    {
        name: 'Senegal',
        countryCode: 'SN',
        callingCode: '+221'
    },
    {
        name: 'Serbia',
        countryCode: 'RS',
        callingCode: '+381'
    },
    {
        name: 'Seychelles',
        countryCode: 'SC',
        callingCode: '+248'
    },
    {
        name: 'Sierra Leone',
        countryCode: 'SL',
        callingCode: '+232'
    },
    {
        name: 'Singapore',
        countryCode: 'SG',
        callingCode: '+65'
    },
    {
        name: 'Sint Maarten (Dutch part)',
        countryCode: 'SX',
        callingCode: '+1721'
    },
    {
        name: 'Slovakia',
        countryCode: 'SK',
        callingCode: '+421'
    },
    {
        name: 'Slovenia',
        countryCode: 'SI',
        callingCode: '+386'
    },
    {
        name: 'Solomon Islands',
        countryCode: 'SB',
        callingCode: '+677'
    },
    {
        name: 'Somalia',
        countryCode: 'SO',
        callingCode: '+252'
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        countryCode: 'GS',
        callingCode: '+500'
    },
    {
        name: 'Korea (Republic of)',
        countryCode: 'KR',
        callingCode: '+82'
    },
    {
        name: 'South Sudan',
        countryCode: 'SS',
        callingCode: '+211'
    },
    {
        name: 'Spain',
        countryCode: 'ES',
        callingCode: '+34'
    },
    {
        name: 'Sri Lanka',
        countryCode: 'LK',
        callingCode: '+94'
    },
    {
        name: 'Sudan',
        countryCode: 'SD',
        callingCode: '+249'
    },
    {
        name: 'Suriname',
        countryCode: 'SR',
        callingCode: '+597'
    },
    {
        name: 'Svalbard and Jan Mayen',
        countryCode: 'SJ',
        callingCode: '+4779'
    },
    {
        name: 'Swaziland',
        countryCode: 'SZ',
        callingCode: '+268'
    },
    {
        name: 'Sweden',
        countryCode: 'SE',
        callingCode: '+46'
    },
    {
        name: 'Switzerland',
        countryCode: 'CH',
        callingCode: '+41'
    },
    {
        name: 'Syrian Arab Republic',
        countryCode: 'SY',
        callingCode: '+963'
    },
    {
        name: 'Taiwan, China',
        countryCode: 'TW',
        callingCode: '+886'
    },
    {
        name: 'Tajikistan',
        countryCode: 'TJ',
        callingCode: '+992'
    },
    {
        name: 'Tanzania, United Republic of',
        countryCode: 'TZ',
        callingCode: '+255'
    },
    {
        name: 'Thailand',
        countryCode: 'TH',
        callingCode: '+66'
    },
    {
        name: 'Timor-Leste',
        countryCode: 'TL',
        callingCode: '+670'
    },
    {
        name: 'Togo',
        countryCode: 'TG',
        callingCode: '+228'
    },
    {
        name: 'Tokelau',
        countryCode: 'TK',
        callingCode: '+690'
    },
    {
        name: 'Tonga',
        countryCode: 'TO',
        callingCode: '+676'
    },
    {
        name: 'Trinidad and Tobago',
        countryCode: 'TT',
        callingCode: '+1868'
    },
    {
        name: 'Tunisia',
        countryCode: 'TN',
        callingCode: '+216'
    },
    {
        name: 'Turkey',
        countryCode: 'TR',
        callingCode: '+90'
    },
    {
        name: 'Turkmenistan',
        countryCode: 'TM',
        callingCode: '+993'
    },
    {
        name: 'Turks and Caicos Islands',
        countryCode: 'TC',
        callingCode: '+1649'
    },
    {
        name: 'Tuvalu',
        countryCode: 'TV',
        callingCode: '+688'
    },
    {
        name: 'Uganda',
        countryCode: 'UG',
        callingCode: '+256'
    },
    {
        name: 'Ukraine',
        countryCode: 'UA',
        callingCode: '+380'
    },
    {
        name: 'United Arab Emirates',
        countryCode: 'AE',
        callingCode: '+971'
    },
    {
        name: 'United Kingdom of Great Britain and Northern Ireland',
        countryCode: 'GB',
        callingCode: '+44'
    },
    {
        name: 'United States of America',
        countryCode: 'US',
        callingCode: '+1'
    },
    {
        name: 'Uruguay',
        countryCode: 'UY',
        callingCode: '+598'
    },
    {
        name: 'Uzbekistan',
        countryCode: 'UZ',
        callingCode: '+998'
    },
    {
        name: 'Vanuatu',
        countryCode: 'VU',
        callingCode: '+678'
    },
    {
        name: 'Venezuela (Bolivarian Republic of)',
        countryCode: 'VE',
        callingCode: '+58'
    },
    {
        name: 'Viet Nam',
        countryCode: 'VN',
        callingCode: '+84'
    },
    {
        name: 'Wallis and Futuna',
        countryCode: 'WF',
        callingCode: '+681'
    },
    {
        name: 'Western Sahara',
        countryCode: 'EH',
        callingCode: '+212'
    },
    {
        name: 'Yemen',
        countryCode: 'YE',
        callingCode: '+967'
    },
    {
        name: 'Zambia',
        countryCode: 'ZM',
        callingCode: '+260'
    },
    {
        name: 'Zimbabwe',
        countryCode: 'ZW',
        callingCode: '+263'
    }
];
