<div  class="profile-info-form" [formGroupName]="currentControlName">
  <afc-form-group>
    <afc-select
      label="Title"
      formControlName="title"
      [options]="titles"
      [maxItemsShown]="titles.length"
      [variant]="isPreviewMode ? 'read-only' : 'editable'"
      [isDisabled]="isPreviewMode ? true:  false"
    ></afc-select>
    <afc-input label="Initials" formControlName="initials" [variant]="isPreviewMode ? 'read-only' : 'editable'"></afc-input>
  </afc-form-group>
  <afc-form-group>
    <afc-input label="First name" [withAsterisk]="true" formControlName="firstName" [variant]="isPreviewMode ? 'read-only' : 'editable'"></afc-input>
    <afc-input label="Middle name" formControlName="middleName" [variant]="isPreviewMode ? 'read-only' : 'editable'"></afc-input>
    <afc-input label="Surname" [withAsterisk]="true" formControlName="surname" [variant]="isPreviewMode ? 'read-only' : 'editable'"></afc-input>
  </afc-form-group>
  <afc-form-group>
    <afc-tooltip
      [text]="identityTooltipMessage"
      [showTooltip]="profileForm.enabled"
      position="bottomLeft"
      top="85px"
    >
      <afc-input label="Date of birth" formControlName="dateOfBirth" variant="read-only"></afc-input>
    </afc-tooltip>
    <afc-tooltip
      top="85px"
      position="bottomLeft"
      [text]="identityTooltipMessage"
      [showTooltip]="profileForm.enabled"
    >
      <afc-input label="ID type" formControlName="identifierType" variant="read-only"></afc-input>
    </afc-tooltip>
    <afc-tooltip
      top="85px"
      position="bottomLeft"
      [text]="identityTooltipMessage"
      [showTooltip]="profileForm.enabled"
    >
      <afc-input [label]="idNumberLabel" formControlName="identityNumber" variant="read-only"></afc-input>
    </afc-tooltip>
  </afc-form-group>

  <span class="asterisk-info">Fields containing <span class="asterisk">*</span> are required.</span>
</div>
