import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class CustomValidators {
  public static selectOptions(values: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !values.includes(value)) {
        return { selectOptions: true }
      }
      return null;
    }
  }

  public static emptyValue(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value === null || value === undefined || value === '') {
        return { emptyValue: true }
      }
      return null;
    }
  }

  public static positiveNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && value <= 0) {
        return { positiveNumber: true };
      }
      return null;
    }
  }

  public static integerNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !Number.isInteger(Number(control.value))) {
        return { integerNumber: true };
      }
      return null;
    }
  }

  public static digitValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const digitError: ValidationErrors = { NAN: true };
      if (!control.value) {
        return null;
      }
      const digitRegex = /^[0-9]*$/;
      return !control.value.match(digitRegex) ? digitError : null;
    };
  }

  public static lengthValidator(length: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const message = ' be '+length+' digits long ';
      const lengthError: ValidationErrors = { length:message };
      if (!control.value) {
        return null;
      }
      return control.value.length !== length ? lengthError : null;
    };
  }

  public static lengthValidatorCustom(length: number,InterNationalMinLength:Number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const message = ' be '+length+ ' digits long ';
      const lengthError: ValidationErrors = { length:message };
      if (!control.value) {
        return null;
      }
      let countryCode = control.parent?.get('primaryCountryCode')?.value;
      let internationaNoMessage = '';
      if (countryCode !== "+27") {
       let interLength = InterNationalMinLength
     internationaNoMessage=control.value.length > length ? 'contain no more than 10 digits' : 'contain no less than 9 digits'
       const lengthError: ValidationErrors = { length: internationaNoMessage };
        return control.value.length < interLength || control.value.length > length ? lengthError : null;
      }
      return control.value.length !== length ? lengthError : null;
    };
  }

  public static conditionalRequiredValidator(isRequired: boolean): ValidationErrors {
    if (isRequired) {
      return Validators.required;
    }
    return Validators.nullValidator;
  }

  public static alphabeticValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const alphabeticError: ValidationErrors = { alphabetic: true };
      if (!control.value) {
        return null;
      }
      const alphabeticRegex = /^[a-zA-Z ]*$/;
      return !control.value.match(alphabeticRegex) ? alphabeticError : null;
    };
  }

  public static customAlphabeticValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const alphabeticError: ValidationErrors = { alphabetic: true };
      if (!control.value) {
        return null;
      }
      const alphabeticRegex = /^[a-zA-ZÀ-ÿ '-]*$/;
      return !control.value.match(alphabeticRegex) ? alphabeticError : null;
    };
  }

  public static alphanumericValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const error: ValidationErrors = { alphanumeric: true };
      if (!control.value) {
        return null;
      }
      const regex = /^[a-zA-Z0-9]*$/;
      return !control.value.match(regex) ? error : null;
    };
  }

  public static alphanumericWithSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const error: ValidationErrors = { alphanumericWithSpaces: true };
      if (!control.value) {
        return null;
      }
      const regex = /^[a-zA-Z0-9 ]*$/;
      return !control.value.match(regex) ? error : null;
    };
  }

  public static atLeastOne = (validator: ValidatorFn): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const group = control as FormGroup;
      
      if (group && group.controls) {
        const hasAtLeastOne = Object.keys(group.controls).some(key => 
          !validator(group.controls[key])
        );
  
        return hasAtLeastOne ? null : { atLeastOne: true };
      }
      
      return null;
    };
  };

  public static ageRangeValidator(minAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const ageRangeError: ValidationErrors = { ageRange: true, minAge };

      if (control.value) {
        const birthDate = new Date(control.value);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
  
        // Check if the birthdate has occurred for the current year and month
        if (
          today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
  
        if (age < minAge) {
          return ageRangeError;
        }
      }
      return null;
    };
  }

  public static maxDecimalPlaces(places: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pattern = new RegExp(`^-?\\d*[.,]?\\d{0,${places}}$`);
      if (control.value && !control.value.match(pattern)) {
        return { maxDecimalPlacesError: true, places }
      }
      return null;
    };
  }
  
  public static minWithdrawalAmount(amount: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value < amount) {
        return { minWithdrawalAmountError: true, minAmount: amount }
      }
      return null;
    };
  }

  public static validateAtLeastOne(): ValidatorFn {
    return (): ValidationErrors | null => {
      return { atLeastOneRequired: true };
    };
  }

  static specialCharValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const error: ValidationErrors = { invalidSpecialChar: true };
      const hasSpecialChar = /[^a-zA-Z0-9%.,!?\s]/.test(control.value);

      if (!control.value) {
        return null;
      }

      return hasSpecialChar ? error : null;
    };
  }

  public static truthy(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return Boolean(control.value) ? null : { truthy: true };
    };
  }
  
  public static noWhitespaceOnly(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      return value && !value.trim() ? { whitespace: true } : null;
    };
  }
}
