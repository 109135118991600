import { Component, Input, OnInit } from '@angular/core';

enum State {
  Profile = 'profile',
  Statements = 'statements',
  InternalFunds = 'internal-funds',
  ExternalFunds = 'external-funds',
  Claims = 'claims',
  FAQ = 'faq',
  UsefulDocuments = 'useful-documents'
};

@Component({
  selector: 'afc-loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss']
})
export class LoadingSkeletonComponent {
  @Input() variant: 'profile' | 'statements' | 'internal-funds' | 'external-funds' | 'claims' | 'faq' | 'useful-documents';

  state = State;

  constructor() { }
}
