import { Injectable, OnDestroy } from '@angular/core';
import { Subject, timer, Subscription, fromEvent, BehaviorSubject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleStateService implements OnDestroy {
  private unsubscribe$$ = new Subject<void>();
  private timerSubscription: Subscription | undefined;

  private idleState$$ = new BehaviorSubject<boolean>(false);
  public idleState$ = this.idleState$$.asObservable();

  private timeoutDuration: number;
  public set timeout(value: number) {
    this.timeoutDuration = value * 60 * 1000;
    this.startTimer();
    this.attachEventListeners();
  }

  ngOnDestroy(): void {
    this.unsubscribe$$.next();
    this.unsubscribe$$.complete();
  }

  public startTimer(): void {
    this.timerSubscription = timer(this.timeoutDuration, 1000)
      .pipe(takeUntil(this.unsubscribe$$))
      .subscribe(() => this.idleState$$.next(true));
  }

  private attachEventListeners(): void {
    const userEvents = ['mousemove', 'keydown', 'wheel', 'touchstart', 'touchmove', 'touchend', 'click'];

    userEvents.forEach((event) => {
      fromEvent(document, event)
        .pipe(
            debounceTime(250),
            filter(() => !this.timerSubscription?.closed),
            takeUntil(this.unsubscribe$$)
        )
        .subscribe(() => {
          // Remove once testing has finished
          // console.log('Timer is Reset!');
          
          this.resetTimer();
        });
    });
  }

  public stopTimer(): void {
    if (this.timerSubscription)
        this.timerSubscription.unsubscribe();
  }

  private resetTimer(): void {
    this.stopTimer();
    this.startTimer();
    this.idleState$$.next(false);
  }
}
