<span 
  class="ellipsis-dot"
  [ngStyle]="getDotStyle()"
></span>
<span 
  class="ellipsis-dot"
  [ngStyle]="getDotStyle()"
  [style.margin-inline.px]="spacing"
></span>
<span 
  class="ellipsis-dot"
  [ngStyle]="getDotStyle()"
></span>
