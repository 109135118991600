import { Component, Input, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ChartBeneficiary } from './interfaces';
import * as d3 from 'd3';

enum Signs {
  Bigger = '>',
  Equal = '=',
  Smaller = '<',
  All = 'all'
}

interface ArcEntity {
  name: Signs;
  value: ChartBeneficiary[];
}

@Component({
  selector: 'afc-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {
  @Input() data: ChartBeneficiary[];

  private medianAllocation: number;
  private chartData: ArcEntity[] = [
    { name: Signs.Bigger, value: [] },
    { name: Signs.Equal, value: [] },
    { name: Signs.Smaller, value: [] }
  ];
  private svg: any;
  private legendSvg: any;
  private screenWidth = window.innerWidth;
  private width = Math.min(this.screenWidth, 300);
  private height = Math.min(this.screenWidth, 320);
  private radius = (this.width * 0.75) / 2;
  private colorsArr = ['#F69521', '#15808D', '#124E57'];

  public tableData: ArcEntity;
  public tableTitle: string = 'Beneficiaries';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setMedianAllocationAndChartData();

    this.tableData = { name: Signs.All, value: this.data } as ArcEntity;
  }

  ngAfterViewInit(): void {
    this.createSvg();
    this.drawChart();
    this.cdr.detectChanges();
  }

  private setMedianAllocationAndChartData() {
    const count = this.data.length;
    this.data.sort((a, b) => {
      if (a.allocation > b.allocation) return -1;
      else if (a.allocation < b.allocation) return 1;
      else {
        if (a.fullName < b.fullName) return -1;
        else if (a.fullName > b.fullName) return 1;
        else return 0;
      }
    });

    this.medianAllocation =
      count % 2 == 0
        ? Math.round((this.data[count / 2 - 1].allocation + this.data[count / 2].allocation) / 2)
        : this.data[(count - 1) / 2].allocation;

    this.chartData[0].value = this.data.filter((b) => b.allocation > this.medianAllocation);
    this.chartData[1].value = this.data.filter((b) => b.allocation == this.medianAllocation);
    this.chartData[2].value = this.data.filter((b) => b.allocation < this.medianAllocation);
  }

  private createSvg(): void {
    const svgCont = d3.select('figure#chart')
        .attr('viewBox', `0 0 ${this.width} ${this.height}`)
        .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('svg')
        .attr('width', this.width)
        .attr('height', this.height);

    this.svg = svgCont.append('g')
      .attr('class', 'wrapper')
      .attr('transform', 'translate(' + this.width / 2 + ',' + (this.height * 0.8) / 2 + ')');

    this.legendSvg = svgCont.append('g')
      .attr('transform', 'translate(' + this.width / 2 + ',' + this.height / 15 + ')');
  }

  private drawChart(): void {
    const pie = d3.pie<any>()
      .startAngle(Math.PI / 180)
      .endAngle(Math.PI / 180 + 2 * Math.PI)
      .value((d: any) => d.value.length)
      .sort(null);

    const colorScale = d3.scaleOrdinal()
      .domain(this.chartData.map((d: any, i: any) => i.toString()))
      .range(this.colorsArr);

    // Add a transparent rectangle to capture clicks outside the chart
    this.svg.append('rect')
        .attr('class', 'overlay')
        .attr('x', -this.width / 2)
        .attr('y', -this.height / 2)
        .attr('width', this.width)
        .attr('height', this.height)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
      .on('click', () => {
        this.updateTableData({ name: Signs.All, value: this.data });
      });

    const arc = d3.arc<any>()
      .innerRadius(this.radius * 0.6)
      .outerRadius(this.radius);

    const arcs = this.svg.selectAll('arc')
      .data(pie(this.chartData))
      .enter()
      .append('g')
        .attr('class', 'arc');

    // Draw arc paths
    arcs.append('path')
        .attr('d', arc)
        .attr('fill', (d: any, i: any) => colorScale(i.toString()))
      .on('mouseover', (event: any) => this.onMouseHover(event))
      .on('mouseout', (event: any) => this.onMouseHover(event, false))
      .on('click', (event: MouseEvent, d: any) => this.onClick(event, d));

    this.addHoverBubbles(arc, arcs);

    this.addLegend();
  }

  // Handles both mouseover and mouseout
  private onMouseHover(event: any, toShow: boolean = true): void {
    const currentArc = d3.select(event.currentTarget);

    d3.select(currentArc.node().parentNode)
      .select('.bubble')
      .style('display', toShow ? 'block' : 'none');
  }

  private onClick(event: MouseEvent, d: any): void {
    event.stopPropagation();

    this.updateTableData(d.data);
  }

  private updateTableData(data: ArcEntity): void {
    this.tableTitle = data.name == Signs.All 
      ? 'Beneficiaries' 
      : `Allocation ${data.name}${this.medianAllocation}%`;

    this.tableData = data;
    this.cdr.detectChanges();
  }

  private addHoverBubbles(arc: any, arcs: any) {
    this.defineShadowFilter();

    // Add labels with rectangles Group
    const beneficiariesCount = arcs.append('g')
        .attr('class', 'bubble')
        .style('filter', 'url(#drop-shadow)') // Apply shadow filter
        .style('display', 'none')
        .attr('transform', (d: any) => {
          const midAngle = (d.startAngle + d.endAngle) / 2;
          const degree = midAngle * (180 / Math.PI);
          const pos = arc.centroid(d);
          pos[0] *= 1.3;
          if (degree < 120 || degree > 240) {
            pos[1] *= 1.15;
          }
          return 'translate(' + pos + ')';
        })
      .on('mouseover', (event: any, d: any) => this.onMouseHover(event))
      .on('mouseout', (event: any) => this.onMouseHover(event, false));

    // Rounded rectangles
    beneficiariesCount.append('rect')
      .attr('x', -29)
      .attr('y', -16)
      .attr('width', 50)
      .attr('height', 32)
      .attr('rx', 20)
      .attr('ry', 20)
      .attr('fill', 'white');

    // Triangles
    const sym = d3.symbol().type(d3.symbolTriangle).size(200);

    beneficiariesCount.append('path')
      .attr('d', sym)
      .attr('fill', 'white')
      .attr('transform', (d: any) => {
        const pos = arc.centroid(d);
        return `translate(${pos[0] > 0 ? -26 : 18})rotate(${pos[0] > 0 ? -90 : 90})`;
      });

    // Add Text
    beneficiariesCount.append('text')
        .attr('dx', '-6px')
        .attr('dy', 4)
        .attr('font-size', '12px')
        .attr('font-family', 'Hero New SemiBold')
        .attr('class', 'count-text')
        .style('text-anchor', 'middle') // Positioning - can be changed
      .text((d: any) => d.data.value.length.toString());
  }

  private defineShadowFilter() {
    const defs = this.svg.append('defs');

    const filter = defs.append('filter')
      .attr('id', 'drop-shadow')
      .attr('x', '-30%') // Ensures the shadow isn’t clipped
      .attr('y', '-30%')
      .attr('width', '200%')
      .attr('height', '200%');

    filter.append('feOffset')
      .attr('dx', 0) // Offset in x and y direction
      .attr('dy', 1);

    filter.append('feFlood')
      .attr('flood-color', '#000000') // Shadow color
      .attr('flood-opacity', '0.29'); // Shadow color opacity

    filter.append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 4); // Blur amount

    filter.append('feComposite')
      .attr('in2', 'offsetBlur')
      .attr('operator', 'in');

    filter.append('feBlend')
      .attr('in', 'SourceGraphic')
      .attr('in2', 'blurOut')
      .attr('mode', 'normal');
  }

  private addLegend() {
    const legend = this.svg.append('g')
      .attr('transform', `translate(${-(this.width / 2 - 25)}, ${this.radius + 50})`); // Positioning at the bottom

    const legendSpacing = 50;

    legend.selectAll('circles')
      .data(this.chartData)
      .enter()
      .append('circle')
        .attr('cx', (d: any, i: any) => i * legendSpacing * 2) // Adjust spacing between legend items
        .attr('cy', 0)
        .attr('r', 12) // Radius of the legend circles
        .style('fill', (d: any, i: any) => this.colorsArr[this.colorsArr.length - 1 - i]);

    legend.selectAll('text')
      .data(this.chartData.map((c) => c.name).reverse())
      .enter()
      .append('text')
        .attr('x', (d: any, i: any) => i * legendSpacing * 2 + 17) // Position text next to circles
        .attr('y', 0)
        .attr('dy', '0.35em')
        .style('text-anchor', 'start')
        .attr('font-size', '16px')
        .attr('font-family', 'Hero New')
      .text((d: any) => d + this.medianAllocation + '%');
  }
}
