<div class="stepper-container">
  <ng-container *ngFor="let number of [].constructor(totalSteps); let i = index">
    <div class="step" [ngClass]="i < currentStep ? 'active' : ''"></div>
  </ng-container>
</div>
<div class="content">
  <div *ngIf="showStepper" class="content__count">STEP {{ currentStep }} OF {{ totalSteps }}</div>
  <div class="content__project">
    <ng-content></ng-content>
  </div>
</div>
