<afc-modal
  [width]="462"
  [isOpen]="showModal"
  [withCloseButton]="false"
  [disableOutsideClickClose]="true"
  [zIndex]="9999"
  variant="info"
  header="Your session is about to expire"
>
  <p class="expired-session-modal-text">
    <span>Your session has been inactive for over 15 minutes.</span>
    <span>
      Click “Continue” to avoid being logged out -
      <span class="timer">{{ displayTime }}s</span>
    </span>
  </p>
  <div class="button-container">
    <afc-button
      [fullWidth]="true"
      (clickBtn)="this.logoutBtnEvent.emit()"
      variant="cancel"
      title="Logout"
      size="large"
    >
    </afc-button>
    <afc-button
      [fullWidth]="true"
      (clickBtn)="this.continueBtnEvent.emit()"
      emitValue="isCloseModal"
      variant="filled"
      title="Continue"
      size="large"
    >
    </afc-button>
  </div>
</afc-modal>
