import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { EmitValue } from 'src/app/core/constants/base.constants';

@Component({
  selector: 'afc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() isActive: boolean;
  @Input() emitValue: EmitValue;
  @Input() title = '';
  @Input() disabled: boolean;
  @Input() hybrid: boolean;
  @Input() fullWidth: boolean;
  @Input() size: 'small' | 'medium' | 'large' | 'x-large' | 'block' | 'dynamic' | 'large-icon' | 'icon-only' | 'auto' =
    'small';
  @Input() variant: 'outlined' | 'filled' | 'filled-warn' | 'transparent' | 'link' | 'cancel' | 'greyish' | 'warn' =
    'outlined';
  @Input() iconSrc: string;
  @Input() iconSize = 14;
  @Input() iconPosition: 'start' | 'end' = 'start';
  @Input() activeItem: string;
  @Input() alternateDisabledColor: boolean;
  @Input() isUploadButton = false;
  @Input() loading: boolean = false;
  @Output() clickBtn = new EventEmitter<string>();
  @Output() uploadedFile = new EventEmitter<File>();
  @ViewChild('fileInput') fileInput: ElementRef;

  ngOnInit(): void {}

  @HostBinding('style.flexGrow') get applyFlexGrow() {
    return this.fullWidth ? '1' : 'auto';
  }

  public onFileUpload(event: any): void {
    const selectedFile: File = event.target.files[0];
    if (selectedFile) {
      this.uploadedFile.emit(selectedFile);
    }
  }

  public onClick(event: any) {
    this.clickBtn.emit(this.emitValue || this.title);

    if (this.isUploadButton && this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }
}
