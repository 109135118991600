<div class="container" *ngIf="control && control.errors && control.dirty && control.touched">
  <div class="container__error" *ngIf="control.errors['required']">{{ fieldText }} is required</div>
  <div class="container__error" *ngIf="control.errors['emptyValue']">Please enter a {{ fieldText }}</div>
  <div class="container__error" *ngIf="control.errors['minlength']">
    {{ fieldText }} must be at least {{ control.errors['minlength'].requiredLength }} characters long
  </div>
  <div class="container__error" *ngIf="control.errors['maxlength']">
    {{ fieldText }} must be no more than {{ control.errors['maxlength'].requiredLength }} characters
  </div>
  <div class="container__error" *ngIf="control.errors['existingProductName']">
    Please enter a unique Product Name. Example: Alexforbes Life Cover
  </div>
  <div class="container__error" *ngIf="control.errors['email']">Invalid Email address</div>
  <div class="container__error" *ngIf="control.errors['NAN']">Please enter a valid number</div>
  <div class="container__error" *ngIf="control.errors['positiveNumber']">
    Please enter a positive number. Example: R100 000
  </div>
  <div class="container__error" *ngIf="control.errors['integerNumber']">
    Please enter a number without any digits after the decimal point
  </div>
  <div class="container__error" *ngIf="control.errors['max']">
    Please enter a number less than or equal to {{ convertToNumberWithCommas(control.errors['max'].max) }}
  </div>
  <div class="container__error" *ngIf="control.errors['length']">
    {{ fieldText }} must be {{ control.errors['length'] }} digits long
  </div>
  <div class="container__error" *ngIf="control.errors['alphabetic']">
    {{ fieldText }} cannot contain numbers or special characters
  </div>
  <div class="container__error" *ngIf="control.errors['alphanumeric']">
    {{ fieldText }} cannot contain special characters or spaces
  </div>
  <div class="container__error" *ngIf="control.errors['alphanumericWithSpaces']">
    {{ fieldText }} cannot contain special characters
  </div>
  <div class="container__error" *ngIf="control.errors['selectOptions']">
    Please select a valid {{ fieldText }} from the dropdown list
  </div>
  <div class="container__error" *ngIf="control.errors['ageRange']">
    Beneficiary must be at least {{ control.errors['minAge'] }} years of age
  </div>
  <div class="container__error" *ngIf="control.errors['maxDecimalPlacesError']">
    Please enter a number with up to {{ control.errors['places'] }} decimal places
  </div>
  <div class="container__error" *ngIf="control.errors['minWithdrawalAmountError']">
    Withdrawal amount must be at least <span class="no-wrap">R{{ control.errors['minAmount'] | afcNumber }}</span>
  </div>
  <div class="container__error" *ngIf="control.errors['atLeastOneRequired']">One of the fields is required</div>
  <div class="container__error" *ngIf="control.errors['invalidSpecialChar']">
    Special characters are not permitted in this text field. Only letters, numbers, pecentage sign and standard
    punctuation are allowed.
</div>

<div class="container" *ngIf="control && control.errors && control.dirty">
  <div class="container__error no-wrap" *ngIf="control.errors['beforeCurrentDate']">
    Selection cannot be a future date.
  </div>
</div>
