<div #dateContainer [class]="['date-container', variant]" [class.disabled]="control?.disabled" (click)="toggleCalendar()">
  <ng-container *ngIf="variant === DatepickerVariants.standard" [ngTemplateOutlet]="standard"></ng-container>
  <ng-container *ngIf="variant === DatepickerVariants.input" [ngTemplateOutlet]="input"></ng-container>
  <img
    src="assets/icons/calendar.svg"
    width="16"
    height="16"
    alt="calendar"
    [ngClass]="[variant, 'calendar-icon']"
  >
</div>
<afc-form-validation-errors
  *ngIf="control && variant === DatepickerVariants.standard"
  [control]="control"
  [fieldText]="label"
>
</afc-form-validation-errors>

<div class="calendar-wrapper" [ngClass]="isCalendarOpen ? 'open' : ''">
  <div
    *ngIf="isCalendarOpen"
    class="calendar-container"
    [ngStyle]="{ width: windowWidth - 32 + 'px', left: swapOpenDirection ? offsetCalendarOpenPosition : 0 }"
  >
    <mat-calendar
      *ngIf="isCalendarOpen"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [startAt]="value"
      [(selected)]="value"
    ></mat-calendar>
  </div>
</div>

<ng-template #standard>
  <div class="label-container">
    <span class="date-container__label">{{ label }}<span *ngIf="withAsterisk" class="asterisk">*</span></span>
    <span class="date-container__date">{{ (value | afcDate) || 'dd/mm/yyyy' }}</span>
  </div>
</ng-template>

<ng-template #input>
  <div class="full-width">
    <ng-container
      *ngIf="inputFormGroup && inputFormControlName && DatepickerVariants.input"
      [formGroup]="inputFormGroup"
    >
      <afc-input
        type='datepicker'
        variant="non-editable"
        [value]="value | afcDate"
        [label]="label"
        [withAsterisk]="withAsterisk"
        [withIcon]="false"
        [formControlName]="inputFormControlName"
      >
      </afc-input>
    </ng-container>

    <afc-input
      *ngIf="!inputFormGroup && !inputFormControlName"
      type='datepicker'
      variant="non-editable"
      [value]="value | afcDate"
      [label]="label"
      [withAsterisk]="withAsterisk"
      [withIcon]="false"
      >
    </afc-input>
    <div #clickableSurface class="clickable-surface"></div>
  </div>
</ng-template>
