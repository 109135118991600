import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/guards/auth-guard.service';
import { ProfileDetailsComponent } from './profile-details/components/profile-details/profile-details.component';
import { LoggedInGuardService } from './auth/guards/logged-in-guard.service';
import appsetting from 'src/assets/appsetting.json';
import { ConditionalGuard } from './auth/guards/conditional.guard';

export const DASHBOARD = 'dashboard';
export const PROFILE_DETAILS = 'profile-details';
export const FUND_DETAILS = 'fund-details';
export const FUNERAL_COVER = 'funeral-cover';
export const UNAPPROVED_LIFE_COVER = 'unapproved-life-cover';
export const STATEMENTS_AND_DOCUMENTS = 'statements-and-documents';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoggedInGuardService],
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule)
  },
  {
    path: 'home',
    canActivate: [LoggedInGuardService],
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule)
  },
  {
    path: DASHBOARD,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'login',
    canActivate: [LoggedInGuardService],
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
  },
  {
    path: PROFILE_DETAILS,
    component: ProfileDetailsComponent,
    canActivate: [AuthGuardService, ConditionalGuard],
    canDeactivate: [AuthGuardService],
    data: { canAccess: appsetting.featureToggles.profile },
    loadChildren: () => import('./profile-details/profile-details.module').then((m) => m.ProfileDetailsModule)
  },
  {
    path: `${FUND_DETAILS}/:id`,
    canActivate: [AuthGuardService, ConditionalGuard],
    data: { canAccess: appsetting.featureToggles.fundDetails },
    loadChildren: () => import('./fund-details/fund-details.module').then((m) => m.FundDetailsModule)
  },
  {
    path: FUNERAL_COVER,
    canActivate: [AuthGuardService, ConditionalGuard],
    data: { canAccess: appsetting.featureToggles.unapprovedCovers },
    loadChildren: () => import('./funeral-cover/funeral-cover.module').then((m) => m.FuneralCoverModule)
  },
  {
    path: UNAPPROVED_LIFE_COVER,
    canActivate: [AuthGuardService, ConditionalGuard],
    data: { canAccess: appsetting.featureToggles.unapprovedCovers },
    loadChildren: () =>
      import('./unapproved-life-cover/modules/unapproved-life-cover.module').then((m) => m.UnapprovedLifeCoverModule)
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./loading-page/loading-page.module').then((m) => m.LoadingPageModule)
  },
  {
    path: STATEMENTS_AND_DOCUMENTS,
    canActivate: [AuthGuardService, ConditionalGuard],
    data: { canAccess: appsetting.featureToggles.docVault },
    loadChildren: () =>
      import('./statements-and-documents/statements-and-documents.module').then((m) => m.StatementsAndDocumentsModule)
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
