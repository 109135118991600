import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Injector,
  Input,
  ViewChild,
  forwardRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DatepickerVariants } from 'src/app/shared/enums/datepicker.enum';
import { DatepickerService } from '../../services/datepicker.service';
import { AfcDatePipe } from '../../pipes/date-pipe';

@Component({
  selector: 'afc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ],
  // Due to events being fired on every mousemove
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements ControlValueAccessor {
  @Input() label: string = 'From: ';
  @Input() isDisabled: boolean = true;
  @Input() withAsterisk: boolean = false;
  @Input() swapOpenDirection: boolean;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() isInModal: boolean = true;
  @Input() variant: DatepickerVariants = DatepickerVariants.standard;
  @Input() inputFormGroup?: FormGroup;
  @Input() inputFormControlName?: string;
  @Output() onCalendarClose = new EventEmitter<void>();

  public control?: FormControl;
  public subs = new Subscription();
  public isCalendarOpen = false;
  private _value: Date;
  public windowWidth: number;
  public paddingSizes: 32;
  public offsetCalendarOpenPosition: string;
  public DatepickerVariants = DatepickerVariants;

  @ViewChild('dateContainer') dateContainer: ElementRef;
  @ViewChild('clickableSurface') clickableSurface: ElementRef;

  public get value(): Date {
    return this._value;
  }

  @Input() public set value(value: Date) {
    if (value) {
      const timezoneOffset = value.getTimezoneOffset();
      value.setHours(0);
      value.setMinutes(0 - timezoneOffset);
    }
    
    this._value = value;
    this.propagateChange(value);
    this.isCalendarOpen = false;
  }

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private datepickerService: DatepickerService) {}

  toggleCalendar() {
    const tabletWidth = 720;
    if (this.isInModal) {
      this.windowWidth = window.innerWidth * 0.95;
    } else {
      this.windowWidth = window.innerWidth > tabletWidth ? tabletWidth : window.innerWidth;
    }
    if (this.control && !this.control.disabled) {
      this.isCalendarOpen = !this.isCalendarOpen;

      if (this.isCalendarOpen) {
        this.datepickerService.setOpenDatepicker(this);
      } else {
        this.datepickerService.closeOpenDatepicker();
      }
    }
  }

  @HostListener('document:click', ['$event']) click(event: any) {
    const target = event.target as HTMLElement;
    const calendarClick = target.classList.contains('mat-calendar-body-cell-content');
    const isInsideClick = this.elementRef.nativeElement.contains(event.target);
    
    if (this.isCalendarOpen) {
      if (isInsideClick || calendarClick) {
        this.datepickerService.setOpenDatepicker(this);
      } else {
        this.isCalendarOpen = false;
      }

      if (!this.isCalendarOpen) {
        this.datepickerService.closeOpenDatepicker();
      }
    }

    if (!this.isCalendarOpen && calendarClick) {
      this.datepickerService.closeOpenDatepicker();
    }
  }

  ngAfterViewInit(): void {
    this.windowWidth = window.innerWidth;
    const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
    if (ngControl) {
      this.control = ngControl.control as FormControl;
      this.cdr.detectChanges();
    }

    if (this.swapOpenDirection) {
      const calendarWidth = 320;
      this.offsetCalendarOpenPosition = -calendarWidth + this.dateContainer.nativeElement.offsetWidth + 'px';
    }
  }

  private propagateChange: Function = (_: string) => {};
  private propagateTouch: Function = (_: string) => {};

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onBlur(): void {
    this.propagateTouch();
  }
}
