<div
  class="dropdown-collection"
  [ngClass]="[size, (control && control.disabled) || isDisabled ? 'disabled' : 'not-disabled']"
  (click)="toggleDropdown($event)"
>
  <!-- INPUT FIELD -->
  <div class="field-container">
    <div class="label-container">
      <label *ngIf="label" [ngClass]="[variant]" class="label">
        {{ label }}<span *ngIf="withAsterisk" class="asterisk">*</span>
      </label>
      <afc-form-validation-errors
        [control]="control"
        [fieldText]="errorReferenceText || label"
      ></afc-form-validation-errors>
    </div>
    <div class="input-container">
      <input
        type="text"
        class="field-container-input"
        [placeholder]="(isReadOnly && readOnlyPlaceholder) || placeholder"
        [maxLength]="maxLength"
        [ngClass]="[
          control && variant === 'read-only' ? 'read-only' : '',
          (control && control.disabled) || isDisabled ? 'disabled' : 'not-disabled',
          size,
          dropdownOpen ? 'dropdown-open' : '',
          control && control.errors && control.dirty && control.touched ? 'hasError' : '',
          filter ? 'filterable' : 'non-filterable'
        ]"
        [disabled]="(control && control.disabled) || isDisabled || variant === 'read-only' || isReadOnly || !filter"
        [(ngModel)]="currentValue"
        (blur)="onBlur()"
        (ngModelChange)="onChange($event)"
      />
      <button class="arrow-container" *ngIf="variant !== 'read-only' && !control?.disabled">
        <afc-expandable-arrow
          endPosition="top"
          startPosition="bottom"
          animationSpeed="fast"
          ngClass="arrow"
          [isExpanded]="dropdownOpen"
        >
        </afc-expandable-arrow>
      </button>
    </div>
  </div>
  <!-- DROPDOWN ITEMS / Renders according to select type -->
  <ng-container *ngTemplateOutlet="dropdownListItems; context: { type }"></ng-container>
</div>

<ng-template #dropdownListItems let-type="type">
  <ul
    class="dropdown-list"
    role="listbox"
    *ngIf="dropdownOpen"
    [attr.aria-expanded]="dropdownOpen"
    [@selectAnimation]="dropdownOpen ? 'expanded' : 'collapsed'"
    [ngClass]="{ 'dropdown-open': dropdownOpen }"
  >
    <ng-container
      *ngIf="filteredOptions.length !== 0; else noDropdownData"
      [ngTemplateOutlet]="virtualScroll"
    ></ng-container>
  </ul>
</ng-template>

<ng-template #virtualScroll>
  <cdk-virtual-scroll-viewport
    class="select-menu-inner"
    [appendOnly]="true"
    [itemSize]="SizeEnum[size]"
    [style.height.px]="itemsShown * SizeEnum[size]"
    [minBufferPx]="itemsShown * SizeEnum[size]"
    [maxBufferPx]="itemsShown * SizeEnum[size]"
  >
    <div
      class="list-item-container"
      *cdkVirtualFor="let option of filteredOptions"
      [ngClass]="[currentValue === option ? 'selected' : '', size]"
      (click)="select(option)"
    >
      <img
        height="14"
        width="26"
        *ngIf="(type === 'country' || type === 'country-code') && option['countryCode']"
        [src]="'../../../../assets/country-flags/' + option?.['countryCode'] + '.svg' | lowercase"
      />
      <li tabindex="0" role="option" [innerHTML]="valueField && textField ? option[textField] : option"></li>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #noDropdownData>
  <div class="dropdown-no-data">No Data</div>
</ng-template>
