import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  ViewChild
} from '@angular/core';
import { RadioGroupComponent } from '../radio-group/radio-group.component';
import { RadioButtonLabelPosition, RadioButtonSize, RadioButtonVariant } from '../../models/radio.model';

// Increasing integer for generating unique ids for all afc-radio-button instances.
let nextUniqueId = 0;

@Component({
  selector: 'afc-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonComponent {
  private _uniqueId: string = `afc-radio-${++nextUniqueId}`;
  private _checked: boolean = false;
  private _disabled: boolean = false;
  private _variant: RadioButtonVariant = 'default';
  private _labelPosition: RadioButtonLabelPosition = 'after';
  private _size: RadioButtonSize = 'inline';
  @Input() outerCircleColor: 'grey' | 'default' = 'default';

  @Input() id: string = this._uniqueId;
  @Input() value: any;
  @Input() labelSize: string;

  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(newCheckedState: boolean) {
    if (this._checked !== newCheckedState) {
      this._checked = newCheckedState;
      if (newCheckedState && this.radioGroup && this.radioGroup.value !== this.value) {
        this.radioGroup.selected = this;
      }
      this.markForCheck();
    }
  }

  @Input()
  get disabled(): boolean {
    return this._disabled || (this.radioGroup !== null && this.radioGroup.disabled);
  }
  set disabled(newValue: boolean) {
    this._disabled = newValue;
  }

  @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;

  constructor(@Optional() private radioGroup: RadioGroupComponent, private changeDetector: ChangeDetectorRef) {}

  get inputId(): string {
    return `${this.id}-input`;
  }

  get variant(): RadioButtonVariant {
    return this._variant;
  }

  get labelPosition(): RadioButtonLabelPosition {
    return this._labelPosition;
  }

  get size(): RadioButtonSize {
    return this._size;
  }

  ngOnInit() {
    if (!this.radioGroup) {
      this._variant = 'checkbox';
    }
    if (this.radioGroup) {
      this.checked = this.radioGroup.value === this.value;
      this._variant = this.radioGroup.variant;
      this._labelPosition = this.radioGroup.labelPosition;
      this._size = this.radioGroup.size;
    }
  }

  markForCheck() {
    this.changeDetector.markForCheck();
  }

  onTouchTargetClick(event: Event) {
    this.onChange(event);

    // Since the click comes from the touch target, we need
    // to focus the native input element manually.
    this.inputElement.nativeElement.focus();
  }

  onBlur(): void {
    this.radioGroup.propagateTouch();
  }

  onChange(event: Event) {
    event.stopPropagation();
    if (!this.checked && !this.disabled) {
      this.checked = true;
    }
  }
}
