import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { Profile } from 'src/app/profile/models/profile.model';
import { ProfileDetails } from '../../models/profile-details.model';
import { extractFormDateFromISOString } from 'src/app/shared/utilities/date.util';
import { INPUT_MAX_LENGTH, INPUT_NAME_MAX_LENGTH, PROFILE_ID_NUMBER, PROFILE_PASSPORT } from 'src/app/core/constants/base.constants';
import { CustomValidators } from 'src/app/shared/validation/custom-validators';

@Component({
  selector: 'afc-profile-details-info',
  templateUrl: './profile-details-info.component.html',
  styleUrls: ['./profile-details-info.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ProfileDetailsInfoComponent implements OnInit {
  @Input() profile: Profile;
  @Input() editClickSubject: BehaviorSubject<boolean>;
  @Input() updateClickSubject: Subject<void>;
  @Output() onSubmit = new EventEmitter<ProfileDetails>();
  @Output() isDisabled = new EventEmitter<boolean>();
  public titles: string[] = ['Mr', 'Mrs', 'Ms', 'Dr', 'Prof'];
  public genderOptions: string[] = ['Male', 'Female', 'Trans', 'Other'];
  public identityTooltipMessage = 'Please contact your HR or Adviser to update your identity details on your profile';
  public currentControlName = 'profile';
  public profileForm: AbstractControl;
  public idNumberLabel: string;
  public isPreviewMode = false;

  private parentForm!: FormGroup;
  constructor(private formBuilder: FormBuilder, private parent: FormGroupDirective) {}

  ngOnInit() {
    // Get value for ID Number Label
    this.idNumberLabel = this.patchIDLabelValue();
    // Initialize form
    this.initForm();
    this.isPreviewMode = true;
  }

  patchInitialValues() {
    this.profileForm.patchValue({
      title: (!this.profile.title?.trim() || this.profile.title === 'None') ? null : this.profile.title,
      initials: this.profile.initials,
      firstName: this.profile.firstName,
      middleName: this.profile.middleName,
      surname: this.profile.lastName,
      dateOfBirth: extractFormDateFromISOString(this.profile.dateOfBirth),
      identifierType: this.profile.identifierType,
      identityNumber: this.profile.idNumber || this.profile.passportNumber
    });
  }

  // INPUT_MAX_LENGTH is a default/placeholder validation constant and will be replaced
  // when we have clear validation requirements.
  public initForm() {
    this.parentForm = this.parent.form;
    this.parentForm.addControl(
      this.currentControlName,
      this.formBuilder.group({
        title: [''],
        initials: ['', [
          Validators.maxLength(INPUT_MAX_LENGTH),
          CustomValidators.alphabeticValidator()
        ]],
        firstName: ['', [
          Validators.required,
          Validators.maxLength(INPUT_NAME_MAX_LENGTH),
         CustomValidators.customAlphabeticValidator()
        ]],
        middleName: ['', [
          Validators.maxLength(INPUT_NAME_MAX_LENGTH),
          CustomValidators.customAlphabeticValidator()
        ]],
        surname: ['', [
          Validators.required,
          Validators.maxLength(INPUT_NAME_MAX_LENGTH),
          CustomValidators.customAlphabeticValidator()
        ]],
        dateOfBirth: [''],
        identifierType: [''],
        identityNumber: ['']
      })
    );

    this.profileForm = this.parentForm.get(this.currentControlName)!;
    this.patchInitialValues();
    this.profileForm.disable();
  }

  // Depending on Id Type value, switch between labels for the following Number field
  public patchIDLabelValue(): string {
    switch (this.profile.identifierType) {
      case PROFILE_ID_NUMBER: return 'ID number';
      case PROFILE_PASSPORT:  return 'Passport number';
      default: return 'Number';
    }
  }
}
