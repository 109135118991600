<div class="modal-wrapper" (click)="toggleModal()">
  <ng-content select="[isOpenButton]"></ng-content>
</div>

<div [ngStyle]="{ zIndex }" *ngIf="isOpen" class="background-container" (click)="externalClickClose($event)">
  <div
    [ngStyle]="{ width: width + 'px' }"
    [ngClass]="[
      'content-container',
      variant,
      noScrollbarContentContainer ? 'noScrollbar' : '',
      borderHighlight ? 'with-border' : ''
    ]"
  >
    <div *ngIf="variant !== 'standard'" class="color-bar" [ngClass]="variant"></div>
    <div [ngStyle]="{ padding: reversedPadding ? '0px' : null }" class="inner-container">
      <div
        *ngIf="header"
        class="header-container"
        [ngStyle]="{ padding: reversedPadding ? '24px 24px 20px 24px' : null }"
      >
        <div class="header-content">
          <div class="img-container" *ngIf="variant === 'error' || variant === 'warn' || variant === 'info-light'">
            <div
              *ngIf="iconSrc"
              [ngStyle]="{ '-webkit-mask-image': 'url(' + iconSrc + ')' }"
              class="icon"
              [ngClass]="variant"
            ></div>
          </div>
          {{ header }}
        </div>
        <afc-svg-button
          *ngIf="withCloseButton"
          size="small"
          src="../../../assets/icons/delete.svg"
          emitValue="isCloseModal"
          (clickBtn)="closeFromX()"
        ></afc-svg-button>
        <ng-content select="[headerSideContent]"></ng-content>
      </div>
      <div class="body-container">
        <ng-container *ngIf="content">
          {{ content }}
        </ng-container>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
