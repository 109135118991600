// Currently not in use (may require a typecheck refactor in the future)
/*
  export const formatDateFromISOString = (date?: string): string | undefined => {
    if (date && typeof date === 'string') {
      const formattedDate = new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
      return formattedDate;
    }
    return date;
  };
*/

import moment from 'moment';

export const extractFormDateFromISOString = (date?: any): string | null => {
  return formatAfcDate(date);
};

export const dateNowToString = (): string => {
  const dateNow = new Date();
  const yyyy = dateNow.getFullYear();
  let mm = dateNow.getMonth() + 1;
  let dd = dateNow.getDate();
  return yyyy + '-' + (mm < 10 ? '0' + mm : mm) + '-' + (dd < 10 ? '0' + dd : dd);
};

export const formatAfcDate = (date: Date | string | null | undefined, format: string = 'D MMM YYYY') => {
  if (!date) {
    return '';
  }
  const formattedDate = moment(date).format(format);
  return formattedDate;
};

export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
}

export const padZero = (num: number): string =>  {
  return num < 10 ? `0${num}` : `${num}`;
}

export const convertDateToUTC = (date: Date | string | null | undefined) => {
  return moment.utc(date);
}

export const convertToUtcISOString = (localDate: Date): string => {
  const userTimezoneOffset = localDate.getTimezoneOffset();
  localDate.setHours(0);
  localDate.setMinutes(0 - userTimezoneOffset);
  return moment(localDate).toISOString();
}
