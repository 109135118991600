<div class="welcome-banner__wrapper">
  <div class="back-button-mobile" (click)="goBack()" *ngIf="router.url !== '/dashboard'">
    <afc-expandable-arrow arrowColor="#fff" startPosition="left" size="26px"></afc-expandable-arrow>
  </div>
  <div class="welcome-title">
    <!-- Initials icon/Welcome message -->
    <div class="left-side">
      <div [class]="['initials', profileService.getUserType() === USER_TYPE.Retail && 'retail-color']">
        {{ initials }}
      </div>
      <div class="name-welcome">
        <div class="hello-container">
          <span class="hello-container__hello"> Hello, </span>
          <span
            [class]="['name', profileService.getUserType() === USER_TYPE.Retail && 'retail-color']"
            *ngIf="userData"
          >
            {{ userData.given_name }} {{ userData.family_name }}
          </span>
          <div class="welcome-to-af">Welcome to Alexforbes</div>
        </div>
      </div>
    </div>
    <!-- Contact/Call me back Buttons -->
    <div class="contact">
      <afc-button
        ngClass="white-icon font-size-normal"
        iconSrc="assets/icons/contact.svg"
        title="Contact adviser"
        size="large"
        variant="transparent"
        [iconSize]="24"
        (click)="linkToContactAdviser()"
      >
      </afc-button>
      <div class="callMeBack">
        <afc-button title="Call me back" size="large" variant="filled">
          <mat-icon>call_outline</mat-icon>
        </afc-button>
      </div>
    </div>
  </div>
</div>
