<div class="inline-error-container">
  <img [class]="['svg', size]" [src]="src" />
  <div class="text-content">
    <span class="inline-error-title">{{ title || severity }}</span>
    <span class="inline-error-message">{{ message }}</span>
  </div>
  <afc-svg-button
    *ngIf="withCloseBtn"
    ngClass="close-btn"
    src="/assets/icons/delete.svg"
    emitValue="isCloseModal"
    (clickBtn)="closeErrorMessage()"
  >
  </afc-svg-button>
</div>
