import { Component } from '@angular/core';

@Component({
  selector: 'afc-info-warning',
  templateUrl: './info-warning.component.html',
  styleUrls: ['./info-warning.component.scss']
})
export class InfoWarningComponent {

}
