<ng-container *ngIf="(profileService.updateProfileError$ | async) === true; then errorPopup"> </ng-container>
<!-- <ng-container *ngIf="(profileService.updateProfileError$ | async) === false; then successPopup">
</ng-container> -->

<afc-page-layout [normalView]="true">
  <ng-template afcMobileTemplate>
    <afc-expandable-wrapper>
      <afc-section-header [title]="pageName" [isStatic]="false">
        <!-- <ng-container icon [ngTemplateOutlet]="tooltip"></ng-container> -->
        <afc-expandable-arrow></afc-expandable-arrow>
      </afc-section-header>
      <afc-expandable-container [isExpanded]="true">
        <afc-section>
          <ng-container *ngTemplateOutlet="profileTabs"></ng-container>
        </afc-section>
      </afc-expandable-container>
    </afc-expandable-wrapper>
  </ng-template>
  <ng-template afcDesktopTemplate>
    <afc-section>
      <afc-breadcrumb [currentPage]="pageName"></afc-breadcrumb>
      <afc-section-header [title]="pageName">
        <!-- <ng-container icon [ngTemplateOutlet]="tooltip"></ng-container> -->
      </afc-section-header>
      <ng-container *ngTemplateOutlet="profileTabs"></ng-container>
    </afc-section>
  </ng-template>
</afc-page-layout>
<ng-container *ngTemplateOutlet="confirmationModal"></ng-container>

<ng-template #profileTabs>
  <form [formGroup]="parentForm">
    <afc-content-container ngClass="profile-info-container">
      <ng-container *ngTemplateOutlet="toaster"></ng-container>
      <afc-tabs>
        <afc-tab label="Profile details" [tabButtons]="profileDetailsButtons">
          <ng-container *ngIf="profileService.profile$ | async as profile; else checkError">
            <afc-profile-details-info [profile]="profile"> </afc-profile-details-info>
          </ng-container>
        </afc-tab>
        <afc-tab label="Contact details" [tabButtons]="profileDetailsButtons">
          <ng-container *ngIf="profileService.profile$ | async as profile; else checkError">
            <afc-contact-details [profile]="profile"> </afc-contact-details>
          </ng-container>
        </afc-tab>
      </afc-tabs>
    </afc-content-container>
  </form>
</ng-template>

<ng-template #profileDetailsButtons>
  <afc-button
    *ngIf="!isEditable; else secondaryActions"
    (clickBtn)="onToggleEdit()"
    title="Edit"
    variant="outlined"
    [hybrid]="true"
    [disabled]="!(profileService.profile$ | async) || !!(profileService.updateProfile$ | async)"
    iconSrc="../../../../assets/icons/edit.svg"
    ngClass="align-right"
  >
  </afc-button>
  <ng-template #secondaryActions>
    <afc-button
      (clickBtn)="onUpdate()"
      title="Update"
      [disabled]="!parentForm.valid"
      size="small"
      variant="filled"
      [hybrid]="true"
    ></afc-button>
    <afc-button (clickBtn)="onCancel()" title="Cancel" variant="outlined" size="small" [hybrid]="true"></afc-button>
  </ng-template>
</ng-template>

<ng-template #checkError>
  <afc-content-container
    *ngIf="profileService.getProfileError$ | async; else loading"
    ngClass="profile-error-container"
  >
    <afc-error
      size="large"
      src="../../../../assets/icons/error.svg"
      title="Something went wrong"
      message="Something went wrong in showing your profile. Please try logging in later."
    ></afc-error>
  </afc-content-container>
</ng-template>

<ng-template #loading>
  <afc-content-container ngClass="spinner-container no-shadow">
    <afc-loading-ellipsis [size]="16" [spacing]="7"></afc-loading-ellipsis>
  </afc-content-container>
</ng-template>

<ng-template #successPopup>
  <afc-modal header="Success!" (onClose)="isPopupOpen = false" [isOpen]="true" [withCloseButton]="false" [width]="500">
    <afc-update-success></afc-update-success>
  </afc-modal>
</ng-template>

<ng-template #errorPopup>
  <afc-modal
    variant="error"
    header="Failure to update"
    (onClose)="isPopupOpen = false"
    [isOpen]="isPopupOpen"
    [width]="462"
  >
    <afc-update-failed></afc-update-failed>
  </afc-modal>
</ng-template>

<ng-template #tooltip>
  <afc-tooltip
    *ngIf="profileService.updateProfile$ | async"
    ngClass="profile-details-loading__icon"
    text="Profile information is currently being updated and will be renewed shortly"
    position="bottomCenter"
  >
    <img src="/assets/icons/info.svg" />
  </afc-tooltip>
</ng-template>

<ng-template #toaster>
  <afc-toaster-message
    @flyInOut
    ngClass="toaster-message"
    *ngIf="isOpen"
    size="large"
    src="/assets/icons/info.svg"
    message="Thank you for your updates. Your changes will be reflected when you login again."
    (onClose)="closeToast()"
  >
  </afc-toaster-message>
</ng-template>

<ng-template #confirmationModal>
  <afc-modal
    variant="info"
    header="Do you want to proceed without saving?"
    [isOpen]="openConfirmation"
    [width]="360"
    [withCloseButton]="false"
    [disableOutsideClickClose]="true"
  >
    <p>Unsaved changes will be lost.</p>
    <div class="confirmation-button-row">
      <afc-button title="Cancel" size="large" variant="cancel" [fullWidth]="true" (clickBtn)="openConfirmation = false">
      </afc-button>
      <afc-button title="Continue" size="large" variant="filled" [fullWidth]="true" (clickBtn)="onReset()">
      </afc-button>
    </div>
  </afc-modal>
</ng-template>
