import { Component, ContentChild, Input } from '@angular/core';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';
import { DesktopTemplateDirective } from '../../directives/desktop-template/desktop-template.directive';
import { MobileTemplateDirective } from '../../directives/mobile-template/mobile-template.directive';
import { DocumentsService } from 'src/app/documents/services/documents.service';

@Component({
  selector: 'afc-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent {
  @ContentChild(DesktopTemplateDirective) desktop!: DesktopTemplateDirective;
  @ContentChild(MobileTemplateDirective) mobile!: MobileTemplateDirective;

  @Input() normalView: boolean;
  @Input() strippedContent: boolean;

  constructor(
    public breakpointService: BreakpointService,
    public documentsService: DocumentsService
  ) {}
}
