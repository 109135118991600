import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'afc-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() src: string;
  @Input() title: string;
  @Input() message: string;
  @Input() height: 'small' | 'medium' | 'large' = 'small';
  @Input() alignContent: 'center' | 'left' = 'left';

  constructor() {}

  ngOnInit() {}
}
