import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2
} from '@angular/core';

@Component({
  selector: 'afc-loading-ellipsis',
  templateUrl: './loading-ellipsis.component.html',
  styleUrls: ['./loading-ellipsis.component.scss']
})
export class LoadingEllipsisComponent {
  @Input() size: number = 56;
  @Input() spacing?: number;
  @Input() color: string = 'black';
  @Input() autoSize: boolean = false;

  private autoHeightDefault = '200px';

  private sizeMappingTable = [
    { minWidth: 0, maxWidth: 400, size: 16, spacing: 4, height: this.autoHeightDefault },
    { minWidth: 401, maxWidth: 600, size: 24, spacing: 6, height: '225px' },
    { minWidth: 601, maxWidth: 800, size: 28, spacing: 8, height: '300px' },
    { minWidth: 801, maxWidth: 1200, size: 32, spacing: 10, height: '300px' },
    { minWidth: 1201, maxWidth: Infinity, size: 36, spacing: 12, height: '300px' }
  ];

  constructor(private elementRef: ElementRef, public cdr: ChangeDetectorRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.spacing === undefined) {
      this.spacing = this.size / 3;
    }
  }

  ngAfterViewInit() {
    if (this.autoSize) {
      this.updateSizeBasedOnDimensions();
    }
  }

  @HostBinding('class.autosize') get isAutosize() {
    return this.autoSize;
  }

  private updateSizeBasedOnDimensions() {
    const hostWidth = this.elementRef.nativeElement.offsetWidth;

    const matchedSize = this.sizeMappingTable.find(
      (entry) => hostWidth >= entry.minWidth && hostWidth <= entry.maxWidth
    );

    if (matchedSize) {
      this.size = matchedSize.size;
      this.spacing = matchedSize.spacing || 4;
      this.renderer.setStyle(this.elementRef.nativeElement, 'min-height', matchedSize.height || 'auto');
      this.cdr.detectChanges();
    }
  }
}
