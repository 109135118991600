import { Component, Input } from '@angular/core';

@Component({
  selector: 'afc-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
  @Input() currentStep: number;
  @Input() totalSteps: number;
  @Input() showStepper: boolean = true;
}
