<ng-container *ngIf="data$ | async as data else checkError">
  <div class="form-wrapper" [formGroup]="form">
    <div class="info-wrapper">
      <span class="info-text">Please provide the following information below</span>
      <span class="info-title">{{ title }}</span>
    </div>
    <afc-form-group class="personal-info">
      <afc-input [withAsterisk]="true" label="First name" formControlName="firstName" [noFormEnabledBorder]="true"></afc-input>
      <afc-input [withAsterisk]="true" label="Surname" formControlName="lastName" [noFormEnabledBorder]="true"></afc-input>
    </afc-form-group>
    <afc-form-group>
      <afc-select ngClass="nob-benficiary-select"
                  label="Title"
                  formControlName="titleId"
                  size="large"
                  textField="value"
                  valueField="id"
                  [withAsterisk]="true"
                  [options]="data.titles!"
                  [maxItemsShown]="data.titles!.length < 5 ? data.titles!.length : 5">
      </afc-select>
      <div class="nob-beneficiary-datepicker">
        <afc-datepicker ngClass="input"
                        label="Date of Birth"
                        formControlName="dateOfBirth"
                        inputFormControlName="dateOfBirth"
                        [inputFormGroup]="form"
                        [withAsterisk]="true"
                        [variant]="DatepickerVariants.input"
                        (onCalendarClose)="triggerDatepickerValidation(DatepickerType.DOB)">
        </afc-datepicker>
        <afc-tooltip ngClass="nob-beneficiary-datepicker__age-tooltip"
                     text="The nominated person must be 18-years or older.">
          <img class="nob-beneficiary-datepicker__age-tooltip-image" src="assets/icons/info.svg" />
        </afc-tooltip>
      </div>
      <afc-select ngClass="nob-benficiary-select"
                  label="Relationship"
                  formControlName="relationshipId"
                  size="large"
                  textField="value"
                  valueField="id"
                  [withAsterisk]="true"
                  [options]="data.relationshipTypes!"
                  [maxItemsShown]="data.relationshipTypes!.length < 5 ? data.relationshipTypes!.length : 5">
      </afc-select>
    </afc-form-group>
    <afc-form-group>
      <ng-container formGroupName="phoneNumberAndEmailGroup">
        <afc-select ngClass="countryCode"
                    label="Country code"
                    errorReferenceText="Country code"
                    type="country-code"
                    size="large"
                    formControlName="countryCode"
                    [withAsterisk]="true"
                    [maxItemsShown]="5"
                    [filter]="true"
                    [filterBy]="['countryCode', 'name', 'callingCode']">
        </afc-select>
        <afc-input
          label="Phone number"
          formControlName="mobileNumber"
          [withAsterisk]="true"
          [noFormEnabledBorder]="true"
          [maxLength]="phoneLength"
        >
        </afc-input>
        <afc-input
          label="Email address"
          formControlName="email"
          [withAsterisk]="true"
          [noFormEnabledBorder]="true"
        >
        </afc-input>
      </ng-container>
    </afc-form-group>
    <afc-form-group class="documentation-info">
      <afc-radio-group formControlName="radioGroup"
                       label="Please select the ID type:"
                       direction="column"
                       variant="checkbox"
                       (valueChange)="selectRadioBtn($event)">
        <div class="checkbox_input">
          <afc-radio-button class="checkbox_input__margin"
                            value="id"
                            [checked]="this.form.get('radioGroup')?.value === 'id'">
          </afc-radio-button>
          <label for="id" class="checkbox_input__margin checkbox_input__label">
            ID
            <span *ngIf="idNumberAsterisk" class="asterisk">*</span>
          </label>
          <afc-input class="checkbox_input__input"
                     placeholder="ID number"
                     formControlName="identityNumber"
                     errorReferenceText="ID"
                     [value]="this.form.get('radioGroup')?.value === 'id' ? this.form.get('identityNumber')?.value : ''"
                     [variant]="this.form.get('radioGroup')?.value === 'id' ? 'editable' : 'read-only'"
                     [noFormEnabledBorder]="true"
                     [maxLength]="idLength">
          </afc-input>
        </div>
        <div class="checkbox_input">
          <afc-radio-button class="checkbox_input__margin"
                            value="passport"
                            [checked]="this.form.get('radioGroup')?.value === 'passport'">
          </afc-radio-button>
          <label for="passport" class="checkbox_input__margin checkbox_input__label">
            Passport
            <span *ngIf="passportAsterisk" class="asterisk">*</span>
          </label>
          <afc-input class="checkbox_input__input"
                     placeholder="Passport number"
                     formControlName="passportNumber"
                     errorReferenceText="Passport"
                     [value]="this.form.get('radioGroup')?.value === 'passport' ? this.form.get('passportNumber')?.value : ''"
                     [variant]="this.form.get('radioGroup')?.value === 'passport' ? 'editable' : 'read-only'"
                     [noFormEnabledBorder]="true"
                     [maxLength]="passportLength">
          </afc-input>
        </div>
      </afc-radio-group>
    </afc-form-group>
    <afc-form-group class="passport-info"
                    *ngIf="this.form.get('radioGroup')?.value === 'passport'">
      <afc-datepicker ngClass="input"
                      label="Expiry date"
                      formControlName="expiryDate"
                      inputFormControlName="expiryDate"
                      [inputFormGroup]="form"
                      [variant]="DatepickerVariants.input"
                      [withAsterisk]="expiryDateAsterisk"
                      [minDate]="dateToday"
                      (onCalendarClose)="triggerDatepickerValidation(DatepickerType.ExpiryDate)">
      </afc-datepicker>
      <afc-select label="Country of issue"
                  type="country"
                  formControlName="country"
                  size="large"
                  [maxItemsShown]="5"
                  [filter]="true"
                  [filterBy]="'name'"
                  [withAsterisk]="countryAsterisk">
      </afc-select>
    </afc-form-group>
  </div>
</ng-container>

<ng-template #loading>
  <afc-content-container ngClass="spinner-container no-shadow">
    <afc-loading-spinner></afc-loading-spinner>
  </afc-content-container>
</ng-template>

<ng-template #checkError>
  <ng-container *ngIf="nobService.beneficiaryValuesetsError$ | async; else loading">
    <afc-inline-error size="large"
                      ngClass="nob-error"
                      src="assets/icons/warning.svg"
                      message="Something went wrong. Please try again later.">
    </afc-inline-error>
  </ng-container>
</ng-template>
