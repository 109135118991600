<div [class]="['wrapper', variant]">
    <div [class]="['svg-container', variant]">
        <img [class]="['svg-overlay', variant]" src="/assets/icons/tool.svg" alt="">
        <img [class]="['svg-background', variant]" src="/assets/icons/splash_background.svg" alt="">
    </div>
    
    <div class="text-container">
        <span>{{ data.categoryTitleToDisplay }}</span>
        <div [innerHTML]="data.htmlContent"></div>
    </div>
</div>
