import { Injectable } from '@angular/core';
import { ApiPaths } from '../../core/constants/api-paths.constants';
import { HttpClientService } from 'src/app/shared/services/http-client.service';
import { ApprovedCover, ApprovedCoverDto, Membership } from '../models/nob.model';
import { Subject, BehaviorSubject, shareReplay, map, switchMap, of, throwError, tap, ReplaySubject } from 'rxjs';
import {
  Action,
  BeneficiarySubDetails,
  BeneficiaryType,
  Gender,
  Lookup,
  LookupNormalizedResponse,
  LookupValue,
  RelationshipType,
  Title,
  Valuesets
} from 'src/app/funeral-cover/models/funeral-cover.model';
import { Router } from '@angular/router';

const LOOKUP_TITLE = 'title';
const LOOKUP_RELATIONSHIP = 'relationship';
const LOOKUP_BENEFICIARY_TYPE = 'beneficiarytype';
const LOOKUP_NOMINATION_TYPE = 'nominationtype';
const LOOKUP_ACTIONS = 'actions';
const LOOKUP_GENDER = 'gender';

enum BeneficiaryTypeEnum {
  Dependant = 'Dependant',
  Nominee = 'Nominee',
  Beneficiary = 'Beneficiary'
}

enum NominationType {
  Funeral = 'Funeral',
  Retirement = 'Retirement',
  GLA = 'GLA'
}

export enum NominationTypeId {
  Funeral = 1,
  Retirement = 2,
  LifeCover = 3
}

@Injectable({
  providedIn: 'root'
})
export class NobService {
  constructor(private http: HttpClientService) {}

  private nominationLoaded = false;
  private valuesetsLoaded = false;

  private isLoading$$ = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoading$$.asObservable();

  private beneficiaryDetails$$ = new BehaviorSubject<ApprovedCover | null>(null);
  public beneficiaryDetails$ = this.beneficiaryDetails$$.asObservable().pipe(shareReplay(1));

  public beneficiaryDetailsError$$ = new BehaviorSubject<boolean>(false);
  public beneficiaryDetailsError$ = this.beneficiaryDetailsError$$.asObservable();

  private beneficiaryCrud$$ = new BehaviorSubject<any | null>(null);
  public beneficiaryCrud$ = this.beneficiaryCrud$$.asObservable().pipe(shareReplay(1));

  public beneficiaryCrudError$$ = new BehaviorSubject<boolean>(false);
  public beneficiaryCrudError$ = this.beneficiaryCrudError$$.asObservable();

  public beneficiaryValuesetsError$$ = new BehaviorSubject<boolean>(false);
  public beneficiaryValuesetsError$ = this.beneficiaryValuesetsError$$.asObservable();

  private titles$$ = new ReplaySubject<Title[] | undefined>(1);
  public titles$ = this.titles$$.asObservable().pipe(shareReplay(1));

  private relationshipTypes$$ = new BehaviorSubject<RelationshipType[] | undefined | null>(null);
  public relationshipTypes$ = this.relationshipTypes$$.asObservable().pipe(shareReplay(1));

  private beneficiaryTypes$$ = new BehaviorSubject<BeneficiaryType[] | undefined | null>(null);
  public beneficiaryTypes$ = this.beneficiaryTypes$$.asObservable().pipe(shareReplay(1));

  private nominationTypeId$$ = new ReplaySubject<number | undefined>(1);
  public nominationTypeId$ = this.nominationTypeId$$.asObservable().pipe(shareReplay(1));

  private actions$$ = new Subject<Action[] | undefined>();
  public actions$ = this.actions$$.asObservable().pipe(shareReplay(1));

  private genders$$ = new BehaviorSubject<Gender[] | undefined | null>(null);
  public genders$ = this.genders$$.asObservable().pipe(shareReplay(1));

  private approvedBeneficiariesSubDetails$$ = new BehaviorSubject<BeneficiarySubDetails[] | null>(null);
  public approvedBeneficiariesSubDetails$ = this.approvedBeneficiariesSubDetails$$.asObservable().pipe(shareReplay(1));

  public internalReset$$ = new BehaviorSubject<boolean>(false);
  public internalReset$ = this.internalReset$$.asObservable().pipe(shareReplay(1));

  getNobCover(loadOnce?: boolean): void {
    if (this.nominationLoaded && loadOnce) {
      return;
    }

    this.isLoading$$.next(true);

    this.http
      .get<ApprovedCoverDto>(ApiPaths.getNomination)
      .pipe(
        tap((response) => {
          if (!response.success) {
            throw new Error('Data fetch unsuccessful');
          }
        }),
        map((response) => response.value)
      )
      .subscribe({
        next: (response) => {
          if (!loadOnce) {
            this.internalReset$$.next(true);
          }

          this.nominationLoaded = true;
          this.isLoading$$.next(false);
          this.beneficiaryDetails$$.next(response);
        },
        error: () => {
          this.nominationLoaded = true;
          this.isLoading$$.next(false);
          this.beneficiaryDetailsError$$.next(true);
        }
      });
  }

  beneficiariesCrud(body: ApprovedCover, toPromise?: boolean): any {
    if (!toPromise) {
      this.http
        .post<ApprovedCoverDto>(ApiPaths.processNomination, body)
        .pipe(
          tap((response) => {
            if (!response.success) {
              throw new Error('Data fetch unsuccessful');
            }
          })
        )
        .subscribe({
          next: (response) => {
            this.beneficiaryCrud$$.next(response);
          },
          error: () => {
            this.beneficiaryCrudError$$.next(true);
          }
        });
    }

    if (toPromise) {
      return this.http.post<ApprovedCoverDto>(ApiPaths.processNomination, body).pipe(
        tap((response) => {
          if (!response.success) {
            this.beneficiaryCrudError$$.next(true);
          }
        })
      );
    }
  }

  downloadBeneficiaryPdf(body: any, nominationType: any): any {
    if (nominationType == 'Lumpsum') {
      return this.http.post<any>(ApiPaths.unapprovedLumpSumPdfPdf, body);
    } else {
      return this.http.post<any>(ApiPaths.nominationPdf, body);
    }
  }

  getValuesets(loadOnce?: boolean): void {
    if (this.valuesetsLoaded && loadOnce) {
      return;
    }

    this.beneficiaryValuesetsError$$.next(false);

    this.http
      .get<Valuesets>(ApiPaths.getValuesetsNOB)
      .pipe(
        switchMap((data) => {
          if (data.success === false) return throwError(() => new Error());

          return of(data.value.result).pipe(map((data) => this.normalizeValuesetResponse(data)));
        })
      )
      .subscribe({
        next: (data: LookupNormalizedResponse) => {
          this.valuesetsLoaded = true;
          this.titles$$.next(data.titles);
          this.relationshipTypes$$.next(data.relationshipTypes);
          this.nominationTypeId$$.next(data.nominationTypeId);
          this.actions$$.next(data.actions);
          this.beneficiaryTypes$$.next(data.beneficiaryTypes);
          this.genders$$.next(data.genders);
        },
        error: () => {
          this.valuesetsLoaded = true;
          this.isLoading$$.next(false);
          this.beneficiaryValuesetsError$$.next(true);
        }
      });
  }

  normalizeValuesetResponse(data: Lookup[]): LookupNormalizedResponse {
    return {
      titles: data
        .find((item: Lookup) => item.lookupType === LOOKUP_TITLE)
        ?.lookupValues.map((value: LookupValue) => ({
          id: value.id,
          value: value.name
        })),
      relationshipTypes: data
        .find((item: Lookup) => item.lookupType === LOOKUP_RELATIONSHIP)
        ?.lookupValues.map((value: LookupValue) => ({
          id: value.id,
          value: value.name
        })),
      beneficiaryTypeId: data
        .find((item: Lookup) => item.lookupType === LOOKUP_BENEFICIARY_TYPE)
        ?.lookupValues.find((value: LookupValue) => value.name === BeneficiaryTypeEnum.Nominee)?.id,
      nominationTypeId: data
        .find((item: Lookup) => item.lookupType === LOOKUP_NOMINATION_TYPE)
        ?.lookupValues.find((value: LookupValue) => value.type === NominationType.Funeral)?.id,
      actions: data
        .find((item: Lookup) => item.lookupType === LOOKUP_ACTIONS)
        ?.lookupValues.map((value: LookupValue) => ({
          id: value.id,
          value: value.name
        })),
      beneficiaryTypes: data
        .find((item: Lookup) => item.lookupType === LOOKUP_BENEFICIARY_TYPE)
        ?.lookupValues.map((value: LookupValue) => ({
          id: value.id,
          value: value.name
        })),
      genders: data
        .find((item: Lookup) => item.lookupType === LOOKUP_GENDER)
        ?.lookupValues.map((value: LookupValue) => ({
          id: value.id,
          value: value.name
        }))
    };
  }

  getMembership(
    memberships: Membership[] | undefined | null,
    nominationTypeId: number,
    isApproved: boolean | null,
    fundId?: string
  ): Membership | undefined {
    return memberships?.find(
      (m) =>
        m.isApproved === isApproved &&
        m.nominationTypeId === nominationTypeId &&
        (fundId === undefined || m.productCaseKey === fundId)
    );
  }
}
