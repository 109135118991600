import { Component, Input } from '@angular/core';

@Component({
  selector: 'afc-expandable-card-item',
  templateUrl: './expandable-card-item.component.html',
  styleUrls: ['./expandable-card-item.component.scss']
})
export class ExpandableCardItemComponent {
  @Input() name: string;
  @Input() value: string;
}
