import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'afc-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
