import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

export interface ElementDimensions {
  width: number,
  height: number
}

@Directive({ 
  selector: '[observeResize]' 
})
export class ResizeObserverDirective implements OnInit, OnDestroy {
  @Output() resize = new EventEmitter<ElementDimensions>();

  private observer: ResizeObserver | undefined;

  constructor(private element: ElementRef, private zone: NgZone) {}

  ngOnInit(): void {
    this.createObserver();
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  private createObserver() {
    this.observer = new ResizeObserver(entries => {
      this.zone.run(() => {
        this.resize.emit({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height
        })
      });
    });

    this.observer.observe(this.element.nativeElement);
  }
}
