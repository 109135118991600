import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { Profile } from 'src/app/profile/models/profile.model';
import { ContactDetails } from '../../models/contact-details.model';
import {
  PHONE_INPUT_LENGTH,
  EMAIL_INPUT_MAX_LENGTH,
  CONTACT_DETAILS_INPUT_MAX_LENGTH
} from 'src/app/core/constants/base.constants';
import { CountriesCollection } from './country-collection';
import { CustomValidators } from 'src/app/shared/validation/custom-validators';

export const getContactNumberWithZero = (phoneNumber: string | null) => {
  if (phoneNumber) {
    // Get 9 digits (meant to exclude country code)
    const phoneRegex = /\d{9}$/;
    const outputPhoneNumber = phoneNumber?.match(phoneRegex)?.[0];

    // Returns the raw phone number with an appended 0 in front
    return outputPhoneNumber ? `0${outputPhoneNumber}` : '';
  }
  return '';
};

@Component({
  selector: 'afc-contact-details',
  templateUrl: './contact-details-info.component.html',
  styleUrls: ['./contact-details-info.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ContactDetailsInfoComponent implements OnInit {
  @Input() profile: Profile;
  @Input() editClickSubject: BehaviorSubject<boolean>;
  @Input() updateClickSubject: Subject<void>;
  @Output() onSubmit = new EventEmitter<ContactDetails>();

  public currentControlName = 'contact';
  public contactForm: AbstractControl;
  public phoneAsterisk = false;
  public emailAsterisk = false;
  public fillRequiredText = false;
  public isPreviewMode=false

  private parentForm!: FormGroup;

  constructor(private formBuilder: FormBuilder, private parent: FormGroupDirective) {}

  ngOnInit() {
    // Initialize form
    this.initForm();
    this.isPreviewMode=true
  }

  private getCountryCode(phoneNumber: string | null): string {
    if (phoneNumber) {
      const countryCodecRegex = phoneNumber.length > 11 ? /([0-9.]*)\d{10}/ : /([0-9.]*)\d{9}/;
      const match = phoneNumber?.match(countryCodecRegex)?.[1];
      if (match && CountriesCollection.find((item) => item.callingCode === `+${match}`)) return `+${match}`;
    }
    return '';
  }

  private getContactNumber(phoneNumber: string | null): string {
    return getContactNumberWithZero(phoneNumber);
  }
 
  public patchInitialValues() {
    this.contactForm.patchValue({
      primaryCountryCode: this.getCountryCode(this.profile.primaryContactNumber),
      primaryPhoneNumber: this.getContactNumber(this.profile.primaryContactNumber),
      recoveryCountryCode: this.getCountryCode(this.profile.recoveryContactNumber),
      recoveryPhoneNumber: this.getContactNumber(this.profile.recoveryContactNumber),
      primaryEmailAddress: this.profile.primaryEmailAddress,
      recoveryEmailAddress: this.profile.recoveryEmailAddress,
      streetName: this.profile.address.address,
      suburb: this.profile.address.suburb,
      country: this.profile.address.country,
      city: this.profile.address.city,
      postalCode: this.profile.address.postalCode
    });
   
  }

  private initForm() {
    this.parentForm = this.parent.form;
    this.parentForm.addControl(
      this.currentControlName,
      this.formBuilder.group({
        primaryCountryCode: [
          '',
          [CustomValidators.selectOptions(CountriesCollection.map((country) => country.callingCode))]
        ],
        primaryPhoneNumber: [
          '',
          [CustomValidators.digitValidator(), CustomValidators.lengthValidator(PHONE_INPUT_LENGTH)]
        ],
        // recoveryCountryCode: ['', [
        //   CustomValidators.conditionalRequiredValidator(!!this.profile.registrationType),
        //   Validators.maxLength(5)
        // ]],
        // recoveryPhoneNumber: ['', [
        //   CustomValidators.conditionalRequiredValidator(!!this.profile.registrationType),
        //   CustomValidators.digitValidator(),
        //   CustomValidators.lengthValidator(PHONE_INPUT_LENGTH)
        // ]],
        primaryEmailAddress: ['', [Validators.email, Validators.maxLength(EMAIL_INPUT_MAX_LENGTH)]],
        // recoveryEmailAddress: ['', [
        //   CustomValidators.conditionalRequiredValidator(!!this.profile.registrationType),
        //   Validators.email,
        //   Validators.maxLength(EMAIL_INPUT_MAX_LENGTH)
        // ]],
        streetName: ['', Validators.maxLength(CONTACT_DETAILS_INPUT_MAX_LENGTH)],
        suburb: ['', Validators.maxLength(CONTACT_DETAILS_INPUT_MAX_LENGTH)],
        country: ['', Validators.maxLength(CONTACT_DETAILS_INPUT_MAX_LENGTH)],
        city: ['', Validators.maxLength(CONTACT_DETAILS_INPUT_MAX_LENGTH)],
        postalCode: [null, [CustomValidators.digitValidator(), Validators.maxLength(4)]]
      })
    );

    this.contactForm = this.parentForm.get(this.currentControlName)!;
    this.patchInitialValues();
    this.addValidations();
    this.contactForm.disable();
  }

  private addValidations() {
    const phoneNumberInput = this.contactForm.get('primaryPhoneNumber');
    const emailAddressInput = this.contactForm.get('primaryEmailAddress');
    const regType = this.profile.registrationType?.toLocaleLowerCase() || null;

    switch (regType) {
      case null:
      case 'combined':
        phoneNumberInput?.addValidators(Validators.required);
        emailAddressInput?.addValidators(Validators.required);

        this.phoneAsterisk = true;
        this.emailAsterisk = true;
        break;
      case 'individual':
        phoneNumberInput?.addValidators(Validators.required);
        this.phoneAsterisk = true;
        if (this.profile.preferredContactMethod?.toLocaleLowerCase() === 'email') {
          emailAddressInput?.addValidators(Validators.required);
          this.emailAsterisk = true;
        }
        break;
      case 'corporate':
        emailAddressInput?.addValidators(Validators.required);
        this.emailAsterisk = true;
        break;
      default:
        break;
    }
  }
}
