import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserData } from '../../models/user-data.model';
import { ApiPaths } from '../../constants/api-paths.constants';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { UserType } from 'src/app/profile/models/profile.model';
import AppSettings from 'src/assets/appsetting.json';

@Component({
  selector: 'afc-welcome-banner',
  templateUrl: './welcome-banner.component.html',
  styleUrls: ['./welcome-banner.component.scss']
})
export class WelcomeBannerComponent implements OnInit {
  userData: UserData;
  initials: string;
  USER_TYPE = UserType;

  constructor(private oidcSecurityService: OidcSecurityService, private location: Location, public router: Router, public profileService: ProfileService) {}

  ngOnInit(): void {
    this.oidcSecurityService.userData$.subscribe((userData) => {
      this.userData = userData.userData;
      this.initials = this.userData.given_name.substring(0, 1) + this.userData.family_name.substring(0, 1);
    });
  }

  goBack() {
    this.location.back();
  }

  linkToContactAdviser() {
    window.open(AppSettings.urls.contactAdviser, '_blank');
  }
  
  // Force refresh token / trigger silent renew dynamically
  // refreshToken() {
  //   this.oidcSecurityService.forceRefreshSession().subscribe();
  // }
}
