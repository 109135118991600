import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[observeVisibility]',
  exportAs: 'intersectionObserver'
})
export class IntersectionObserverDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() rootMargin: string = '0px';
  @Input() threshold: number = 0;

  public isIntersecting$ = new BehaviorSubject<boolean>(false);

  private observer: IntersectionObserver | undefined;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.createObserver();
  }

  ngAfterViewInit() {
    this.observer?.observe(this.element.nativeElement);
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }

  private createObserver() {
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: this.rootMargin,
      threshold: this.threshold
    };

    this.observer = new IntersectionObserver(this.intersectionCallback.bind(this), options);
  }

  private intersectionCallback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.isIntersecting$.next(true);
        observer.unobserve(entry.target);
      };
    });
  }
}
