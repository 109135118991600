<div class="toaster-container" [ngStyle]="{'width': width}" [class]="type">
  <img [class]="['svg', size]" [src]="src" />
  <span *ngIf="title" class="toaster-title">{{ title }}</span>
  <span class="toaster-message">{{ message }}</span>
  <afc-svg-button
    ngClass="close-btn"
    [size]="closeButtonSize"
    src="/assets/icons/delete.svg"
    emitValue="isCloseModal"
    (clickBtn)="closeToast()"
  >
  </afc-svg-button>
</div>
