<div [ngClass]="[secondaryColor]" class="progress-bar-container" [style]="{height: height + 'rem'}">
  <div [ngClass]="['progress-bar', primaryColor]" [style]="{ width: progressCounter + '%', height: height + 'rem' }">
    <div class="text-container" [style]="{ minWidth: width + 'rem' }">
      <div>
        <ng-content></ng-content>
    </div>
      <span *ngIf="false"> {{ progress.toFixed(0) }}% </span>
    </div>
  </div>
</div>
