import { Component, Host, Optional, OnInit, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  EMAIL_INPUT_MAX_LENGTH,
  FAILED_DOWNLOAD_TITLE,
  FAILED_DOWNMLOAD_MESSAGE,
  IDENTITY_NUMBER_INPUT_LENGTH,
  INPUT_NAME_MAX_LENGTH,
  PASSPORT_NUMBER_INPUT_LENGTH_NOB,
  PHONE_INPUT_LENGTH,
  SUCCESSFULL_DOWNLOAD_TITLE
} from 'src/app/core/constants/base.constants';
import { map, combineLatest, filter, first, Subscription } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserData } from 'src/app/core/models/user-data.model';
import { FuneralCoverComponent } from '../funeral-cover.component';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { CustomValidators } from 'src/app/shared/validation/custom-validators';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { FuneralCoverService } from 'src/app/funeral-cover/services/funeral-cover.service';
import { RadioGroupComponent } from 'src/app/shared/components/radio-group/radio-group.component';
import { FuneralCoverSummaryComponent } from 'src/app/beneficiaries/components/fund-summary/funeral-cover/funeral-cover-summary.component';
import { NobService, NominationTypeId } from 'src/app/shared/services/nob.service';
import { RelationshipType } from 'src/app/funeral-cover/models/funeral-cover.model';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';
import { BeneficiariesService } from 'src/app/beneficiaries/services/beneficiaries.service';
import { CountriesCollection } from 'src/app/profile-details/components/contact-details-info/country-collection';
import { InternalFund } from '../../../../beneficiaries/models/funds.model';
import { Beneficiary } from '../../../../shared/models/nob.model';
import { NobModalService } from 'src/app/beneficiaries/services/nob-modal.service';
import { triggerFormValidation } from 'src/app/shared/utilities/forms.util';
import { convertDateToUTC } from 'src/app/shared/utilities/date.util';
import { ActiveDownloadSection } from 'src/app/shared/components/download-modal/download-modal.component';
import { NotificationService, NotificationType } from 'src/app/shared/services/notification.service';

const DOB_MIN_AGE = 18;

export enum ActiveModalSection {
  Beneficiary1 = 'Beneficiary1',
  Beneficiary2 = 'Beneficiary2',
  Cancel = 'Cancel',
  Confirm = 'Confirm',
  Success = 'Success',
  EditBeneficiary = 'EditBeneficiary',
  SelectBeneficiary = 'SelectBeneficiary',
  ReplaceBeneficiary = 'ReplaceBeneficiary'
}

@Component({
  selector: 'afc-fc-beneficiary-modal',
  templateUrl: './fc-beneficiary-modal.component.html',
  styleUrls: ['./fc-beneficiary-modal.component.scss']
})
export class FcBeneficiaryModalComponent implements OnInit {
  @ViewChild('beneficiaryRadio') beneficiaryRadio: RadioGroupComponent;

  @Input() isInEditMode = false;
  @Input() beneficiaryId?: number;
  @Input() isReplaceJourney = false;
  @Input() data: any;

  public userData: UserData;
  public ActiveModalSection = ActiveModalSection;
  public activeModalSection: ActiveModalSection;
  public currentControlName = 'form';
  public beneficiaryForm: FormGroup;
  public beneficiary2Form: FormGroup;
  public editForm: FormGroup;
  public replaceForm: FormGroup;
  public selectedBeneficiary: number | null;
  public idNumberAsterisk = false;
  public passportAsterisk = false;
  public expiryDateAsterisk = false;
  public countryAsterisk = false;
  public isConfirmed = false;
  public isRevert = false;
  public showError = false;
  public editFormNomineeIdentifierId: number;
  public loading = false;
  public isMobile = false;
  public isSuccess = false;
  public NominationTypeId = NominationTypeId;
  public membershipNumber: string;
  public employerName: string;
  public fund: InternalFund | undefined;
  public valuesetsError = false;
  public isChecked = false;
  public activeDownloadSection: ActiveDownloadSection;
  public ActiveDownloadSectionType = ActiveDownloadSection;
  private previousModalSelection: ActiveModalSection;
  private initialModalHeader: string;
  private initialModalWidth: number;
  private valuesetsSubscription: Subscription | null = null;
  private patchSubscription: Subscription;

  constructor(
    @Host() @Optional() private modal: ModalComponent,
    @Host() @Optional() private funaralCoverDetailsPage: FuneralCoverComponent,
    @Host() @Optional() private funeralCoverSummary: FuneralCoverSummaryComponent,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    public funeralCoverService: FuneralCoverService,
    private oidcSecurityService: OidcSecurityService,
    private profileService: ProfileService,
    public nobService: NobService,
    public breakpointService: BreakpointService,
    private beneficiariesService: BeneficiariesService,
    private nobModalService: NobModalService,
    private notificationService: NotificationService
  ) {
    this.getDataFromToken();
    nobModalService.isFcSuccessModal$.subscribe((isSuccess) => { 
      if (isSuccess) {
        this.isSuccess = isSuccess;
        this.successModal();
      }
    });
  }

  ngOnInit(): void {
    // Init forms
    this.initBeneficiary1Form();
    this.initBeneficiary2Form();
    this.initEditForm();
    this.initReplaceForm();

    this.breakpointService.isMobile$.subscribe((mobile) => (this.isMobile = mobile));

    switch (true) {
      case this.isInEditMode:
        this.previousModalSelection = ActiveModalSection.EditBeneficiary;
        break;
      case this.isReplaceJourney:
        this.previousModalSelection = ActiveModalSection.ReplaceBeneficiary;
        break;
      default:
        this.previousModalSelection = ActiveModalSection.Beneficiary1;
        break;
    }

    this.valuesetsSubscription = this.nobService.beneficiaryValuesetsError$.subscribe(
      (error) => (this.valuesetsError = error)
    );
  }

  private revertInitialModalValues(): void {
    this.modal.variant = 'standard';
    this.modal.withCloseButton = true;
    this.activeModalSection = this.previousModalSelection;
    this.modal.header = this.initialModalHeader;
    this.modal.width = this.isReplaceJourney ? 850 : this.initialModalWidth;
    this.isConfirmed = false;
    this.isRevert = true;
    // Set error initial state
    this.nobService.beneficiaryCrudError$$.next(false);
    this.cdr.markForCheck();
  }

  private configureInitialModal(): void {
    switch (true) {
      case this.isSuccess:
        this.activeModalSection = ActiveModalSection.Success;
        this.modal.width = 616;
        break;
      case this.isInEditMode:
        this.activeModalSection = ActiveModalSection.EditBeneficiary;
        break;
      case this.isReplaceJourney:
        this.activeModalSection = ActiveModalSection.SelectBeneficiary;
        this.modal.header = 'Replace beneficiary';
        this.modal.width = 600;
        break;
      default:
        this.activeModalSection = ActiveModalSection.Beneficiary1;
        break;
    }
  }

  public switchBeneficiaryForm(form: FormGroup): void {
    const phoneNumberInputValue = form.get('phoneNumberAndEmailGroup.phoneNumber')?.value;
    const emailInputValue = form.get('phoneNumberAndEmailGroup.email')?.value;

    if (
      (form.invalid || !(phoneNumberInputValue || emailInputValue)) &&
      this.activeModalSection === ActiveModalSection.Beneficiary1
    ) {
      triggerFormValidation(form);
    }

    switch (this.activeModalSection) {
      case ActiveModalSection.Beneficiary1:
        if (form.valid && !form.pristine) {
          this.activeModalSection = ActiveModalSection.Beneficiary2;
        }
        break;
      case ActiveModalSection.Beneficiary2:
        this.activeModalSection = ActiveModalSection.Beneficiary1;
        break;
      default:
        break;
    }
  }

  public submitForm(form: FormGroup): void {
    const phoneNumberInputValue = form.get('phoneNumberAndEmailGroup.mobileNumber')?.value;
    const emailInputValue = form.get('phoneNumberAndEmailGroup.email')?.value;

    if (form.invalid || !(phoneNumberInputValue || emailInputValue)) {
      triggerFormValidation(form);
    }

    if (form.valid && !form.pristine) {
      let payload;

      this.isConfirmed = true;
      this.patchSubscription?.unsubscribe();
      this.valuesetsSubscription?.unsubscribe();

      switch (true) {
        case this.isInEditMode:
          payload = this.normalizeResponse([this.editForm], this.beneficiaryId! + 1, 'Update');
          break;
        case this.isReplaceJourney:
          payload = this.normalizeFormForReplace(this.replaceForm, this.selectedBeneficiary!);
          break;
        default:
          payload = this.normalizeResponse(
            [this.beneficiaryForm, this.beneficiary2Form],
            this.beneficiaryId! + 1,
            'Insert'
          );
          break;
      }

      this.fetchDataAndShowModal(this.nobService.beneficiariesCrud(payload, true));
    }
  }

  public replaceBeneficiary(selectedBeneficiary: string | null): void {
    this.selectedBeneficiary = Number(selectedBeneficiary);
    this.activeModalSection = ActiveModalSection.Confirm;
    this.modal.variant = 'info';
    this.modal.header = `Are you sure you want to replace Beneficiary ${this.selectedBeneficiary}?`;
    this.modal.width = this.isMobile ? 360 : 450;
    this.modal.withCloseButton = false;
  }

  public radioGroupSelected(selectedId: string): void {
    this.beneficiaryRadio.value = selectedId;
  }

  public onInitiateClose() {
    if (
      this.beneficiaryForm.pristine &&
      this.beneficiary2Form.pristine &&
      this.editForm.pristine &&
      this.replaceForm.pristine
    ) {
      if (this.funaralCoverDetailsPage) {
        this.funaralCoverDetailsPage.isModalOpen = false;
        this.funaralCoverDetailsPage.inEditMode = false;
        this.funaralCoverDetailsPage.isReplaceJourney = false;
      }

      if (this.funeralCoverSummary) {
        this.nobModalService.closeFcModal();
        // this.funeralCoverSummary.isModalOpen = false;
        this.funeralCoverSummary.inEditMode = false;
        this.funeralCoverSummary.isReplaceJourney = false;
      }
    } else {
      this.activeModalSection = ActiveModalSection.Cancel;
      this.modal.variant = 'info';
      this.modal.header = `Do you want to cancel ${this.isInEditMode ? 'editing' : ''} this information?`;
      this.modal.width = this.isMobile ? 360 : 450;
      this.modal.withCloseButton = false;

      this.modal.isOpen = true;

      if (this.funaralCoverDetailsPage) this.funaralCoverDetailsPage.isModalOpen = true;

      if (this.funeralCoverSummary) this.nobModalService.openFcModal();
      // this.funeralCoverSummary.isModalOpen = true;
    }
  }

  public onClose(): void {
    if (this.modal) {
      this.revertInitialModalValues();

      this.beneficiaryForm.reset();
      this.beneficiary2Form.reset();
      this.editForm.reset();
      this.replaceForm.reset();

      this.beneficiaryForm.markAsPristine();
      this.beneficiary2Form.markAsPristine();
      this.editForm.markAsPristine();
      this.replaceForm.markAsPristine();

      this.modal.isOpen = false;
      if (this.funaralCoverDetailsPage) {
        this.funaralCoverDetailsPage.isModalOpen = false;
        this.funaralCoverDetailsPage.inEditMode = false;
        this.funaralCoverDetailsPage.isReplaceJourney = false;
      }

      if (this.funeralCoverSummary) {
        // this.funeralCoverSummary.isModalOpen = false;
        this.funeralCoverSummary.inEditMode = false;
        this.funeralCoverSummary.isReplaceJourney = false;
      }

      this.nobModalService.closeFcModal();
      this.nobModalService.closeFcSuccessModal();
    }
  }

  private successModal(): void {
    this.activeModalSection = ActiveModalSection.Success;
    this.modal.variant = 'standard';
    this.modal.header = 'Success!';
    this.modal.width = 616;
    this.modal.withCloseButton = false;
  }

  public onConfirm(): void {
    this.activeModalSection = ActiveModalSection.ReplaceBeneficiary;
    this.modal.variant = 'standard';
    this.modal.header = 'Replace Beneficiary';
    this.modal.withCloseButton = true;
    this.modal.width = 850;
  }

  public async fetchDataAndShowModal(observable: any) {
    let crudError = false;
    let fetchError = false;

    // TODO: clean up
    this.nobService.beneficiaryDetailsError$.subscribe((error) => (fetchError = error));
    switch (true) {
      case this.isInEditMode:
        this.nobService.beneficiaryCrudError$.subscribe((error) => (crudError = error));
        break;
      case this.isReplaceJourney:
        this.nobService.beneficiaryCrudError$.subscribe((error) => (crudError = error));
        break;
      default:
        this.nobService.beneficiaryCrudError$.subscribe((error) => (crudError = error));
        break;
    }

    this.beneficiaryForm.disable();
    this.beneficiary2Form.disable();
    this.editForm.disable();
    this.replaceForm.disable();

    this.loading = true;

    await observable.toPromise();

    if (!crudError) {
      await this.nobService.getNobCover();

      await this.nobService.beneficiaryDetails$
        .pipe(
          filter((data) => data !== null),
          first()
        )
        .toPromise()
        .then(() => {
          if (!fetchError) {
            this.beneficiaryForm.markAsPristine();
            this.beneficiary2Form.markAsPristine();
            this.editForm.markAsPristine();
            this.replaceForm.markAsPristine();
            this.nobModalService.openFcSuccessModal();
          } else {
            this.beneficiaryForm.enable();
            this.beneficiary2Form.enable();
            this.editForm.enable();
            this.replaceForm.enable();

            this.showError = true;
            this.loading = false;
          }
        });
    } else {
      this.beneficiaryForm.enable();
      this.beneficiary2Form.enable();
      this.editForm.enable();
      this.replaceForm.enable();

      this.showError = true;
      this.loading = false;
    }
  }

  public onCancel(): void {
    this.revertInitialModalValues();
  }

  public onCancelConfirm(): void {
    this.modal.variant = 'standard';
    this.modal.withCloseButton = true;
    this.activeModalSection = ActiveModalSection.SelectBeneficiary;
    this.modal.header = 'Replace beneficiary';
    this.modal.width = 600;
  }

  hideErrorMessage(): void {
    this.showError = false;
  }

  public downloadPdf(): void {
    this.activeDownloadSection = ActiveDownloadSection.Loading;

    let profileDetails: any;
    let beneficiariesDetails: any;
    let relationshipTypes: RelationshipType[] | null | undefined;

    this.profileService.profile$.subscribe((data) => (profileDetails = data));
    this.nobService.relationshipTypes$.subscribe((types) => (relationshipTypes = types));

    this.nobService.beneficiaryDetails$.subscribe((data) => {
      beneficiariesDetails = this.nobService
        .getMembership(data?.membership, NominationTypeId.Funeral, null)
        ?.beneficiaries.map((item: Beneficiary) => ({
          name: `${item.firstName}  ${item.lastName}`,
          idPassport: item.identityNumber || item.passportNumber,
          expiryDate: item.expiryDate == null || item.expiryDate == undefined ? '' : item.expiryDate.substring(0, 10),
          countryIssue: item.country,
          relationship: relationshipTypes?.find((r: RelationshipType) => r.id === item.relationshipId)?.value || '',
          mobileNumber: item.phoneNumber == null || item.phoneNumber == undefined ? '' : item.phoneNumber,
          email: item.email,
          lastModifiedDate:item.dateModified
        }));
    });

    this.beneficiariesService.internalFundSummary$.subscribe((funds) => {
      if (funds != null || funds != undefined) {
        var nonRestrictedFund = funds?.find((fund) => fund.isRestricted === false);
        if (nonRestrictedFund != null || nonRestrictedFund != undefined) {
          this.fund = nonRestrictedFund;
          this.membershipNumber = this.fund.refNum2 != null || this.fund.refNum2 != undefined ? this.fund.refNum2 : '';
          this.employerName =
            this.fund.employerName != null || this.fund.employerName != undefined ? this.fund.employerName : '';
        }
      }
    });

    const payload = {
      employeeDetails: {
        nameOfEmployer: '',
        employeeNumber: '',
        names: `${profileDetails?.firstName} ${profileDetails?.lastName}`,
        idPassport: profileDetails?.idNumber || profileDetails?.passportNumber,
        expiryDate: '',
        countryIssue: profileDetails?.address.country,
        mobileNumber: profileDetails?.primaryContactNumber,
        homeNumber: '',
        workNumber: '',
        email: profileDetails?.primaryEmailAddress
      },
      beneficiaries: beneficiariesDetails,
      declarationDate: new Date().toISOString()
    };

    const lastModifiedDates = beneficiariesDetails
    ?.map((b: { lastModifiedDate: string }) => new Date(b.lastModifiedDate))
    .sort((a: Date, b: Date) => b.getTime() - a.getTime()) || [];

    const newPayload = {
      fullNameAndSurname: `${profileDetails?.firstName} ${profileDetails?.lastName}`,
      dateOfBirth: `${profileDetails?.dateOfBirth.substring(0, 10)}`,
      employerName: this.employerName,
      membershipNumber: this.membershipNumber,
      idPassport: profileDetails?.idNumber || profileDetails?.passportNumber,
      lastModifiedDate: lastModifiedDates.length > 0 ? lastModifiedDates[0].toISOString().split('T')[0] : '',
      beneficiaries: beneficiariesDetails
    };

    this.funeralCoverService.downloadBeneficiaryPdf(newPayload).subscribe({
      next: (data: any) => {
        this.download(data?.value.pdfData);
        this.activeDownloadSection = ActiveDownloadSection.Success;
        this.notificationService.showNotification({
          notificationType: NotificationType.Success,
          title: SUCCESSFULL_DOWNLOAD_TITLE
        });
        this.onClose();
      },
      error: (error: any) => {
        this.activeDownloadSection = ActiveDownloadSection.Error;
        this.notificationService.showNotification({
          notificationType: NotificationType.Error,
          title: FAILED_DOWNLOAD_TITLE,
          message: FAILED_DOWNMLOAD_MESSAGE
        });
        this.onClose();
      }
    });
  }

  private download(fileStream: string): void {
    const source = `data:application/pdf;base64,${fileStream}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = 'BeneficiaryReport.pdf';
    link.click();
  }

  private setInitialModalValues(): void {
    this.initialModalHeader = this.modal.header;
    this.initialModalWidth = this.modal.width;
  }

  private normalizeResponse(forms: FormGroup[], beneficiaryId?: number, action?: string): any {
    let profileData: any;
    let personDetails: any;
    let membershipDetails: any;

    this.profileService.profile$.subscribe((data) => (profileData = data));
    this.nobService.beneficiaryDetails$.subscribe((data) => {
      personDetails = data?.person;
      membershipDetails = this.nobService.getMembership(data?.membership, NominationTypeId.Funeral, null);
    });

    const beneficiaries = forms.map((form, i) => {
      return {
        action: action,
        nominationId: action === 'Update' ? membershipDetails.beneficiaries[beneficiaryId! - 1].nominationId : 0,
        beneficiaryTypeId: form.value.beneficiaryTypeId,
        genderId: form.value.genderId,
        titleId: form.value.titleId,
        relationshipId: form.value.relationshipId,
        identityNumber: form.value.identityNumber,
        firstName: form.value.firstName,
        middleName: form.value.middleName,
        lastName: form.value.lastName,
        initials: form.value.firstName.substring(0, 1) + form.value.lastName.substring(0, 1),
        dateOfBirth: convertDateToUTC(form.value.dateOfBirth).toISOString(),
        dateCreated: new Date().toISOString(),
        dateModified: new Date().toISOString(),
        lastModifiedBy: profileData?.samAccount,
        passportNumber: form.value.passportNumber || '',
        expiryDate: form.value.expiryDate ? convertDateToUTC(form.value.expiryDate).toISOString() : null,
        email: form.value.phoneNumberAndEmailGroup.email,
        phoneNumber:
          form.value.phoneNumberAndEmailGroup.countryCode.substring(1) +
          (form.value.phoneNumberAndEmailGroup.countryCode == '+27'
            ? form.value.phoneNumberAndEmailGroup.mobileNumber.substring(1)
            : form.value.phoneNumberAndEmailGroup.mobileNumber),
        country: form.value.country,
        allocation:
          action === 'Update' ? membershipDetails.beneficiaries[beneficiaryId! - 1].allocation : i === 0 ? 100 : 0,
        nomineeIdentifier:
          action === 'Update' ? membershipDetails.beneficiaries[beneficiaryId! - 1].nomineeIdentifier : i + 1
      };
    });

    const payload = {
      person: {
        id: personDetails?.id || 0,
        gender: personDetails?.gender || profileData.gender || 'Unknown',
        afGroupId: this.userData.afgroupid,
        title: personDetails?.title || profileData.title || 'Unknown',
        firstName: personDetails?.firstName || profileData.firstName,
        lastName: personDetails?.lastName || profileData.lastName,
        idNumber: personDetails?.idNumber || profileData.idNumber,
        passportNumber: personDetails?.passportNumber || profileData.passportNumber,
        dateOfBirth: personDetails?.dateOfBirth || profileData.dateOfBirth,
        userpin: personDetails?.userpin || profileData.samAccount,
        email: personDetails?.email || profileData.primaryEmailAddress,
        passportExpiryDate: personDetails?.passportExpiryDate || null,
        passportCountryOfIssue: personDetails?.passportCountryOfIssue || profileData.address.country,
        mobileNumber: personDetails?.mobileNumber || profileData.primaryContactNumber,
        workNumber: personDetails?.workNumber || '',
        homeNumber: personDetails?.homeNumber || '',
        employer: personDetails?.employer || '',
        employeeNumber: personDetails?.employeeNumber || ''
      },
      membership: [
        {
          id: 0,
          productGuid: membershipDetails?.productGuid || null,
          productName: membershipDetails?.productName || null,
          productCaseKey: membershipDetails?.productCaseKey || null,
          sourceSystemName: membershipDetails?.sourceSystemName || null,
          caseMbrKey: membershipDetails?.caseMbrKey || null,
          membershipNumber: membershipDetails?.membershipNumber || null,
          isApproved: false,
          nominationTypeId: 1,
          beneficiaries: beneficiaries
        }
      ]
    };

    return payload;
  }

  private normalizeFormForReplace(form: FormGroup, beneficiaryId: number): any {
    let existingRecord: any;
    let titles: any;
    let relationshipTypes: any;

    let profileData: any;
    let personDetails: any;
    let membershipDetails: any;

    this.profileService.profile$.subscribe((data) => (profileData = data));

    this.nobService.beneficiaryDetails$.subscribe((data) => {
      personDetails = data?.person;
      membershipDetails = this.nobService.getMembership(data?.membership, NominationTypeId.Funeral, null);
      existingRecord = this.nobService.getMembership(data?.membership, NominationTypeId.Funeral, null)
        ?.beneficiaries[beneficiaryId - 1];
    });

    this.nobService.titles$.subscribe((data) => (titles = data));
    this.nobService.relationshipTypes$.subscribe((data) => (relationshipTypes = data));

    const phoneNumber =
      form.value.phoneNumberAndEmailGroup.countryCode.substring(1) +
      (form.value.phoneNumberAndEmailGroup.countryCode == '+27'
        ? form.value.phoneNumberAndEmailGroup.mobileNumber.substring(1)
        : form.value.phoneNumberAndEmailGroup.mobileNumber);

    const beneficiaries = [
      {
        action: 'Delete',
        nominationId: existingRecord.nominationId,
        beneficiaryTypeId: form.value.beneficiaryTypeId,
        genderId: form.value.genderId,
        titleId: existingRecord.titleId,
        relationshipId: existingRecord.relationshipId,
        firstName: existingRecord.firstName,
        middleName: existingRecord.middleName,
        lastName: existingRecord.lastName,
        dateOfBirth: existingRecord.dateOfBirth,
        identityNumber: existingRecord.identityNumber,
        initials: existingRecord.initials,
        allocation: existingRecord.allocation,
        country: existingRecord.country,
        dateCreated: new Date().toISOString(),
        dateModified: new Date().toISOString(),
        lastModifiedBy: profileData?.samAccount,
        passportNumber: existingRecord.passportNumber,
        expiryDate: existingRecord.expiryDate || null,
        email: existingRecord.email || '',
        phoneNumber: existingRecord.phoneNumber || '',
        nomineeIdentifier: existingRecord.nomineeIdentifier
      },
      {
        action: 'Insert',
        beneficiaryTypeId: form.value.beneficiaryTypeId,
        genderId: form.value.genderId,
        titleId: form.value.titleId,
        relationshipId: form.value.relationshipId,
        firstName: form.value.firstName,
        middleName: '',
        lastName: form.value.lastName,
        dateOfBirth: convertDateToUTC(form.value.dateOfBirth).toISOString(),
        identityNumber: form.value.identityNumber,
        initials: form.value.firstName.substring(0, 1) + form.value.lastName.substring(0, 1),
        allocation: existingRecord.allocation,
        country: form.value.country,
        dateCreated: form.value.dateCreated,
        dateModified: form.value.dateModified,
        lastModifiedBy: profileData?.samAccount,
        passportNumber: form.value.passportNumber,
        expiryDate: form.value.expiryDate ? convertDateToUTC(form.value.expiryDate).toISOString() : null,
        email: form.value.phoneNumberAndEmailGroup.email,
        phoneNumber: phoneNumber,
        nomineeIdentifier: existingRecord.nomineeIdentifier
      }
    ];

    const payload = {
      person: {
        id: personDetails.id || 0,
        gender: personDetails.gender || profileData.gender || 'Unknown',
        afGroupId: this.userData.afgroupid,
        title: personDetails.title || profileData.title || 'Unknown',
        firstName: personDetails.firstName || profileData.firstName,
        lastName: personDetails.lastName || profileData.lastName,
        idNumber: personDetails.idNumber || profileData.idNumber,
        passportNumber: personDetails.passportNumber || profileData.passportNumber,
        dateOfBirth: personDetails.dateOfBirth || profileData.dateOfBirth,
        userpin: personDetails.userpin || profileData.samAccount,
        email: personDetails.email || profileData.primaryEmailAddress,
        passportExpiryDate: personDetails.passportExpiryDate || null,
        passportCountryOfIssue: personDetails.passportCountryOfIssue || profileData.address.country,
        mobileNumber: personDetails.mobileNumber || profileData.primaryContactNumber,
        workNumber: personDetails.workNumber || '',
        homeNumber: personDetails.homeNumber || '',
        employer: personDetails.employer || '',
        employeeNumber: personDetails.employeeNumber || ''
      },
      membership: [
        {
          id: 0,
          productGuid: membershipDetails.productGuid || null,
          productName: membershipDetails.productName || null,
          productCaseKey: membershipDetails.productCaseKey || null,
          sourceSystemName: membershipDetails.sourceSystemName || null,
          caseMbrKey: membershipDetails.caseMbrKey || null,
          membershipNumber: membershipDetails.membershipNumber || null,
          isApproved: false,
          nominationTypeId: 1,
          beneficiaries: beneficiaries
        }
      ]
    };

    return payload;
  }

  private patchValuesForEdit(): void {
    this.patchSubscription = combineLatest([
      this.nobService.titles$,
      this.nobService.relationshipTypes$,
      this.nobService.beneficiaryDetails$
    ])
      .pipe(
        map(([titles, relationshipTypes, data]) => ({
          titles,
          relationshipTypes,
          data
        }))
      )
      .subscribe(({ titles, relationshipTypes, data }) => {
        const beneficiary = this.nobService.getMembership(data?.membership, NominationTypeId.Funeral, null)
          ?.beneficiaries[this.beneficiaryId!];
        this.editFormNomineeIdentifierId = beneficiary?.nomineeIdentifier!;
        this.editForm.patchValue({
          firstName: beneficiary?.firstName,
          lastName: beneficiary?.lastName,
          titleId: beneficiary?.titleId,
          dateOfBirth: beneficiary?.dateOfBirth ? new Date(beneficiary?.dateOfBirth) : '',
          relationshipId: beneficiary?.relationshipId,
          identityNumber: beneficiary?.identityNumber || '',
          passportNumber: !beneficiary?.identityNumber ? beneficiary?.passportNumber : '',
          expiryDate: beneficiary?.expiryDate ? new Date(beneficiary?.expiryDate) : '',
          country: beneficiary?.country,
          radioGroup: beneficiary?.identityNumber ? 'id' : beneficiary?.passportNumber ? 'passport' : 'id',
          nominationId: beneficiary?.nominationId
        });

        this.editForm.get('phoneNumberAndEmailGroup')?.patchValue({
          mobileNumber: this.getContactNumber(beneficiary?.phoneNumber || ''),
          countryCode: this.getCountryCode(beneficiary?.phoneNumber || ''),
          email: beneficiary?.email
        });

        this.editForm.markAsPristine();
      });
  }

  private initBeneficiary1Form(): void {
    this.beneficiaryForm = this.formBuilder.group(this.createFormGroupObject());

    // Configure modal properties
    this.configureInitialModal();
    this.setInitialModalValues();
  }

  private initBeneficiary2Form(): void {
    this.beneficiary2Form = this.formBuilder.group(this.createFormGroupObject());
  }

  private initEditForm(): void {
    this.editForm = this.formBuilder.group(this.createFormGroupObject());

    if (this.isInEditMode) this.patchValuesForEdit();
  }

  private initReplaceForm(): void {
    this.replaceForm = this.formBuilder.group(this.createFormGroupObject());
  }

  private createFormGroupObject() {
    return {
      nominationTypeId: [0],
      beneficiaryTypeId: [2],
      titleId: [, [Validators.required]],
      relationshipId: [, [Validators.required]],
      firstName: [
        '',
        [Validators.required, Validators.maxLength(INPUT_NAME_MAX_LENGTH), CustomValidators.alphabeticValidator()]
      ],
      lastName: [
        '',
        [Validators.required, Validators.maxLength(INPUT_NAME_MAX_LENGTH), CustomValidators.alphabeticValidator()]
      ],
      dateOfBirth: ['', [Validators.required, CustomValidators.ageRangeValidator(DOB_MIN_AGE)]],
      identityNumber: [
        '',
        [CustomValidators.digitValidator(), CustomValidators.lengthValidator(IDENTITY_NUMBER_INPUT_LENGTH)]
      ],
      passportNumber: [
        '',
        [CustomValidators.alphanumericValidator(), CustomValidators.lengthValidator(PASSPORT_NUMBER_INPUT_LENGTH_NOB)]
      ],
      country: [''],
      expiryDate: [''],
      phoneNumberAndEmailGroup: this.formBuilder.group({
        email: [''],
        mobileNumber: [''],
        countryCode: ['']
      }),
      nomineeIdentifier: [1],
      dateCreated: [new Date().toISOString()],
      dateModified: [new Date().toISOString()],
      lastModifiedBy: [''],
      genderId: [1],
      middleName: [''],
      initials: [''],
      allocation: [0],
      radioGroup: ['id'],
      nominationId: [''],
      personId: [''],
      action: ['']
    };
  }

  private getCountryCode(phoneNumber: string | null): string {
    if (phoneNumber) {
      const countryCodecRegex = phoneNumber.length > 11 ? /([0-9.]*)\d{10}/ : /([0-9.]*)\d{9}/;
      const match = phoneNumber?.match(countryCodecRegex)?.[1];
      if (match && CountriesCollection.find((item) => item.callingCode === `+${match}`)) return `+${match}`;
    }
    return '';
  }

  private getContactNumber(phoneNumber: string | null): string {
    if (phoneNumber) {
      // Get 9 digits (meant to exclude country code)
      const phoneRegex = phoneNumber.length > 11 ? /\d{10}$/ : /\d{9}$/;
      const outputPhoneNumber = phoneNumber?.match(phoneRegex)?.[0];

      // Returns the raw phone number with an appended 0 in front
      if (phoneNumber.length > 11) {
        return outputPhoneNumber ? `${outputPhoneNumber}` : '';
      } else {
        return outputPhoneNumber ? `0${outputPhoneNumber}` : '';
      }
    }
    return '';
  }

  public toggleCheckbox(isChecked: boolean): void {
    this.isChecked = isChecked;
  }
  
  private getDataFromToken(): void {
    this.oidcSecurityService.userData$.subscribe((userData) => {
      this.userData = userData.userData;
    });
  }
}
