import { Component, OnInit, ViewChild } from '@angular/core';
import { FcBeneficiaryModalComponent } from './fc-beneficiary-modal/fc-beneficiary-modal.component';
import { FuneralCoverService } from '../../services/funeral-cover.service';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { NobService, NominationTypeId } from 'src/app/shared/services/nob.service';
import { RelationshipType } from '../../models/funeral-cover.model';
import { BeneficiariesService } from '../../../beneficiaries/services/beneficiaries.service';
import { InternalFund } from '../../../beneficiaries/models/funds.model';
import { Beneficiary } from '../../../shared/models/nob.model';
import { ActiveDownloadSection } from 'src/app/shared/components/download-modal/download-modal.component';
import { NotificationService, NotificationType } from 'src/app/shared/services/notification.service';
import {
  FAILED_DOWNLOAD_TITLE,
  FAILED_DOWNMLOAD_MESSAGE,
  SUCCESSFULL_DOWNLOAD_TITLE
} from 'src/app/core/constants/base.constants';

@Component({
  selector: 'afc-funeral-cover',
  templateUrl: './funeral-cover.component.html',
  styleUrls: ['./funeral-cover.component.scss']
})
export class FuneralCoverComponent implements OnInit {
  @ViewChild('beneficiaryModal') beneficiaryModal: FcBeneficiaryModalComponent;

  public isModalOpen = false;
  public inEditMode = false;
  public beneficiaryId?: number;
  public isReplaceJourney = false;
  public NominationTypeId = NominationTypeId;
  public membershipNumber: string;
  public employerName: string;
  public breadcrumbCurrentPage = 'Funeral cover';
  public fund: InternalFund | undefined;
  public breadcrumbPages = [
    {
      name: 'Investments and policies',
      url: '/dashboard',
      scrollTo: 'investmentsAndPolicies'
    }
  ];

  activeDownloadSection: ActiveDownloadSection | undefined = undefined;
  ActiveDownloadSectionType = ActiveDownloadSection;

  public ngOnInit(): void {
    this.nobService.getNobCover(true);
  }

  constructor(
    public funeralCoverService: FuneralCoverService,
    private profileService: ProfileService,
    public nobService: NobService,
    private beneficiariesService: BeneficiariesService,
    private notificationService: NotificationService
  ) {}

  public openModal(editMode?: boolean, beneficiaryId?: number): void {
    if (editMode) {
      this.inEditMode = true;
      this.beneficiaryId = beneficiaryId;
    }
    this.isModalOpen = true;
  }

  public openReplaceModal(): void {
    this.isReplaceJourney = true;
    this.isModalOpen = true;
  }

  public closeModal(): void {
    this.beneficiaryModal.onInitiateClose();
  }

  public downloadPdf(): void {
    this.activeDownloadSection = ActiveDownloadSection.Loading;

    let profileDetails: any;
    let beneficiariesDetails: any;
    let relationshipTypes: RelationshipType[] | null | undefined;

    this.profileService.profile$.subscribe((data) => (profileDetails = data));
    this.nobService.relationshipTypes$.subscribe((types) => (relationshipTypes = types));

    this.nobService.beneficiaryDetails$.subscribe((data) => {
      beneficiariesDetails = this.nobService
        .getMembership(data?.membership, NominationTypeId.Funeral, null)
        ?.beneficiaries.map((item: Beneficiary) => ({
          name: `${item.firstName}  ${item.lastName}`,
          idPassport: item.identityNumber || item.passportNumber,
          expiryDate: item.expiryDate == null || item.expiryDate == undefined ? '' : item.expiryDate.substring(0, 10),
          countryIssue: item.country,
          relationship: relationshipTypes?.find((r: RelationshipType) => r.id === item.relationshipId)?.value || '',
          mobileNumber: item.phoneNumber == null || item.phoneNumber == undefined ? '' : item.phoneNumber,
          lastModifiedDate: item.dateModified,
          email: item.email
        }));
    });

    this.beneficiariesService.internalFundSummary$.subscribe((funds) => {
      if (funds != null || funds != undefined) {
        var nonRestrictedFund = funds?.find((fund) => fund.isRestricted === false);
        if (nonRestrictedFund != null || nonRestrictedFund != undefined) {
          this.fund = nonRestrictedFund;
          this.membershipNumber = this.fund.refNum2 != null || this.fund.refNum2 != undefined ? this.fund.refNum2 : '';
          this.employerName =
            this.fund.employerName != null || this.fund.employerName != undefined ? this.fund.employerName : '';
        }
      }
    });

    const lastModifiedDates =
      beneficiariesDetails
        ?.map((b: { lastModifiedDate: string }) => new Date(b.lastModifiedDate))
        .sort((a: Date, b: Date) => b.getTime() - a.getTime()) || [];

    const newPayload = {
      fullNameAndSurname: `${profileDetails?.firstName} ${profileDetails?.lastName}`,
      dateOfBirth: `${profileDetails?.dateOfBirth.substring(0, 10)}`,
      employerName: this.employerName,
      membershipNumber: this.membershipNumber,
      idPassport: profileDetails?.idNumber || profileDetails?.passportNumber,
      lastModifiedDate: lastModifiedDates.length > 0 ? lastModifiedDates[0].toISOString().split('T')[0] : '',
      beneficiaries: beneficiariesDetails
    };
    this.funeralCoverService.downloadBeneficiaryPdf(newPayload).subscribe({
      next: (data: any) => {
        this.activeDownloadSection = ActiveDownloadSection.Success;
        this.notificationService.showNotification({
          notificationType: NotificationType.Success,
          title: SUCCESSFULL_DOWNLOAD_TITLE
        });
        this.download(data?.value.pdfData);
      },
      error: () => {
        this.activeDownloadSection = ActiveDownloadSection.Error;
        this.notificationService.showNotification({
          notificationType: NotificationType.Error,
          title: FAILED_DOWNLOAD_TITLE,
          message: FAILED_DOWNMLOAD_MESSAGE
        });
      }
    });
  }

  private download(fileStream: string): void {
    const source = `data:application/pdf;base64,${fileStream}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = 'BeneficiaryReport.pdf';
    link.click();
  }
}
