import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { formatTime } from 'src/app/shared/utilities/date.util';

@Component({
  selector: 'afc-idle-state-modal',
  templateUrl: './idle-state-modal.component.html',
  styleUrls: ['./idle-state-modal.component.scss']
})
export class IdleStateModalComponent {
  @Input() showModal = false;
  @Input() timerDurationInMinutes: number;
  @Output() continueBtnEvent = new EventEmitter<void>();
  @Output() logoutBtnEvent = new EventEmitter<void>();
  @Output() timerElapsedEvent = new EventEmitter<void>();

  public displayTime: string;

  constructor() { }

  ngOnInit(): void {
    this.displayTime = formatTime(this.timerDurationInMinutes * 60);
    this.startTimer();
  }

  private startTimer(): void {
    const timer$ = this.defineInterval(this.timerDurationInMinutes);

    let lastTimestamp = Date.now();
    let accumulatedTimeDifference = 0;
    let elapsedSeconds = 0;

    timer$.subscribe(() => {
      const currentTimestamp = Date.now();
      const elapsedMilliseconds = currentTimestamp - lastTimestamp;
      
      elapsedSeconds += Math.floor((accumulatedTimeDifference + elapsedMilliseconds) / 1000);
      accumulatedTimeDifference = (accumulatedTimeDifference + elapsedMilliseconds) % 1000;

      const remainingSeconds = Math.max((this.timerDurationInMinutes * 60) - elapsedSeconds, 0);

      this.displayTime = formatTime(remainingSeconds);

      if (remainingSeconds <= 0)
        this.timerElapsedEvent.emit();
  
      lastTimestamp = currentTimestamp;
    });
  }

  private defineInterval(durationInMinutes: number): Observable<number> {
    const durationInSeconds = durationInMinutes * 60;
    const interval$ = interval(1000);
  
    return interval$.pipe(
      takeWhile((elapsedSeconds) => elapsedSeconds <= durationInSeconds)
    );
  }
}
