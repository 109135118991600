<nav class="tabs-nav">
  <ul class="nav-list">
    <li #tab *ngFor="let tab of tabs; let i = index" class="nav-item" [class.active]="tab.isActive">
      <button class="nav-button" (click)="activateTab(tab, i)">
        {{ tab.label }}
      </button>
    </li>
    <span #slider class="slider"></span>
  </ul>
  <div class="nav-action-buttons">
    <ng-container [ngTemplateOutlet]="activeTab.tabButtons"></ng-container>
  </div>
</nav>
<ng-content></ng-content>
