import { ChangeDetectorRef, Component, Injector, Input, OnInit, Output, EventEmitter, Optional, Self, ViewChild, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { AfcDatePipe } from '../../pipes/date-pipe';

@Component({
  selector: 'afc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements ControlValueAccessor, OnInit {
  private _value: string;
  @Input() label: string;
  @Input() errorReferenceText: string;
  @Input() placeholder: string = '';
  @Input() isDisabled: boolean = false;
  @Input() withAsterisk: boolean = false;
  @Input() variant: 'editable' | 'non-editable' | 'read-only' = 'editable';
  @Input() type: 'text' | 'number' | 'date' | 'password' | 'datepicker' | 'textarea' = 'text';
  @Input() maxLength: number = 50;
  @Input() id: string;
  @Input() step: number;
  @Input() noFormEnabledBorder: boolean = false;
  @Input() withIcon: boolean = false;
  @Input() borderRadius: number = 5;
  @Input() customIcon: string;
  @Input() specialCharAtEnd: string;
  @Input() withTooltip: boolean = false;
  @Input() charCount: number = 0;
  @Input() maxCharLimit: number = 1000;
  @Input() additionalDesc: string = '';
  @Input() hasError: boolean = false;
  @Output() onChange = new EventEmitter();
  @Output() focus = new EventEmitter<FocusEvent>();
  @Output() blur = new EventEmitter<FocusEvent>();
  @ViewChild('secondaryLabel') secondaryLabel: HTMLDivElement;

  public handleInputChange($event: KeyboardEvent) {
    const value = ($event.target as HTMLInputElement).value;
    this.onChange.emit(value);
  }

  public onFocus(): void {
    this.focus.emit();
  }

  public onBlur(): void {
    this.blur.emit();
    this.propagateTouch();
  }

  public control: FormControl;

  public get value(): string {
    return this._value;
  }

  @Input() public set value(value: string) {
    if (this.type === 'datepicker') {
      value = this.afcDatePipe.transform(value);
    }

    this._value = value;
    this.propagateChange(value);
  }

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    private afcDatePipe: AfcDatePipe,
    @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['variant']) {
      this.isDisabled = changes['variant'].currentValue as typeof this.variant !== 'editable';
    }
  }

  ngOnInit(): void {
    if (this.variant !== 'editable') {
      this.isDisabled = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.ngControl && this.ngControl.control) {
      this.control = this.ngControl.control as FormControl;
      this.cdr.detectChanges();
    }
  }

  private propagateChange: Function = (_: string) => { };
  private propagateTouch: Function = (_: string) => { };

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
