import { DASHBOARD, FUND_DETAILS, FUNERAL_COVER, PROFILE_DETAILS, STATEMENTS_AND_DOCUMENTS } from 'src/app/app-routing.module';

export const DEFAULT_BACKGROUND_IMAGE = 'bg-img-dashboard';

export const routeBackgroundData = [
  {
    pathKeywords: [DASHBOARD],
    imageName: DEFAULT_BACKGROUND_IMAGE
  },
  {
    pathKeywords: [PROFILE_DETAILS],
    imageName: 'bg-img-profile'
  },
  {
    pathKeywords: [FUND_DETAILS, FUNERAL_COVER, STATEMENTS_AND_DOCUMENTS],
    imageName: 'bg-img-funds'
  }
]