<div class="label-container">
  <span *ngIf="label" class="label"> {{ label }}<span *ngIf="withAsterisk" class="asterisk">*</span> </span>
  <ng-content select="[secondaryLabel]"></ng-content>
</div>
<div
  class="radio-buttons-container"
  [ngClass]="noWrapRadioContainer ? 'no-wrap' : ''"
  [class.column]="direction === 'column'"
>
  <ng-content></ng-content>
</div>
