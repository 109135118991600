import { ChangeDetectionStrategy, Component, Output, EventEmitter, ContentChildren, QueryList } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { SectionHeaderComponent } from 'src/app/shared/components/section-header/section-header.component';
import { CardHeaderComponent } from 'src/app/shared/components/card/card-header/card-header.component';

@Component({
  selector: 'afc-expandable-wrapper',
  templateUrl: './afc-expandable-wrapper.component.html',
  styleUrls: ['./afc-expandable-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableWrapperComponent {
  @ContentChildren(SectionHeaderComponent) childSectionHeader: QueryList<SectionHeaderComponent>;
  @ContentChildren(CardHeaderComponent) childCardHeader: QueryList<CardHeaderComponent>;

  public isExpanded$ = new BehaviorSubject<boolean | null>(null);

  ngAfterViewInit() {
    this.childSectionHeader.forEach((sectionHeader: SectionHeaderComponent) =>
      sectionHeader.onClick.subscribe(() => this.toggleIsExpanded())
    );
    this.childCardHeader.forEach((cardHeader: CardHeaderComponent) =>
      cardHeader.onClick.subscribe(() => this.toggleIsExpanded())
    );
  }

  toggleIsExpanded(isExpanded?: boolean) {
    this.isExpanded$
      .pipe(take(1))
      .subscribe((current) => {
        if (isExpanded === true || isExpanded === false) {
          this.isExpanded$.next(isExpanded);
        } else {
          this.isExpanded$.next(!current);
        }
      })
      .unsubscribe();
  }
}
