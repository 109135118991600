<ng-container *ngIf="breakpointService.isDesktop$ | async; else mobileView">
  <ng-container *ngIf="strippedContent; else fullPageViewDesktop">
    <ng-container [ngTemplateOutlet]="desktop.templateRef"></ng-container>
  </ng-container>
</ng-container>

<ng-template #mobileView>
  <ng-container *ngIf="strippedContent; else fullPageViewMobile">
    <ng-container [ngTemplateOutlet]="mobile.templateRef"></ng-container>
  </ng-container>
</ng-template>

<ng-template #fullPageViewDesktop>

  <!-- TODO: Rethink once notifications are to be fully implemented -->
  <ng-container *ngTemplateOutlet="notificationAlert; context: { variant: 'wide' }"></ng-container>

  <div class="banner-background" [ngClass]="normalView ? 'short-banner' : ''"></div>
  <afc-dynamic-background></afc-dynamic-background>
  <afc-inner-layout [ngClass]="normalView ? 'normalView' : ''">
    <afc-welcome-banner></afc-welcome-banner>
    <ng-container [ngTemplateOutlet]="desktop.templateRef"></ng-container>
  </afc-inner-layout>
</ng-template>

<ng-template #fullPageViewMobile>
  <!-- TODO: Rethink once notifications are to be fully implemented -->
  <ng-container *ngTemplateOutlet="notificationAlert; context: { variant: 'small' }"></ng-container>
  <afc-welcome-banner></afc-welcome-banner>
  <ng-container [ngTemplateOutlet]="mobile.templateRef"></ng-container>
</ng-template>

<ng-template #notificationAlert let-variant="variant">
  <ng-container *ngIf="documentsService.notification$ | async as notifications">
    <ng-container *ngFor="let notification of notifications">
      <div *ngIf="notification.navigationType === 'Dashboard'">
        <afc-notification [data]="notification" [variant]="variant"></afc-notification>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
