import { Pipe, PipeTransform } from '@angular/core';
/**
 * Calculates what percent a given number
 * is from another number (limit)
 */
@Pipe({ name: 'findPercentage' })
export class CalculatePercentage implements PipeTransform {
  transform(value: number, limit = 1): number {
    const calculatedPercentage = (value / limit) * 100;

    if (calculatedPercentage > 100) {
      return 100;
    }
    return calculatedPercentage;
  }
}
