import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileDetailsComponent } from 'src/app/profile-details/components/profile-details/profile-details.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanDeactivate<ProfileDetailsComponent> {

  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService
  ) { }

  public canActivate(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((isAuthorized) => {
        if (!isAuthorized.isAuthenticated) {
          this.router.navigate(['login']);
          return false;
        }
        return true;
      })
    )
  }

  public canDeactivate(component: ProfileDetailsComponent): Observable<boolean> | boolean {
    if (!component.parentForm.pristine) {
      component.openConfirmation = true;
      return false;
    }

    return true;
  }
}
