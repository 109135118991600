import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NobModalService {
    private isModalOpen$$ = new BehaviorSubject<boolean>(false);
    public isModalOpen$ = this.isModalOpen$$.asObservable();

    private isFcModalOpen$$ = new BehaviorSubject<boolean>(false);
    public isFcModalOpen$ = this.isFcModalOpen$$.asObservable();

    private isSuccessModal$$ = new BehaviorSubject<boolean>(false);
    public isSuccessModal$ = this.isSuccessModal$$.asObservable();

    private isFcSuccessModal$$ = new BehaviorSubject<boolean>(false);
    public isFcSuccessModal$ = this.isFcSuccessModal$$.asObservable();

    openModal(): void {
        this.isModalOpen$$.next(true);
    }

    openFcModal(): void {
        this.isFcModalOpen$$.next(true);
    }

    closeModal(): void {
        this.isModalOpen$$.next(false);
    }

    closeFcModal(): void {
        this.isFcModalOpen$$.next(false);
    }

    openSuccessModal(): void {
        this.isSuccessModal$$.next(true);
    }

    openFcSuccessModal(): void {
        this.isFcSuccessModal$$.next(true);
    }

    closeSuccessModal(): void {
        this.isSuccessModal$$.next(false);
    }

    closeFcSuccessModal(): void {
        this.isFcSuccessModal$$.next(false);
    }
}
